<div *ngIf="selectedQrMode === qrMode.camera && showCameraList">
  <lib-amdocs-dropdown [elementId]="'camera-dd'"
                       [items]="cameraList"
                       [control]="cameraCtrl"
                       [label]="'Camera list'">
  </lib-amdocs-dropdown>
</div>
<div id="reader-wrapper">
  <div id="reader"></div>
</div>
<div class="reader-input-wrapper" *ngIf="selectedQrMode === qrMode.file">
  <div class="btn btn-medium primary upload-button">
    {{uploadBtnText}}
    <input type="file" name="upload-qr" accept="image/*" (change)="handleQrUpload($event)" />
  </div>
</div>
