<div class="d-flex flex-column simple-page-wrapper">
  <!-- header -->
  <div class="header-wrapper">
    <app-header-desktop></app-header-desktop>
  </div>

  <!-- sub header -->
  <div #subHeader class="sub-header" [hidden]="!showSubheader">
    <ng-content select="[subHeader]"></ng-content>
  </div>

  <!-- content -->
  <div class="content-wrapper position-relative">
    <div class="content-container container">
      <div class="row">
        <div class="col-12">
          <ng-content select="[mainContainer]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
