<div id="sidebar-menu">
  <button class="btn text-btn" *ngIf="!menuOpen" (click)="open()">
    <img lib-amdocs-image-version src="/assets/icons/Hamburger-white.svg" alt="menu icon"/>
  </button>
</div>

<div class="sidebar-fade" [ngClass]="{'show': menuOpen}" (click)="close()"></div>

<div id="sidebar-menu-wrapper" [ngClass]="{'open': menuOpen}" (click)="$event.stopPropagation();">
  <button class="btn text-btn close-btn" (click)="close()">
    <img lib-amdocs-image-version src="/assets/icons/close.svg" alt="close icon"/>
  </button>
  <div class="employee-details">
    <app-avatar [uuid]="userProfile.uuid" [userName]="userProfile?.name"></app-avatar>
    <span class="text-small-bold employee-name"> {{userProfile?.name}} </span>
  </div>
  <div class="menu-item-wrapper">
    <ul class="menu-items">
      <li class="text-small-normal menu-item pointer" (click)="item.action()" *ngFor="let item of menuItems">
      <span class="item-img">
        <img lib-amdocs-image-version *ngIf="item.icon" [src]="item.icon" [alt]="item.name"/>
      </span>
        <div class="item-desc text-small-normal">
          <div class="row-content">{{item.name}}</div>
          <app-badge *ngIf="item.name === upcomingNamed" [content]="upcomingSeatsLength"></app-badge>
        </div>
      </li>
    </ul>
  </div>
  <div class="sidebar-menu-footer-wrapper">
    <div class="sidebar-menu-footer">
      <div class="footer-amdocs-logo">powered by Amdocs IT</div>
    </div>
  </div>
</div>
