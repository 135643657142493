import {Injectable} from '@angular/core';
import {
  ESeatStatus,
  IActiveMap,
  IAdminAmenityRegion,
  IExtendedSeat,
  IRoom,
  ISeat,
  IUnit, IUpdateAmenitySeatAttributes, IUpdateSeatAttributes,
} from '../../../models';
import {Observable, of} from 'rxjs';
import {first, mergeMap} from 'rxjs/operators';
import {MapService} from '../../../core/map.service';

@Injectable()
export class SeatManagementService {

  constructor(private mapService: MapService) {

  }

  public getActiveMap(wingGroup: string): Observable<IActiveMap> {
    return this.mapService.getActiveMap(wingGroup).pipe(
      mergeMap((activeMap: IActiveMap) => {
        return of(activeMap);
      })
    );
  }

  public getAmenitiesAsAdmin(): Observable<IAdminAmenityRegion> {
    return this.mapService.getAmenitiesAsAdmin().pipe(
      mergeMap((res: IAdminAmenityRegion) => {
        return of(res);
      })
    );
  }

  public updateSeatAttributes(selectSeat: IExtendedSeat, amenitiesCodeList: string[], wingGroup, selectedRoom, force = false, isAssign: boolean): Observable<IUpdateSeatAttributes> {
    const input = {
      force,
      seat: {
        seatCode: selectSeat.code,
        roomCode: selectedRoom?.code,
        wingGroupCode: wingGroup,
        isDisabledByAdmin: selectSeat.isUnavailable,
        assignedUser: isAssign ? selectSeat.assignedUser?.code : null,
        amenityCodes: amenitiesCodeList
      },
    };
    return this.mapService.updateSeatAttributes(input).pipe(
      mergeMap((updateSeatAttributes: IUpdateSeatAttributes) => {
        return of(updateSeatAttributes);
      })
    );
  }

  public updateAmenitySeatAttributes(selectSeat: IExtendedSeat, amenitiesCodeList: string[], wingGroup, selectedRoom): Observable<IUpdateAmenitySeatAttributes> {
    const input = {
      seat: {
        seatCode: selectSeat.code,
        roomCode: selectedRoom?.code,
        wingGroupCode: wingGroup,
        amenityCodes: amenitiesCodeList
      },
    };
    return this.mapService.updateAmenitySeatAttributes(input).pipe(
      mergeMap((updateAmenitySeatAttribute: IUpdateAmenitySeatAttributes) => {
        return of(updateAmenitySeatAttribute);
      })
    );
  }

  public getSeatIconForUnit(seat: IExtendedSeat | IRoom, units: IUnit[], selectedUnit: IUnit, selectedSeat: ISeat, selectedRoom: IRoom | IExtendedSeat): string {
    let classesStr = this.mapService.getSeatBaseClasses(seat);
    const tags: string[] = [];

    if (this.mapService.isAllocatedToUnit(seat)) {
      tags.push(ESeatStatus.allocated);
    } else {
      tags.push(ESeatStatus.unallocated);
    }
    if ((seat as IRoom).seats) {
      if (this.mapService.atLeasOneSeatIsBookable((seat as IRoom))) {
        // YELLOW ALREADY ADDED
      } else {
        if (this.mapService.atLeasOneSeatIsAssigned((seat as IRoom))) {
          tags.push(ESeatStatus.assigned);
        } else {
          tags.push(ESeatStatus.unbookable);
        }
      }
    } else {
      if (this.mapService.isAssigned(seat)) {
        tags.push(ESeatStatus.assigned);
      } else {
        if (!this.mapService.isBookable(seat)) {
          tags.push(ESeatStatus.unbookable);
        }
      }
    }
    if (selectedUnit && seat.unit?.code === selectedUnit.code) {
      tags.push(ESeatStatus.selected);
    }

    if ((selectedSeat && selectedSeat.code === seat.code) || (selectedRoom && selectedRoom.code === seat.code)) {
      classesStr += (' ' + ESeatStatus.focused);
    }

    return `${classesStr} ${tags.join('-')}`;
  }

  public getSeatIconForNB(seat: IExtendedSeat | IRoom, units: IUnit[], selectedUnit: IUnit, selectedSeat: ISeat, selectedRoom: IRoom | IExtendedSeat): string {
    let classesStr = this.mapService.getSeatBaseClasses(seat);
    const tags: string[] = [];

    if (this.mapService.isAllocatedToNB(seat)) {
      tags.push(ESeatStatus.allocated);
    } else {
      tags.push(ESeatStatus.unallocated);
    }
    if ((seat as IRoom).seats) {
      if (this.mapService.atLeasOneSeatIsBookableAndNotAssigned((seat as IRoom))) {
        // NO NEED TO ADD
      } else {
        tags.push(ESeatStatus.unbookable);
      }
    } else {
      if (!this.mapService.isBookable(seat) || this.mapService.isAssigned(seat)) {
        tags.push(ESeatStatus.unbookable);
      }
    }
    if ((selectedSeat && selectedSeat.code === seat.code) || (selectedRoom && selectedRoom.code === seat.code)) {
      classesStr += (' ' + ESeatStatus.focused);
    }
    return `${classesStr} ${tags.join('-')}`;
  }

}

export interface ISeatManagementMod {
  mode: ESeatManagementMod;
}

export interface ISeatManagementMainMod {
  mode: ESeatManagementMainMod;
}

export enum ESeatManagementMod {
  noSeatSelected = 0,
  singleSeatView = 1,
  roomView = 2,
  amenities = 3,
}

export enum ESeatManagementMainMod {
  details = 0,
  management = 1,
}

