<div header class="mobile-header-wrapper">
<lib-amdocs-top-header *ngIf="showTopHeader" (onBackButtonClickCb)="close()" [headerTitle]="'Book for a friend'" [showHomeButton]="true" [urlRedirectHome]="homeUrl"></lib-amdocs-top-header>
<!-- <div class="qr-code" (click)="scanQR()">
  <img lib-amdocs-image-version src="/assets/icons/qr-code.svg"/>
</div> -->
</div>
<div class="input-wrapper" [ngClass]="{'scroll': isScroll}">
  <img lib-amdocs-image-version *ngIf="isScroll; else searchIcon" class="input-icon" src="/assets/icons/HR-purple.svg" alt="search icon"/>
  <ng-template #searchIcon>
    <img lib-amdocs-image-version *ngIf="!isScroll"  class="input-icon" src="/assets/icons/search-icon.svg" alt="search icon"/>
  </ng-template>
  <input [attr.placeholder]="placeholder | amdocsTranslate" [ngClass]="isScroll ? 'text-small-normal' :'text-medium-bold'" #inputSearch
         [formControl]="employeeSearchControl"/>
  <ng-container *ngIf="showSearchClearButton">
    <img lib-amdocs-image-version class="delete-icon mobile" src="/assets/icons/grey-close-icon.svg" alt="delete icon" (click)="clearSearch()"/>
    <img lib-amdocs-image-version class="delete-icon desktop" src="/assets/icons/Close-small-purple.svg" alt="delete icon" (click)="clearSearch()"/>
  </ng-container>
</div>
