<div class="d-flex flex-column admin-map-page-container" [ngClass]="{'client': isClient}">
  <!-- header -->
  <div class="header-wrapper">
    <app-header-desktop></app-header-desktop>
  </div>

  <!-- sub header -->
  <div class="sub-header">
    <ng-content select="[subHeader]"></ng-content>
  </div>

  <!-- content -->
  <div class="content-wrapper position-relative">
    <div class="right-side-panel-wrapper d-flex" [ngClass]="{'open': showRightSidePanel}">
      <ng-content select="[rightSidebar]"></ng-content>
    </div>
    <div class="d-flex justify-content-between align-items-center map-wrapper" [ngClass]="{'map-with-right-panel': showRightSidePanel}">
      <ng-content select="[seatMap]"></ng-content>
    </div>
  </div>

  <!-- footer -->
  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
