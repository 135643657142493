import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AmdocsEventBusService} from 'amdocs-core';
import {CONSTANTS} from '../../../constants';

@Component({
  selector: 'app-desktop-map-page-layout',
  templateUrl: './desktop-map-page-layout.component.html',
  styleUrls: ['./desktop-map-page-layout.component.scss']
})
export class DesktopMapPageLayoutComponent implements OnInit, OnDestroy {

  constructor(private eventBusService: AmdocsEventBusService) { }
  public showRightSidePanel: boolean;
  @Input() isClient = false;
  ngOnInit(): void {
    this.eventBusService.on(CONSTANTS.EVENTS.TOGGLE_MAP_PAGE_SIDEBAR, (val: boolean = null) => {
      this.showRightSidePanel = val === null ? !this.showRightSidePanel : val;
    }, true);
  }

  ngOnDestroy(): void {
    this.eventBusService.off(CONSTANTS.EVENTS.TOGGLE_MAP_PAGE_SIDEBAR, true);
  }


}
