<div class="seat-wrapper">
  <!--  SEAT VIEW-->
  <div *ngIf="viewMod === ESeatManagementMod.singleSeatView "
       class="seat-details">
    <!--    TODO: check if booked -->
    <!--    Booking Time & location -->
    <div *ngIf="isSeatBooked"class="booking-and-location text-small-bold">
<!--      AAAA-->
      <div *ngIf="selectedSeatBooking.bookingTime" class="booking-time">
        <img lib-amdocs-image-version class="icon-date-time" src="/assets/icons/Date&Time-purple.svg"/>
        {{selectedSeatBooking.bookingTime.start | amdocsDate:'dddd DD-MMM-yyyy'}},
        {{selectedSeatBooking.bookingTime.start | amdocsDate:' HH:mm' }} -
        {{selectedSeatBooking.bookingTime.end | amdocsDate:'HH:mm' }}
        <div *ngIf="isOverNight" class="d-inline-flex over-night-flag">+1</div>
      </div>
      <div *ngIf="userLocation" class="location text-small-normal">
        <img lib-amdocs-image-version class="icon-location" src="/assets/icons/location-purple.svg"/>
        {{userLocation.building.name}},
        {{'client.seatManagement.floor' | amdocsTranslate}} {{userLocation.floor.name}}</div>
    </div>
    <div class="seat-content">
      <!--    Booked for-->
      <div class="book-for seat-details-item d-flex align-items-center">
        <div class="title text-small-normal">
          {{'client.seatManagement.bookFor' | amdocsTranslate}}
        </div>
        <div *ngIf="!showSearchEmployee" class="value text-medium-normal">
          {{bookForFriend ? bookForFriend.displayName : bookedFor}}
        </div>
        <div role="button"
             (click)="showSearchEmployee = true; "
             *ngIf="showSearchEmployeeBtn() && !showClearSearchBtn"
             class="search-button">
          <img lib-amdocs-image-version src="/assets/icons/HR-purple.svg">
        </div>
        <div role="button"
             (click)="clearSelectedEmployee()"
             *ngIf="showSearchEmployeeBtn() && showClearSearchBtn"
             class="search-button">
          <img lib-amdocs-image-version src="/assets/icons/purple-close-icon.svg">
        </div>
        <div *ngIf="showSearchEmployee" class="search-header">
          <app-employee-search-text-field
            [employeeSearchControl]="employeeSearchControl"
            [showSearchClearButton]="true"
            (clear)="employeeSearchBox.clearSearch(); showSearchEmployee = false"
            [isScroll]="true"
            [showTopHeader]="false"
          ></app-employee-search-text-field>
        </div>
        <app-employee-search-box
          #employeeSearchBox
          [employeeSearchControl]="employeeSearchControl"
          (selectEmployee)="selectEmployee($event)"
          [isScroll]="true"
          [showJobInfo]="false"
        ></app-employee-search-box>


      </div>
      <!--    Status-->
      <div class="status seat-details-item d-flex">
        <div class="title text-small-normal stat">{{'client.seatManagement.status' | amdocsTranslate}}</div>
        <div class="value text-medium-bold bookedStatus" [ngClass]="{'available': currentSeatStatus === ESeatStatus.Available}">
          {{seatStatus}}

        </div>
        <div *ngIf="seatStatus=='Booked' && teamLink" class="teamIcon">

          <div class="teamIconMain">

            <div class="teamsMain" (click)="openChatWithUser()" >
            <img class="teamsImg" lib-amdocs-image-version src="/assets/icons/teams.svg"/>

            <div class="text-tiny-thin">Teams</div>
          </div>
          </div>

        </div>

      </div>
      <!--      Wing-->
      <div class="wing seat-details-item d-flex wingTitle">
        <div class="title text-small-normal">{{'client.seatManagement.wing' | amdocsTranslate}}</div>
        <div class="value text-medium-normal">
          {{selectedSeat.wing.name}}
        </div>
      </div>
      <!--    Unit-->
      <div *ngIf="selectedSeat.unit?.code" class="unit seat-details-item d-flex">
        <div class="title text-small-normal">{{'client.seatManagement.unit' | amdocsTranslate}}</div>
        <div class="value text-medium-normal">
          {{selectedSeat.unit.name}}
        </div>
      </div>
      <!--    Neighborhood-->
      <div *ngIf="selectedSeat.neighborhoods.length > 0" class="neighborhood seat-details-item d-flex">
        <div class="title text-small-normal">{{'client.seatManagement.neighborhood' | amdocsTranslate}}</div>
        <div class="list-wrapper">
          <ul class="list">
            <li class="list-item text-medium-normal" *ngFor="let neighborhood of selectedSeat.neighborhoods">
              {{neighborhood.name}}
            </li>
          </ul>
        </div>
      </div>
      <!--    Amenities-->
      <div *ngIf="selectedSeat.amenities.length > 0" class="amenities seat-details-item d-flex">
        <div class="title text-small-normal">{{'client.seatManagement.amenities' | amdocsTranslate}}</div>
        <div class="list-wrapper">
          <ul class="list">
            <li class="list-item text-medium-normal" *ngFor="let amenity of selectedSeat.amenities">
              {{amenity.name}}
            </li>
          </ul>
        </div>
      </div>


      <!-- <div *ngIf="showCancelBookingBtn" class="teamIcon">
        <div *ngIf="chatIcon" class="chatIconMain" >

          <div class="chatImg">
            <span  (click)="openChatWithUser('daphnam@amdocs.com')" >  <img lib-amdocs-image-version src="/assets/icons/social-chat-icon.svg"/></span>

          </div>

        </div>

        <span *ngIf="chatIcon" class="saparetor" >{{saparetor}}</span>
        <div class="teamIconMain" (click)="toggleTeams()">

          <div >
           <span> <img lib-amdocs-image-version src="/assets/icons/teams.svg"/></span>
          </div>
          <div class="text-tiny-thin">Teams</div>
        </div>

      </div> -->

    </div>

    <div class="actions-buttons d-flex justify-content-center">
      <div *ngIf="isMobile" class="show-on-map-btn">
        <button (click)="navigateToMap()" class="show-on-map-btn btn btn-small secondary">
          {{'client.seatManagement.showOnMap' | amdocsTranslate}}
        </button>
      </div>
      <div *ngIf="showBookThisSeatBtn" class="book-seat-btn action-btn">
        <div class="text-medium-normal btn-top-message" *ngIf="isBookThisSeatBtnDisabled && btnTopMessage">
          {{btnTopMessage}}
        </div>
        <button (click)="createBooking()" class="btn btn-medium primary w-100" [disabled]="isBookThisSeatBtnDisabled">
          {{'client.seatManagement.bookThisSeat' | amdocsTranslate}}
        </button>
      </div>
      <div *ngIf="showCancelBookingBtn" class="cancel-booking action-btn">
        <button (click)="cancelBooking()" class="btn btn-medium secondary w-100">
          {{'client.seatManagement.cancelBooking' | amdocsTranslate}}
        </button>
      </div>
      <!--      TODO: check if booking - ask Carmit - btn doesn't exist in spec but in Invision -->
      <div *ngIf="showCloseBtn" class="close-btn action-btn">
        <button (click)="closeSeatView()" class="btn btn-medium secondary w-100">
          {{'client.seatManagement.close' | amdocsTranslate}}
        </button>
      </div>
    </div>
  </div>
  <!--  ROOM VIEW-->
  <div *ngIf="viewMod === ESeatManagementMod.roomView" class="room-details">
    <div class="room-header">
      <span class="title text-medium-normal">
          {{'client.seatManagement.roomCapacity' | amdocsTranslate}}
      </span>
      <span
        class="value text-medium-bold">{{selectedSeat.seats?.length}} {{'client.seatManagement.seats' | amdocsTranslate}}
      </span>
    </div>
    <div class="room-seats-list">
      <ul class="list">
        <li *ngFor="let seat of selectedSeat.seats" class="list-item d-flex" (click)="roomSeatClicked(seat)">
          <div class="seat-icon {{seat.classNames}}"></div>
          <span class="special-s1">{{seat.name}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>