import {ISeatBooking} from './seat-booking';

export interface IFriendBookings {
  employeeBookings: ISeatBooking[];
}

export const getFriendBooking = `
query GetEmployeeBookings($userCode: String!, $wingGroupCode: String!, $start: String!, $end: String!) {
  employeeBookings(userCode: $userCode, wingGroupCode: $wingGroupCode, start: $start, end: $end) {
    code
    seatCode
    roomCode
    seatName
    bookedBy {
      code
      name
    }
    bookedFor {
      code
      name
    }
    bookingTime {
      start
      end
    }
    location {
      country {
        code
        name
      }
      building {
        code
        name
      }
      floor {
        code
        name
      }
      wingGroup {
        code
        name
      }
      wing {
        code
        name
      }
    }
  }
}
`;
