import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  AmdocsAutocompleteComponent,
  AmdocsDeviceService,
  AmdocsEventBusService,
  AmdocsTranslateService,
  AmdocsUserService, ApiError,
  IAutocompleteItem
} from 'amdocs-core';
import {
  ESelectLocationMod,
  ESelectLocationSubMod,
  IBuilding,
  IBuildingAndUnits,
  ICodeNameItem,
  ICountry,
  IFloor,
  IFloorsAndUnits,
  ILocation,
  IMap,
  INeighborhood, INeighborhoodUser,
  ISelectLocationConfig,
  IUnit,
  IUnitsWingsWingGroups,
  IUserSettings, IWing,
  IWingsAndWingGroups
} from '../../../models';
import {CONSTANTS} from '../../../constants';
import {catchError, finalize, first, mergeMap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FoundationService} from '../../../core/foundation.service';
import {MapService} from '../../../core/map.service';
import {UnitsAutocompleteComponent} from '../units-autocomplete/units-autocomplete.component';
import {UserSettingsService} from '../../../core/user-settings.service';
import {SelectedMapFiltersDetailsService} from '../../../core/selected-map-filters-details.service';
import {QrService} from '../../../core/qr.service';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent implements OnInit, AfterViewInit {

  @Input() config: ISelectLocationConfig;
  @Input() wingsFromMap: IWing[] = [];
  @Input() userSettings: IUserSettings;
  @Input() wingGroupParam: string;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSelectLocation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mapCreateCallback: EventEmitter<ISelectLocationConfig> = new EventEmitter<ISelectLocationConfig>();
  @Output() wingChange: EventEmitter<IWing> = new EventEmitter<IWing>();
  @Output() genericApiErrorCB = new EventEmitter<ApiError>();

  public title = 'selectLocation.headerTitle';
  public countriesDDLItems: IAutocompleteItem[] = [];
  public buildingsDDLItems: IAutocompleteItem[] = [];
  public floorsDDLItems: IAutocompleteItem[] = [];
  public neighborhoodDDLItems: IAutocompleteItem[] = [];
  public wingsDDLItems: IAutocompleteItem[] = [];
  public countries: ICountry[] = [];
  public buildings: IBuilding[] = [];
  public floors: IFloor[] = [];
  public neighborhoods: INeighborhood[] = [];
  public wingGroupCode: string;
  public error = null;
  public wings: ICodeNameItem[] = [];
  public showManageWingsItems = false;
  public isManageWingsLinkDisable = true;
  public submitButtonText = '';
  public showEqualErrMsg = false;
  unitsList: IUnit[] = [];
  unitsResponse: IUnit[];
  countryChanged = false;
  public countriesErrorMsg: string;
  public buildingsErrorMsg: string;
  public floorsErrorMsg: string;
  public wingsErrorMsg: string;
  public unitsErrorMsg: string;
  public neighborhoodErrorMsg: string;
  public disabledCountries = true;
  public disabledBuildings = true;
  public disabledWings = true;
  public disabledFloor = true;
  public disabledUnits = true;
  public isUnitSelectedFirst = false;
  public disabledNeighborhood = true;
  public setAsMyDefaultLocationControl: FormControl = new FormControl();
  public sessionStorageKey: string;
  public allNeighborhoods: INeighborhoodUser[];
  public showDefaultCB = false;
  @ViewChild('unitsAutocompleteComponent') unitsAutocompleteComponent: UnitsAutocompleteComponent;
  @ViewChild('countriesAutocompleteComponent') countriesAutocompleteComponent: AmdocsAutocompleteComponent;
  @ViewChild('buildingsAutocompleteComponent') buildingsAutocompleteComponent: AmdocsAutocompleteComponent;
  @ViewChild('floorsAutocompleteComponent') floorsAutocompleteComponent: AmdocsAutocompleteComponent;
  @ViewChild('wingsAutocompleteComponent') wingsAutocompleteComponent: AmdocsAutocompleteComponent;
  @ViewChild('neighborhoodGroupAutocompleteComponent') neighborhoodGroupAutocompleteComponent: AmdocsAutocompleteComponent;


  constructor(private route: ActivatedRoute, private router: Router,
              private eventBusService: AmdocsEventBusService,
              private mapService: MapService,
              private foundationService: FoundationService,
              private translateService: AmdocsTranslateService,
              private userProfileService: AmdocsUserService,
              private deviceService: AmdocsDeviceService,
              private userSettingsService: UserSettingsService,
              private selectedMapFiltersDetailsService: SelectedMapFiltersDetailsService,
              private qrService: QrService
  ) {
  }

  ngOnInit(): void {
    this.sessionStorageKey = this.isClientMode ? 'selectLocationClient' : 'selectLocation';
    this.setCountriesDDL();
    if(this.isMobile){
      this.submitButtonText = this.translateService.getText('selectLocation.viewLocationMobile');
    } else{
      this.submitButtonText = this.translateService.getText('selectLocation.viewLocation');
    }
  }

  public getUserSettings(): void {
    this.userSettingsService.getUserSettings(null, true).pipe(
      mergeMap((userSettings: IUserSettings) => {
        this.userSettings = userSettings;
        this.isDefaultLocation();
        return of(userSettings);
      }),
      catchError((error: ApiError) => {
        this.error = error;
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      first()
    ).subscribe();
  }

  public isDefaultLocation(): void {
    if (this.userSettings) {
      const location: ILocation = this.userSettings.defaultLocation;
      if ( location && (
        location.country?.code === this.countriesAutocompleteComponent?.selectedItemCode &&
        location.building?.code === this.buildingsAutocompleteComponent?.selectedItemCode &&
        location.floor?.code === this.floorsAutocompleteComponent?.selectedItemCode &&
        location.unit?.code === this.unitsAutocompleteComponent?.selectedItem?.code &&
        location.wingGroup?.code === this.wingGroupCode &&
        location.neighborhood?.code === this.neighborhoodGroupAutocompleteComponent?.selectedItemCode &&
        location.wing?.code === this.wingsAutocompleteComponent?.selectedItemCode
      )

      ) {
        this.showDefaultCB = false;
      } else {
        this.showDefaultCB = true;
      }
    } else {
      this.showDefaultCB = true;
    }
  }



  ngAfterViewInit(): void {
    const userProfileCountry = this.userProfileService.user.userProfile.country;
    if (!sessionStorage.getItem(this.sessionStorageKey)){
      if (userProfileCountry) {
        this.setUserCountry(userProfileCountry);
      }
    }
    const locationFromState: ILocation = JSON.parse(sessionStorage.getItem(this.sessionStorageKey));
    if (locationFromState) {
      this.countriesAutocompleteComponent.selectedItemCode = locationFromState.country.code;
      this.getBuildingsUnitsByCountry().pipe(mergeMap(() => {
        this.buildingsAutocompleteComponent.selectedItemCode = locationFromState.building.code;
        if (this.isUnitMode || this.isClientMode) {
          this.unitsAutocompleteComponent.setSelectedItem({...locationFromState.unit});
        }
        return this.getUnitsFloorByBuilding().pipe(
          mergeMap((res: IFloorsAndUnits) => {
            this.floorsAutocompleteComponent.selectedItemCode = locationFromState.floor.code;
            this.getWingsWingsWingGroupsByFloor().pipe(
              mergeMap((wingsRes: IUnitsWingsWingGroups) => {
                if (this.wingsAutocompleteComponent) {
                  this.wingsAutocompleteComponent.selectedItemCode = locationFromState.wing?.code
                  this.wingGroupCode = wingsRes.wingGroupCode;
                  if (this.isClientMode && this.wingGroupCode) {
                    return this.getNeighborhoodsAllocation().pipe(
                      mergeMap((nbRes: INeighborhoodUser[]) => {
                        this.neighborhoodGroupAutocompleteComponent.selectedItemCode = locationFromState.neighborhood?.code;
                        this.getUserSettings();
                        return of(nbRes);
                      })
                    );
                  }
                }
                return of(wingsRes);
              })
            ).subscribe();
            return of(res);
          })
        );
      })).subscribe();
    } else {
      this.isDefaultLocation();
    }
  }


  showMangeWings(): void {
    this.submitButtonText = this.translateService.getText('selectLocation.uploadMap');
    this.showManageWingsItems = true;
  }

  hideMangeWings(): void {
    this.submitButtonText = this.translateService.getText('selectLocation.viewLocation');
    this.showManageWingsItems = false;
  }

  get isUnitMode(): boolean {
    if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.neighborhoodAllocation) {
      return true;
    }
    return false;
  }
  get isQRGeneration(): boolean {
    if (this.config.subMod === ESelectLocationSubMod.qrGeneration) {
      console.log(this.config.subMod )
      return true;
    }
    return false;
  }

  get isQRDownload(): boolean {
    if (this.config.subMod === ESelectLocationSubMod.qrDownload) {
      console.log(this.config.subMod )
      return true;
    }
    return false;
  }
  get isClientMode(): boolean {
    // return true;
    return this.config.mod === ESelectLocationMod.client;
  }

  get isMaintenanceMode(): boolean {
    if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.mapMaintenance) {
      return true;
    }
    return false;
  }

  handleUnitChanged(e): void {
    this.getBuildingsByUnit();
    this.isDefaultLocation();
    if(this.buildingsAutocompleteComponent.selectedItemCode){
      this.getFloorsByUnit().subscribe();
    }
    if (this.isClientMode && this.wingGroupCode) {
      this.neighborhoodGroupAutocompleteComponent.selectedItemCode = null;
      this.getNeighborhoodsAllocation().subscribe();
    }
  }

  getBuildingsByUnit(): void {
    this.unitsErrorMsg = '';
    if (!this.buildingsAutocompleteComponent.selectedItemCode) {
      // this.resetWingGroupForm();
      this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
      this.mapService.getBuildingsByUnit(this.countriesAutocompleteComponent.selectedItemCode, this.unitsAutocompleteComponent.selectedItem.code).pipe(
        mergeMap((res: IBuilding[]) => {
          this.buildings = res || [];
          this.isUnitSelectedFirst = true;
          this.resetForm(
            ['buildingsDDLItems', 'floorsDDLItems'],
            [this.buildingsAutocompleteComponent, this.floorsAutocompleteComponent], false);
          this.setBuildingsDDL();
          return of(res);
        }),
        catchError(err => {
          this.error = err;
          this.genericApiErrorCB.emit(err);
          return throwError(err);
        }),
        first(),
        finalize(() => {
          this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      ).subscribe();
    }
  }

  getNeighborhoodsAllocation(): Observable<INeighborhoodUser[]> {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    return this.foundationService.getNeighborhoodsAllocation(
      this.wingGroupCode,
      this.unitsAutocompleteComponent?.selectedItem ? this.unitsAutocompleteComponent?.selectedItem?.code : null
    ).pipe(
      mergeMap((res: INeighborhoodUser[]) => {
        this.allNeighborhoods = res;
        // if (res.length > 1) {
        //   res.map(nb => nb.neighborhoods.map(n => this.neighborhoods.push(n)));
        // } else {
        //   this.neighborhoods = res[0]?.neighborhoods || [];
        // }
        this.neighborhoods = res.find(n => n.unitCode === this.unitsAutocompleteComponent?.selectedItem?.code)?.neighborhoods;
        if (this.neighborhoods?.length > 0){
          this.setNeighborhoodsDDl();
        } else {
          this.disabledNeighborhood = true;
        }
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    );
  }

  // getSelectedWings(): string[] {
  //   const selectedWingArr = [];
  //   if (!this.wingGroupForm) {
  //     return [];
  //   }
  //   Object.keys(this.wingGroupForm.controls).forEach(control => {
  //     if (this.wingGroupForm.get(control).value) {
  //       selectedWingArr.push(control);
  //     }
  //   });
  //   return selectedWingArr;
  // }

  // isEqualWings(selectedWings): boolean {
  //   const currentWings = this.wingGroups.find(wing => wing.code === this.wingGroupAutocompleteComponent.selectedItemCode)?.wings;
  //   if (JSON.stringify(selectedWings) === JSON.stringify(currentWings) && currentWings) {
  //     return true;
  //   }
  //   return false;
  // }

  setSelectLocationState(): void {
    if(this.isClientMode){
      this.wingChange.emit(this.wings.find(w => w.code === this.wingsAutocompleteComponent.selectedItemCode));
    }
    const locationState: ILocation = {
      country: {
        code: this.countriesAutocompleteComponent.selectedItemCode,
        name: this.countries.find(c => c.code === this.countriesAutocompleteComponent.selectedItemCode).name,
        region: this.countries.find(c => c.code === this.countriesAutocompleteComponent.selectedItemCode).region
      },
      building: {
        code: this.buildingsAutocompleteComponent.selectedItemCode,
        name: this.buildings.find(b => b.code === this.buildingsAutocompleteComponent.selectedItemCode)?.name
      },
      floor: {
        code: this.floorsAutocompleteComponent.selectedItemCode,
        name: this.floors.find(f => f.code === this.floorsAutocompleteComponent.selectedItemCode).name
      }
    };
    if (this.wingGroupCode) {
      locationState.wingGroup = {
        code: this.wingGroupCode,
        name: this.wingGroupCode
      };
    }
    if (this.wingsAutocompleteComponent?.selectedItemCode) {
      locationState.wing = {
        code: this.wingsAutocompleteComponent.selectedItemCode,
        name: this.wings.find(w => w.code === this.wingsAutocompleteComponent.selectedItemCode).name
      };
    }
    if (this.unitsAutocompleteComponent?.selectedItem?.code && (this.isUnitMode || this.isClientMode)) {
      locationState.unit = {
        code: this.unitsAutocompleteComponent.selectedItem.code,
        name: this.unitsAutocompleteComponent.selectedItem.name
      };
    }
    if (this.isClientMode && this.neighborhoodGroupAutocompleteComponent.selectedItemCode) {
      locationState.neighborhood = {
        code: this.neighborhoodGroupAutocompleteComponent.selectedItemCode,
        name: this.neighborhoodDDLItems.find(n => n.code === this.neighborhoodGroupAutocompleteComponent.selectedItemCode).name
      };
    }
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(locationState));
  }

  validateForm(): boolean {
    return !!this.countriesAutocompleteComponent.selectedItemCode &&
      !!this.buildingsAutocompleteComponent.selectedItemCode &&
      !!this.floorsAutocompleteComponent.selectedItemCode &&
      !!this.wingGroupCode;
  }

  get isValidateForm(): boolean {
    if (this.isUnitMode) {
      if (!this.unitsAutocompleteComponent.selectedItem?.code) {
        return false;
      }
    }
    if (this.countriesAutocompleteComponent.selectedItemCode &&
      this.buildingsAutocompleteComponent.selectedItemCode &&
      this.floorsAutocompleteComponent.selectedItemCode &&
      (this.isClientMode ? this.wingsAutocompleteComponent.selectedItemCode : true)
      || (!this.isClientMode)
    ) {
      return true;
    }
    return false;
  }

  submitLocation(): void {
    if (this.isValidateForm) {
      const focusWing = this.wingsFromMap?.find(w => w.code === this.wingsAutocompleteComponent?.selectedItemCode);
      this.eventBusService.emit(CONSTANTS.EVENTS.MAP_FLY_TO, focusWing);
      if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.mapMaintenance) {
        if (this.wingGroupCode) {
          this.setSelectLocationState();
          this.handleMapMaintenanceSelection();
        } else {
          this.setErrorMessages();
        }
        // }
      } else if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.unitsAllocation) {
        this.setSelectLocationState();
        this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE + '/' + CONSTANTS.ROUTES.ADMIN.CHILDREN.UNITS_MAINTENANCE],
          {queryParams: {wingGroup: this.wingGroupCode}});
        this.close.emit();
      } else if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.neighborhoodAllocation) {
        if (this.wingGroupCode && this.unitsAutocompleteComponent.selectedItem) {
          this.setSelectLocationState();
          this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE + '/' + CONSTANTS.ROUTES.ADMIN.CHILDREN.NEIGHBORHOOD_MANAGEMENT], {
            queryParams: {
              wingGroup: this.wingGroupCode,
              unit: this.unitsAutocompleteComponent.selectedItem.code
            }
          });
          this.close.emit();
        } else {
          this.setErrorMessages();
        }
      } else if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.qrGeneration) {
        const buildingCode = Number(this.buildingsAutocompleteComponent.selectedItemCode); // Adjust as necessary
        const countryCode = this.countriesAutocompleteComponent.selectedItemCode; // Adjust as necessary
        const seatCode =  '';
        const floorCode = Number(this.floorsAutocompleteComponent.selectedItemCode);
        const createNewQRCodes = true; // Adjust as necessary
        const wingGroupCode = this.wingGroupCode;
        
        this.qrService.generateQRCodes(buildingCode, countryCode, seatCode, floorCode, createNewQRCodes, wingGroupCode).subscribe(response => {
          console.log('QR Codes generated:', response);
          this.eventBusService.emit(CONSTANTS.EVENTS.SHOW_CANCEL_BOOKING_MSG, {title:response.generateQRCodes.message});
          this.close.emit();
          // Handle the response as necessary
        }, error => {
          console.error('Error generating QR Codes:', error);
          this.close.emit();
        });
      
      } else if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.qrDownload) {
        const buildingCode = this.buildingsAutocompleteComponent.selectedItemCode; // Adjust as necessary
        const countryCode = this.countriesAutocompleteComponent.selectedItemCode; // Adjust as necessary
        const seatCode =  '';
        const floorCode = Number(this.floorsAutocompleteComponent.selectedItemCode);
        const createNewQRCodes = false; // Adjust as necessary
        const wingGroupCode = this.wingGroupCode;
        
        this.qrService.generateQRCodes(buildingCode, countryCode, seatCode, floorCode, createNewQRCodes, wingGroupCode).subscribe(response => {
          console.log('QR Codes generated:', response);
          const headers = response.headers;
          console.log('Response Headers:', headers);
          // Extract the URL from the response
          const zipFileKey = response.generateQRCodes.message;
          if(zipFileKey != "Invalid Request: Zip File does not exist in S3."){
            const downloadUrl = `${window.location.origin}/${zipFileKey}`;
            window.open(downloadUrl, '_blank');
          }else{
            this.eventBusService.emit(CONSTANTS.EVENTS.SHOW_CANCEL_BOOKING_MSG, {title:response.generateQRCodes.message.split(': ')[1]});
          }
         
  
          this.close.emit();
          // Handle the response as necessary
        }, error => {
          console.error('Error generating QR Codes:', error);
          this.close.emit();
        });
      
      }else if (this.config.mod === ESelectLocationMod.admin && this.config.subMod === ESelectLocationSubMod.qrDownload) {
      
      }

      else if (this.config.mod === ESelectLocationMod.client && this.config.subMod === ESelectLocationSubMod.clientMapPage) {
        this.setSelectLocationState();
        if (this.setAsMyDefaultLocationControl.value) {
          this.setAsDefaultLocation();
        }
        sessionStorage.removeItem('amenities');

        // TODO:: HANDLE ROUTE CHANGE OR FIRE EVENT TO THE USER MAP PAGE
        if (this.isMobile) {
          this.router.navigate([CONSTANTS.ROUTES.BOOK_A_SEAT]);
          return;
        } else {
          this.eventBusService.emit(CONSTANTS.EVENTS.USER_MAP_NAVIGATION, {
            wingGroupCode: this.wingGroupCode,
            unitCode: this.unitsAutocompleteComponent.selectedItem?.code || -1,
            nbCode: this.neighborhoodGroupAutocompleteComponent.selectedItemCode || -1
          });
        }
        this.close.emit();
        return;
      }

    } else {
      this.setErrorMessages();
    }
  }

  setErrorMessages(): void {
    const errText = this.translateService.getText('selectLocation.errorMsg');
    if (!this.countriesAutocompleteComponent?.selectedItemCode) {
      this.countriesErrorMsg = errText;
    }
    if (!this.buildingsAutocompleteComponent?.selectedItemCode) {
      this.buildingsErrorMsg = errText;
    }
    if (!this.floorsAutocompleteComponent?.selectedItemCode) {
      this.floorsErrorMsg = errText;
    }
    if (!this.wingsAutocompleteComponent?.selectedItemCode) {
      this.wingsErrorMsg = errText;
    }
    if (this.isUnitMode) {
      if (!this.unitsAutocompleteComponent.selectedItem?.code) {
        this.unitsErrorMsg = errText;
      }
    }
  }

  onCountriesChanges(event: IAutocompleteItem): void {
    this.resetForm(
      ['buildingsDDLItems', 'floorsDDLItems', 'neighborhoodsDDLItems', 'wingsDDLItems'],
      [this.buildingsAutocompleteComponent, this.floorsAutocompleteComponent, this.neighborhoodGroupAutocompleteComponent, this.wingsAutocompleteComponent],
      true);
    // this.disabledBuildings = true;
    this.disabledFloor = true;
    this.disabledWings = true;
    this.disabledNeighborhood = true;
    // this.resetWingGroupForm();
    if (event?.code) {
      this.countriesErrorMsg = '';
      this.countryChanged = true;
      this.getBuildingsUnitsByCountry().subscribe();
      this.isDefaultLocation();
    }
  }

  onNeighborhoodChanges(event: IAutocompleteItem): void {
    this.isDefaultLocation();
    if (!this.unitsAutocompleteComponent.selectedItem || !this.unitsAutocompleteComponent.selectedItem.code) {
      const currentUnitCode = this.allNeighborhoods.find(userNeighborhoods =>
        userNeighborhoods.neighborhoods.find(n => n.code === this.neighborhoodGroupAutocompleteComponent.selectedItemCode)).unitCode;
      const currentUnit = this.unitsList.find(u => u.code === currentUnitCode);
      this.unitsAutocompleteComponent.setSelectedItem(currentUnit);
    }
  }

  getBuildingsUnitsByCountry(): Observable<IBuildingAndUnits> {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    const isAdminNBPage = true;
    return this.foundationService.getBuildingsUnitsByCountry(this.countriesAutocompleteComponent.selectedItemCode, isAdminNBPage).pipe(
      mergeMap((res: IBuildingAndUnits) => {
        this.buildings = res.buildings;
        this.unitsResponse = res.org2units;
        this.setBuildingsDDL();
        this.setUnitsDDL();
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    );
  }

  onWingsChange(): void {
    this.showEqualErrMsg = false;
  }

  onBuildingsChanges(event: IAutocompleteItem): void {
    this.resetForm(
      ['floorsDDLItems', 'wingsDDLItems', 'neighborhoodDDLItems'],
      [this.floorsAutocompleteComponent, this.wingsAutocompleteComponent, this.neighborhoodGroupAutocompleteComponent]
    );
    if (event?.code) {
      this.isDefaultLocation();
      this.buildingsErrorMsg = '';
      if (this.isUnitSelectedFirst) {
        this.getFloorsByUnit().subscribe();
      } else {
        if (this.unitsAutocompleteComponent) {
          this.unitsAutocompleteComponent.setSelectedItem(null);
        }
        this.getUnitsFloorByBuilding().subscribe();
      }
    }
  }

  onFloorsChanges(event: IAutocompleteItem): void {
    if (event?.code) {
      this.floorsErrorMsg = '';
      this.resetForm(
        ['wingsDDLItems', 'neighborhoodDDLItems'],
        [this.wingsAutocompleteComponent, this.neighborhoodGroupAutocompleteComponent],
      );
      this.showEqualErrMsg = false;
      // this.setWingGroupsDDL();

      if (event?.code) {
        this.isDefaultLocation();
        this.floorsErrorMsg = '';
        this.getWingsWingsWingGroupsByFloor().subscribe();

      }
    }
  }

  onWingsChanges(event: IAutocompleteItem): void {
    if (event?.code) {
      this.wingsErrorMsg = null;
      this.isDefaultLocation();
    }
  }

  getUnitsByWingGroup(): Observable<IUnit[]> {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    return this.foundationService.getUnitsByWingGroup(this.countriesAutocompleteComponent.selectedItemCode, this.wingGroupCode).pipe(
      mergeMap((res: IUnit[]) => {
        this.unitsList = res;
        this.setUnitsDDL();
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    );
  }

  getWingsWingGroupsByUnit(): Observable<IWingsAndWingGroups> {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    return this.foundationService.getWingsWingGroupsByUnit(
      this.countriesAutocompleteComponent.selectedItemCode,
      this.buildingsAutocompleteComponent.selectedItemCode,
      this.floorsAutocompleteComponent.selectedItemCode,
      this.unitsAutocompleteComponent.selectedItem.code).pipe(
      mergeMap((res: IWingsAndWingGroups) => {
        this.wingGroupCode = res.wingGroupCode
        // this.setWingGroupsDDL();
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    );
  }

  getUnitsFloorByBuilding(): Observable<IFloorsAndUnits> {
    this.unitsList = [];
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    return this.foundationService.getUnitsFloorByBuilding(this.countriesAutocompleteComponent.selectedItemCode, this.buildingsAutocompleteComponent.selectedItemCode).pipe(
      mergeMap((res: IFloorsAndUnits) => {
        this.floors = res.floors || [];
        this.unitsResponse = res.org2units || [];
        this.setUnitsDDL();
        this.setFloorsDDL();
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    );
  }

  getFloorsByUnit(): Observable<IFloor[]> {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    return this.foundationService.getFloorsByUnit(
      this.countriesAutocompleteComponent.selectedItemCode,
      this.buildingsAutocompleteComponent.selectedItemCode,
      this.unitsAutocompleteComponent.selectedItem.code).pipe(
      mergeMap((res: IFloor[]) => {
        this.floors = res;
        this.setFloorsDDL();
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    );
  }

  getWingsWingsWingGroupsByFloor(): Observable<IUnitsWingsWingGroups> {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    return this.foundationService.getWingsWingsWingGroupsByFloor(
      this.countriesAutocompleteComponent.selectedItemCode,
      this.buildingsAutocompleteComponent.selectedItemCode,
      this.floorsAutocompleteComponent.selectedItemCode
    ).pipe(
      mergeMap((res: IUnitsWingsWingGroups) => {
        this.wingGroupCode = res.wingGroupCode;
        this.unitsResponse = res.org2units;
        this.wings = res.wings;
        this.setWingsDDL();

        if (this.isClientMode && this.wingGroupCode && this.isUnitSelectedFirst) {
          this.neighborhoodGroupAutocompleteComponent.selectedItemCode = null;
          this.getNeighborhoodsAllocation().subscribe();
        }

        // this.setWingGroupsDDL();
        // this.setUnitsDDL();
        if (this.isMaintenanceMode) {
          // this.wings = res.wings;
          // this.isManageWingsLinkDisable = false;
          // this.setMangeWingGroup();
        }
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    );
  }

  setUserCountry(userProfileCountry): void {
    const selectedCountry = this.countriesDDLItems.find(country => country.code === userProfileCountry);
    if (selectedCountry) {
      this.countriesAutocompleteComponent.selectedItemCode = selectedCountry.code;
      this.getBuildingsUnitsByCountry().subscribe();
    }
  }

  setCountriesDDL(): void {
    this.countries = this.foundationService.countries || [];
    this.countries.map(country => {
      this.countriesDDLItems.push({code: country.code, name: country.name});
    });
    this.disabledCountries = false;
  }

  setBuildingsDDL(): void {
    const items: IAutocompleteItem[] = [];
    this.buildings.map(building => {
      items.push({code: building.code, name: building.name});
    });
    this.buildingsDDLItems = items;
    this.disabledBuildings = false;
  }

  setFloorsDDL(): void {
    const items: IAutocompleteItem[] = [];
    this.floors.map(floor => {
      items.push({code: floor.code, name: floor.name});
    });
    this.floorsDDLItems = items;
    this.disabledFloor = false;
  }

  setWingsDDL(): void {
    const list = [];
    this.wings.map(wing => {
      list.push({code: wing.code, name: wing.name});
    });
    this.wingsDDLItems = list;
    // if (this.wingsAutocompleteComponent){
    //   this.wingsAutocompleteComponent.selectedItemCode = 'All'
    // }
    this.disabledWings = false;
  }

  setUnitsDDL(): void {
    if (this.unitsResponse) {
      const unitsArr = [];
      this.unitsResponse.map((org2units) => {
        unitsArr.push({
          code: org2units.code,
          name: org2units.name,
          isGroup: true,
          isActive: org2units.isActive,
          disabled: !org2units.isActive
        });
        org2units.org3units.map((org3Unit) => {
          unitsArr.push({code: org3Unit.code, name: org3Unit.name});
        });
      });
      this.unitsList = unitsArr;
      if (this.unitsAutocompleteComponent){
        this.unitsAutocompleteComponent.clearSelected();
      }

      if(this.buildingsAutocompleteComponent){
        // console.log(this.buildingsAutocompleteComponent);
      }
      this.disabledUnits = false;
    }
  }

  setNeighborhoodsDDl(): void {
    const items: IAutocompleteItem[] = [];
    this.neighborhoods.map(neighborhood => {
      items.push({code: neighborhood.code, name: neighborhood.name});
    });
    this.neighborhoodDDLItems = items;
    this.disabledNeighborhood = false;
  }

  resetForm(itemsArray: string[], elementArray: AmdocsAutocompleteComponent[] = [], resetAutocomplete = false): void {
    itemsArray.forEach(items => this[items] = []);
    elementArray.forEach(element => {
      if (element) {
        element.selectedItemCode = null;
      }
    });
    if (resetAutocomplete && this.unitsAutocompleteComponent) {
      this.unitsList = [];
      this.unitsAutocompleteComponent.clearSelected();
    }
  }

  cancel(): void {
    if (this.config.mod === ESelectLocationMod.client && this.isMobile) {
      this.router.navigate([CONSTANTS.ROUTES.BOOK_A_SEAT]);
      return;
    }
    this.close.emit();
  }

  clear(): void {
    this.buildingsAutocompleteComponent.selectedItemCode = null;
    this.floorsAutocompleteComponent.selectedItemCode = null;
    if (this.wingsAutocompleteComponent) {
      this.wingsAutocompleteComponent.selectedItemCode = null;
    }
    this.wingGroupCode = null;
    if (this.unitsAutocompleteComponent) {
      this.unitsAutocompleteComponent.clearSelected();
    }
    if (this.isClientMode) {
      this.neighborhoodGroupAutocompleteComponent.selectedItemCode = null;
    }
    // this.unitsList = [];
    this.neighborhoodDDLItems = [];

    this.disabledNeighborhood = true;
    // this.disabledUnits = true;
    this.disabledFloor = true;
    this.disabledWings = true;
    this.isUnitSelectedFirst = false;
    this.getBuildingsUnitsByCountry().subscribe();
    // this.unitsList = []; // TODO lev check if fix CCA-1679
  }

  handleMapMaintenanceSelection(): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.mapService.getMapsList(this.wingGroupCode).pipe(
      mergeMap((res: IMap[]) => {
        if (res.length) {

        }
        const activeMap = res.find(map => map.isActive) || res[0];
        this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE + '/' + CONSTANTS.ROUTES.ADMIN.CHILDREN.MAP_MAINTENANCE],
          {
            queryParams: {
              version: activeMap?.version,
              wingGroup: this.wingGroupCode
            }
          });
        this.close.emit();
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      first(),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    ).subscribe();
  }

  setAsDefaultLocation(): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.foundationService.setDefaultLocation(
      this.wingGroupCode,
      this.neighborhoodGroupAutocompleteComponent?.selectedItemCode,
      this.unitsAutocompleteComponent.selectedItem?.code,
      this.wingsAutocompleteComponent?.selectedItemCode
    ).pipe(
      mergeMap((res: boolean) => {
        return of(res);
      }),
      catchError(err => {
        this.error = err;
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      first(),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    ).subscribe();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }
}
