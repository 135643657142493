import {ICodeNameItem} from './map';

export interface ICodeNameAmenity {
  code: string;
  name: string;
}

export interface IAdminAmenityRegion{
  adminAmenities: IAdminAmenity[];
  amenitiesOrder?: IAmenityOrder[];
  regions: ICodeNameItem[];
}

export interface IAdminAmenity extends ICodeNameAmenity{
  amenities: IAmenity[];
  amenitiesOrder?: {
    code: string,
    order: number
  }[];
  regionCode: string;
}

export interface IUserAmenity extends ICodeNameAmenity{
  amenities: IAmenity[];
  amenitiesOrder: IAmenityOrder[];
}

export interface IAmenityOrder {
  code: string;
  order: number;
}

export interface IAmenity {
  code?: string;
  name: string;
  comments: string;
  isHidden: boolean;
  isDefault?: boolean;
  regionCode?: string;
  order?: number;
}

export const getAmenitiesAsAdmin = `
query GetAmenitiesAsAdmin {
  regions {
    code
    name
  }

  adminAmenities {
    regionCode
    amenities {
      code
      name
      comments
      isHidden
      isDefault
    }
    amenitiesOrder{
      code
      order
    }
  }
}
`;

// export const getAmenitiesAsAdmin = `
//   query GetAmenitiesAsAdmin {
//     adminAmenities {
//       code
//       name
//       amenities {
//        code
//         name
//         comments
//         isHidden
//       }
//     }
// }`;

export const getAmenitiesAsUser = `
query GetAmenitiesAsUser($regionCode: String!) {
  userAmenities(regionCode: $regionCode) {
    amenities {
      code
      name
      isDefault
    }
    amenitiesOrder {
      code
      order
    }
  }
}`;


export const updateAmenity = `
mutation UpdateAmenity($input: UpdateAmenityInput!) {
  updateAmenity(input: $input)
}
`;

export const createAmenity = `
  mutation CreateAmenity($input: CreateAmenityInput!) {
    createAmenity(input: $input) {
      code
    }
  }
`;

export const setDefaultAmenitiesQuery = `
mutation SetDefaultAmenities($input: DefaultAmenitiesInput!) {
  setDefaultAmenities(input: $input)
}
`;

export const saveAmenitiesOrder = `
mutation SaveAmenitiesOrder($regionCode: String!, $amenitiesOrder: [AmenityOrderInput!]!) {
  saveAmenitiesOrder(regionCode: $regionCode, amenitiesOrder: $amenitiesOrder)
}
`;
