export enum EBookingErrors {
  BOOKING_MISSING = 'BOOKING_MISSING',
  BOOKING_CONFLICT = 'BOOKING_CONFLICT',
  BOOKING_COLLISION = 'BOOKING_COLLISION',
  BOOKING_LOCK_CORRUPTED = 'BOOKING_LOCK_CORRUPTED',
  BOOKING_UNAVAILABLE = 'BOOKING_UNAVAILABLE',
  BOOKING_MULTI = 'BOOKING_MULTI',
  BOOKING_ASSIGNED = 'BOOKING_ASSIGNED',
  BOOKING_RESERVED_BY = 'BOOKING_RESERVED_BY',
  BOOKING_RESERVED_FOR = 'BOOKING_RESERVED_FOR',
  BOOKING_LE_USER = 'BOOKING_LE_USER',
  BOOKING_LE = 'BOOKING_LE',
  BOOKING_END_LT_NOW = 'BOOKING_END_LT_NOW',
  BOOKING_END_LT_START = 'BOOKING_END_LT_START',
  BOOKING_END_GT_MAX = 'BOOKING_END_GT_MAX',
  BOOKING_START_LT_MIN = 'BOOKING_START_LT_MIN',
  BOOKING_START_GT_MAX = 'BOOKING_START_GT_MAX',
  BOOKING_CANCEL_NOPE = 'BOOKING_CANCEL_NOPE',
}

export enum EGenericErrors {
  GENERIC = 'GENERIC',
  LOCATION_INVALID = 'LOCATION_INVALID',
  LOCATION_NO_TZ = 'LOCATION_NO_TZ',
  SEAT_INVALID = 'SEAT_INVALID',
  SYSPARAMS_MISSING = 'SYSPARAMS_MISSING',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NO_PROFILE = 'USER_NO_PROFILE',
  DATETIME_INVALID = 'DATETIME_INVALID',
  DATETIME_FORMAT = 'DATETIME_FORMAT',
  DATETIME_MINUTES = 'DATETIME_MINUTES',
  DATETIME_SECONDS = 'DATETIME_SECONDS',
  DATETIME_MILLISECONDS = 'DATETIME_MILLISECONDS',
}

export enum EMapErrors {
  ACTIVEMAP_NOTFOUND = 'ACTIVEMAP_NOTFOUND',
  MAP_ACCESS_DENIED = 'MAP_ACCESS_DENIED',
}
