import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {AmdocsEventBusService} from 'amdocs-core';
import {CONSTANTS} from '../../../../constants';

@Component({
  selector: 'app-right-side-panel-header',
  templateUrl: './right-side-panel-header.component.html',
  styleUrls: ['./right-side-panel-header.component.scss']
})
export class RightSidePanelHeaderComponent implements OnInit {

  constructor(private eventBusService: AmdocsEventBusService) { }
  @Input() rightPanelTitle: string;
  @Output() rightPanelChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }

  public closeRightPanel(): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_MAP_PAGE_SIDEBAR, false);
    this.rightPanelChange.emit(false);
  }

}
