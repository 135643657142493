import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {
  IAmenities,
  IAmenity,
  ICodeNameItem,
  ICountry,
  ILocation,
  IUserAmenity,
  SelectedMapFiltersDetails
} from '../../../models';
import {SelectedMapFiltersDetailsService} from '../../../core/selected-map-filters-details.service';
import {AmdocsEventBusService, ApiError} from 'amdocs-core';
import {catchError, finalize, mergeMap} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {ApiService} from '../../../core/api.service';
import {CONSTANTS} from '../../../constants';
import {FunctionsService} from '../../../core/functions.service';

@Component({
  selector: 'app-change-amenities',
  templateUrl: './change-amenities.component.html',
  styleUrls: ['./change-amenities.component.scss']
})
export class ChangeAmenitiesComponent implements OnInit {

  @Input() footerText: string;
  @Output() cancelCallback = new EventEmitter<string[]>();
  @Output() genericApiErrorCB = new EventEmitter<ApiError>();
  public region: string;
  public amenities: IAmenity[] = [];
  public defaultAmenities: IAmenities[];
  public checkBoxForm = new FormGroup({});
  public amenitiesCodeNameList: ICodeNameItem[] = [];
  public amenitiesCodeList: string[] = [];
  public setAsMyDefaultLocationControl: FormControl = new FormControl();
  public showDefault = false;
  public selectLocationClient: ILocation;

  constructor(
    private selectedMapFiltersDetailsService: SelectedMapFiltersDetailsService,
    private eventBusService: AmdocsEventBusService,
    private apiService: ApiService,
    private functionsService: FunctionsService
  ) {
  }

  ngOnInit(): void {
    this.selectedMapFiltersDetailsService.getSelectedMapFiltersDetails().subscribe((filters: SelectedMapFiltersDetails) => {
      this.selectLocationClient = filters.selectLocationClient;
      this.region = filters.selectLocationClient?.country?.region;
      if (filters.amenities) {
        this.defaultAmenities = filters.amenities;
      }
      this.getAmenities();
    });
  }

  getCountries(): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.apiService.getCountries().pipe(
      mergeMap((countries: ICountry[]) => {
        this.region = countries.find(c => c.countryCode === this.selectLocationClient?.country?.code)?.regionCode;
        this.getAmenitiesAsUser();
        return of(countries);
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    ).subscribe();
  }

  getAmenitiesAsUser(): void {
    this.apiService.getAmenitiesAsUser(this.region).pipe(
      mergeMap((userAmenities: IUserAmenity) => {
        const orderArray: IAmenity[] = this.functionsService.getOrderedListByCode(userAmenities.amenities, userAmenities.amenitiesOrder);
        this.amenities = orderArray;
        this.createAmenitiesCBForm();
        return of(userAmenities);
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    ).subscribe();
  }

  getAmenities(): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    if (!this.region) {
      this.getCountries();
    } else {
      this.getAmenitiesAsUser();
    }

  }

  createAmenitiesCBForm(): void {
    if (this.defaultAmenities?.length > 0 || (this.defaultAmenities?.length === 0 && JSON.parse(sessionStorage.getItem('amenities')))) {
      this.createAmenitiesCBByUserSetting();
    } else {
      this.createAmenitiesByAmenities();
    }
  }

  createAmenitiesByAmenities(): void {
    const group = {};
    this.amenities.map(a => {
      group[a.code] = new FormControl(a.isDefault);
    })
    this.checkBoxForm = new FormGroup(group);
  }

  createAmenitiesCBByUserSetting(): void {
    const group = {};
    this.amenities.map(a => {
      if (this.defaultAmenities?.find(d => d.code === a.code)) {
        group[a.code] = new FormControl(true);
      } else {
        group[a.code] = new FormControl(false);
      }
    });
    this.checkBoxForm = new FormGroup(group);
  }

  cancel(event): void {
    event.stopPropagation();
    this.cancelCallback.emit();
  }

  submit(event): void {
    this.setUpdatedAmenities();
    if (this.setAsMyDefaultLocationControl.value) {
      this.setDefaultAmenities(event);
    } else {
      this.setValuesInStorage();
      this.cancel(event);
    }
  }

  public setUpdatedAmenities(): void {
    const amenitiesCodeNameList: ICodeNameItem[] = [];
    const amenitiesCodeList: string[] = [];
    Object.keys(this.checkBoxForm.controls).forEach(control => {
      if (this.checkBoxForm.controls[control].value === true) {
        const amenityItem = this.amenities.find(a => a?.code === control);
        amenitiesCodeNameList.push({code: amenityItem.code, name: amenityItem.name});
        amenitiesCodeList.push(amenityItem.code);
      }
    });
    this.amenitiesCodeNameList = amenitiesCodeNameList;
    this.amenitiesCodeList = amenitiesCodeList;
  }


  public setDefaultAmenities(event): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.apiService.setDefaultAmenities(this.region, this.amenitiesCodeList).pipe(
      mergeMap((res: boolean) => {
        return of(res);
      }),
      catchError(err => {
        this.genericApiErrorCB.emit(err);
        return throwError(err);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        this.setValuesInStorage();
        this.cancel(event);
      })
    ).subscribe();
  }

  public setValuesInStorage(): void {
    this.selectedMapFiltersDetailsService.setAmenitiesCache(this.amenitiesCodeNameList);
    this.eventBusService.emit(CONSTANTS.EVENTS.USER_MAP_NAVIGATION, {amenities: this.amenitiesCodeList});
  }
}
