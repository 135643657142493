<div class="selected-employees-wrapper">
  <div class="reserved-list-wrapper d-flex align-items-center justify-content-between">
    <div class="reserved-list-counter">
      <span class="reserved-list-counter-title text-small-bold">{{'employeeSearch.reservedList' | amdocsTranslate}}</span>
      <span class="reserved-list-counter-qty text-small-normal">
        ({{reservedListCounter}}
        {{'employeeSearch.reservedListCounterTitle' | amdocsTranslate}})
      </span>
      <lib-amdocs-popover *ngIf="isUnitRestricted" [trigger]="'click'" [placement]="'top'">
        <img lib-amdocs-image-version popoverOpener class="restricted-unit-info" src="assets/icons/info.svg"/>
        <div popoverContent>{{'employeeSearch.restrictedUnitInfo' | amdocsTranslate}}</div>
      </lib-amdocs-popover>
    </div>
    <div class="reserved-list-button" *ngIf="selectedEmployees.length">
      <button class="btn btn-text remove-btn" (click)="removeAllEmployees(true)">
        {{'employeeSearch.removeAllEmployees' | amdocsTranslate}}
      </button>
    </div>
  </div>
  <div class="selected-employees-list" *ngIf="selectedEmployees.length">
    <div *ngFor="let employee of selectedEmployees"
         class="selected-employees-row-wrapper d-flex align-items-center justify-content-between"
         >
      <div class="selected-employees-row-name text-small-normal">{{employee.displayName}}</div>
      <button class="btn btn-text selected-employees-row-remove align-items-center justify-content-center" (click)="removeEmployee(employee.id)">
        <img lib-amdocs-image-version src="assets/icons/employee-remove.svg"/>
      </button>
    </div>
  </div>
</div>
