import {ICodeNameItem, IExtendedSeat} from './map';
import {IMapBooking} from "./user-map";

export interface ISeatBooking {
  code: string;
  seatCode: string;
  seatName: string;
  roomCode?: string;
  bookedBy: ICodeNameItem;
  bookedFor: ICodeNameItem;
  bookingTime: {
    start: string;
    end: string;
  };
  location: {
    country: {
      code: string;
      name: string;
      region?: string
    };
    building: ICodeNameItem;
    floor: ICodeNameItem;
    wingGroup: ICodeNameItem;
  };
  isFavorite?: boolean;
  isCancelled?: boolean;

  // seatId: string;
  // bookingId: string;
  // bookingTime: {
  //   start: string;
  //   end: string;
  // };
  // location: {
  //   country: string;
  //   building: string;
  //   floor: string;
  //   wingGroupCode: string;
  // };
}

export interface IUpdateSeatBooking {
  bookingTime: {
    start: string;
    end: string;
  };
  bookedBy: {
    code: string;
    name: string;
  };
  bookedFor: {
    code: string;
    name: string;
  };
  isCancelled: boolean;
  seatCode: IExtendedSeat;
  roomCode: IExtendedSeat;
}
export interface ICreateBookingRes{
  bookings: IMapBooking[];
  success: boolean;
  wingGroupCode: string;
}
export interface ICreateBooking {
  bookingTime: {
    start: string;
    end: string;
  };
  bookedBy: {
    code: string;
    name: string;
  };
  bookedFor: {
    code: string;
    name: string;
  };
  isCancelled: boolean;
  seat: IExtendedSeat;
  room: IExtendedSeat;
  seatCode?: string;
  code?: string;
}

export interface IMyBookings {
  bookedForMe: ISeatBooking[];
  bookedForOthers: ISeatBooking[];
  recentBookings: ISeatBooking[];
}

export interface ICancelBookingRes {
  cancelBooking: {
    success: boolean;
    wingGroupCode: string
    code: string
  }
}
// export const updateSeatBookingsQuery = `
// mutation UpdateSeatBookings($input: SeatBookingsInput!) {
//   updateSeatBookings(input: $input) {
//     code
//     bookingTime {
//       end
//       start
//     }
//     bookedBy {
//       code
//       name
//     }
//     bookedFor {
//       code
//       name
//     }
//     isCancelled
//     seat {
//       code
//       name
//       isAssigned
//       isReserved
//       isUnavailable
//       assignedUser {
//         code
//         name
//       }
//       reservedUsers {
//         code
//         name
//       }
//       org2unit
//       unit {
//         code
//         name
//       }
//       legalEntity {
//         code
//         name
//       }
//       isCrossEntity
//       neighborhoods {
//         name
//         code
//       }
//       amenities {
//           name
//           code
//       }
//       X
//       Y
//     }
//     room {
//       code
//       name
//       isReserved
//       isUnavailable
//       reservedUsers {
//         code
//         name
//       }
//       org2unit
//       unit {
//         code
//         name
//       }
//       legalEntity {
//         code
//         name
//       }
//       isCrossEntity
//       neighborhoods {
//         code
//         name
//       }
//       amenities {
//           name
//           code
//       }
//       X
//       Y
//     }
//   }
// }
// `;


export const cancelBookingQuery =  `
mutation CancelBooking($code: String!, $sourceSystem: SourceSystemType) {
  cancelBooking(code: $code, sourceSystem: $sourceSystem) {
    success
    wingGroupCode
    code
  }
}
`;

export const getMySeatsBookingQuery = `
query GetMyBookings {
  myBookings {
    bookedForMe {
      code
      seatCode
      seatName
      roomCode
      bookedBy {
        code
        name
      }
      bookedFor {
        code
        name
      }
      bookingTime {
        start
        end
      }
      location {
        country {
          code
          name
        }
        building {
          code
          name
        }
        floor {
          code
         name
        }
        wingGroup {
          code
          name
        }
        wing {
          code
          name
        }
      }
      isCancelled
      cancellationReason
    }
    bookedForOthers {
      code
      seatCode
      seatName
      roomCode
      bookedBy {
        code
        name
      }
      bookedFor {
        code
        name
      }
      bookingTime {
        start
        end
      }
      location {
        country {
          code
          name
        }
        building {
          code
          name
        }
        floor {
          code
         name
        }
        wingGroup {
          code
          name
        }
        wing {
          code
          name
        }
      }
      isCancelled
      cancellationReason
    }
    recentBookings {
      code
      seatCode
      seatName
      roomCode
      bookedBy {
        code
        name
      }
      bookedFor {
        code
        name
      }
      bookingTime {
        start
        end
      }
      location {
        country {
          code
          name
        }
        building {
          code
          name
        }
        floor {
          code
          name
        }
        wingGroup {
          code
          name
        }
      }
      isCancelled
      cancellationReason
    }
  }
}
`;
