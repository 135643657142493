<div class="my-booking-wrappers d-flex flex-column">
  <app-nav-tabs *ngIf="showTabs" class="tabs-wrapper" [(selectedId)]="currentTab">
    <app-nav-tab [id]="tabs.upcoming"
                 [text]="'myBookings.upcoming' | amdocsTranslate"
                 [counter]="bookingSeatsUpcomingForMe.length + bookingSeatsUpcomingByMe.length"
                 (tabClick)="tabClick($event)"></app-nav-tab>
    <app-nav-tab [id]="tabs.recent" [text]="'myBookings.recent'| amdocsTranslate"
                 (tabClick)="tabClick($event)"></app-nav-tab>
  </app-nav-tabs>
  <div *ngIf="!loading" class="my-bookings-tab-content flex-grow-1">
    <div *ngIf="tabs.upcoming === currentTab">
      <div *ngIf="bookingSeatsForMe?.length || bookingSeatsByMe?.length; else noContent">
        <app-seat-booking-cards *ngIf="bookingSeatsUpcomingForMe" [seatsBooking]="bookingSeatsForMe"
                                [currentTab]="currentTab" (clickEvent)="openSeat($event)"
                                (removeBooked)="removeBooked($event)"
                                [isBookingForMe]="true"
                                [isBookingByMe]="false"
                                [favoriteSeats]="favoritesSeats"
                                (favorite)="updateFavorite($event, bookingSeatsUpcomingForMe)"></app-seat-booking-cards>
        <div *ngIf="bookingSeatsByMe" class="special-s1 booked-on">{{ 'myBookings.bookedOnBehalf' | amdocsTranslate }}</div>
        <app-seat-booking-cards *ngIf="bookingSeatsByMe" [seatsBooking]="bookingSeatsByMe"
                                [currentTab]="currentTab" (clickEvent)="openSeat($event)"
                                (removeBooked)="removeBooked($event)"
                                [isBookingForMe]="false"
                                [isBookingByMe]="true"
                                [favoriteSeats]="favoritesSeats"
                                (favorite)="updateFavorite($event, bookingSeatsByMe)"></app-seat-booking-cards>

      </div>
    </div>
    <div *ngIf="tabs.recent === currentTab">
      <div *ngIf="recentBooking?.length; else noContent">
        <app-seat-booking-cards *ngIf="recentBooking" [seatsBooking]="recentBooking"
                                [currentTab]="currentTab" (clickEvent)="openSeat($event)"
                                (removeBooked)="removeBooked($event)"
                                [isBookingForMe]="false"
                                [isBookingByMe]="false"
                                [favoriteSeats]="favoritesSeats"
                                (favorite)="updateFavorite($event, recentBooking)"></app-seat-booking-cards>
      </div>
    </div>

    <ng-template #noContent>
      <div class="no-content">
        <div class="img-wrapper">
          <img lib-amdocs-image-version src="assets/images/No-upcoming-bookings.svg" alt="No upcoming bookings">
        </div>
        <div class="text-medium-bold">
          <span *ngIf="tabs.upcoming === currentTab">{{ 'myBookings.upcomingEmpty' | amdocsTranslate }}</span>
          <span *ngIf="tabs.recent === currentTab">{{ 'myBookings.recentEmpty' | amdocsTranslate }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

