import {ICodeNameItem, ICreateMapPopupData, ICreateMapResponse, IWing} from './map';

export interface ICountry {
  code: string;
  name: string;
  region: string;
  buildings?: IBuilding[];
  org2units?: IUnit[];
  countryCode?: string;
  regionCode?: string;
}

export interface IBuilding {
  code: string;
  name: string;
}

export interface IFloorsAndUnits {
  floors: IFloor[];
  org2units: IUnit[];
}


export interface IUnitsWingsWingGroups {
  org2units: IUnit[];
  wingGroupCode: string;
  wings: IWing[];
}

export interface IBuildingAndUnits {
  buildings: IBuilding[];
  org2units: IUnit[];
}

export interface IWingsAndWingGroups {
  wingGroupCode: string;
  units: IUnit[];
}

export interface IUnit {
  code: string;
  name: string;
  org3units?: IOrg3units[];
  totalSeats?: number;
  rooms?: string[];
  seats?: string[];
  isActive?: boolean;
  isUnitRestricted?: boolean;
}

export interface ISelectedUnit extends IUnit {
  bookable: number;
  unbookable: number;
  assignedToEmployee: number;
}

export interface IOrg3units {
  code: string;
  name: string;
}

export interface IFloor {
  code: string;
  name: string;
  units: IUnit[];
  wingGroups: IWingGroups[];
  wings?: ICodeNameItem[];
}

export interface IWingGroups {
  code: string;
  name: string;
  units: IUnit[];
  wings: string[];
}

export const getCountriesQuery = `
  countries {
      countryCode
      countryName
      regionCode
    }
`;

export const getBuildingsByUnitQuery = `
query BuildingsByUnit($countryCode: String!, $unitCode: String!) {
  buildingsByUnit(countryCode: $countryCode, unitCode: $unitCode) {
    code
    name
  }
}
`;

export const getFloorsByUnitQuery = `
query FloorsByUnit($countryCode: String!, $buildingCode: String!, $unitCode: String!) {
  floorsByUnit(countryCode: $countryCode, buildingCode: $buildingCode, unitCode: $unitCode) {
    code
    name
  }
}
`;

export const getWingsWingGroupByUnitQuery = `
query WingsWingGroupByUnit($countryCode: String!, $buildingCode: String!, $floorCode: String!, $unitCode: String!) {
  wingsWingGroupByUnit(countryCode: $countryCode, buildingCode: $buildingCode, floorCode: $floorCode, unitCode: $unitCode) {
    wings {
      code
      name
    }
    wingGroupCode
  }
}
`;

export const getFloorsByBuildingQuery = `
query FloorsByBuilding($countryCode: String!, $buildingCode: String!) {
  floorsByBuilding(countryCode: $countryCode, buildingCode: $buildingCode) {
    org2units {
      code
      name
      isActive
      org3units {
        code
        name
      }
    }
    floors {
      code
      name
    }
  }
}
`;

export const getUnitsByWingGroupQuery = `
query GetUnitsByWingGroup($countryCode: String!, $wingGroupCode: String!) {
  unitsByWingGroup(countryCode: $countryCode, wingGroupCode: $wingGroupCode) {
    code
    name
    isActive
    org3units {
      code
      name
    }
  }
}
`;

export const getWingsWingsWingGroupByFloorQuery = `
query GetWingsWingGroupByFloor($countryCode: String!, $buildingCode: String!, $floorCode: String!) {
  wingsWingGroupByFloor(countryCode: $countryCode, buildingCode: $buildingCode, floorCode: $floorCode) {
    org2units {
      code
      name
      isActive
      org3units {
        code
        name
      }
    }
    wings {
      code
      name
    }
    wingGroupCode
  }
}
`;

export const getUnitsFoundationQuery = `
query UnitsFoundation {
  units {
    code
    name
    org3units {
      code
      name
    }
  }
}
`;


export const getBuildingsUnitsForNeighborhoodAllocation = `
query GetBuildingsUnitsForNeighborhoodAllocation($countryCode: String!) {
  buildingsUnitsNA(countryCode: $countryCode) {
    buildings {
      code
      name
    }
    org2units {
      code
      name
      isActive
      org3units {
        code
        name
      }
    }
  }
}
`;
export const getBuildingsUnitsQuery = `
query GetBuildingsUnits($countryCode: String!) {
  buildingsUnits(countryCode: $countryCode) {
    buildings {
      code
      name
    }
    org2units {
      code
      name
      isActive
      org3units {
        code
        name
      }
    }
  }
}
`;

export const setDefaultLocationQuery = `
mutation SetDefaultLocation($input: DefaultLocationInput!) {
  setDefaultLocation(input: $input)
}
`;

// export const getBuildingsUnitsForUnitAllocation = `
// query GetBuildingsUnitsForUnitAllocation($countryCode: String!) {
//   buildingsUnitsUA(countryCode: $countryCode) {
//     buildings {
//       code
//       name
//     }
//     org2units {
//       code
//       name
//       org3units {
//         code
//         name
//       }
//     }
//   }
// }
// `;
// export const getBuildingsAndUnitsQuery = `
// query GetBuildingsUnitsByCountry($countryCode: String!) {
//     buildingsUnitsByCountry(countryCode: $countryCode) {
//         buildings {
//             code
//             name
//         }
//         org2units {
//             code
//             name
//             org3units {
//                 code
//                 name
//             }
//         }
//     }
// }
// `;




export interface ISelectLocationConfig {
  mod: ESelectLocationMod;
  subMod: ESelectLocationSubMod;
  source?: ESelectLocationSource;
  showUploadMapMode?: boolean;
  uploadMapData?: ICreateMapPopupData;
}

export enum ESelectLocationSource {
  admin = 'admin',
  client = 'user'
}

export enum ESelectLocationMod {
  admin = 0,
  client = 1
}

export enum ESelectLocationSubMod {
  mapMaintenance = 0,
  unitsAllocation = 1,
  neighborhoodAllocation = 2,
  clientMapPage = 3,
  clientMobile = 4,
  qrGeneration = 5,
  qrDownload = 6
}
