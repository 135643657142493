import {Component} from '@angular/core';

@Component({
  selector: 'app-mobile-simple-page-layout',
  templateUrl: './mobile-simple-page-layout.component.html',
  styleUrls: ['./mobile-simple-page-layout.component.scss']
})
export class MobileSimplePageLayoutComponent {

}
