<div class="booking-seat-card" [ngClass]="{'clickable pointer': currentTab === tabs.upcoming}"
     *ngFor="let seat of seatsBooking"
     (click)="onClick(seat)">
  <div class="left-content">
    <div class="title">
      <span class="text-medium-normal seat-id">
        {{ seat.seatName }}
      </span>
      <img lib-amdocs-image-version *ngIf="!isFavorite(seat)" class="pointer" src="assets/icons/Star.svg" alt="unmarked favorite icon"
           (click)="updateFavorite(seat.seatCode, true, seat.location.wingGroup.code, seat.roomCode); $event.stopPropagation();">
      <img lib-amdocs-image-version *ngIf="isFavorite(seat)" class="pointer" src="assets/icons/Star-marked.svg" alt="marked favorite icon"
           (click)="updateFavorite(seat.seatCode,false, seat.location.wingGroup.code, seat.roomCode); $event.stopPropagation();">
    </div>
    <div *ngIf="isBookingByMe" class="d-flex booked-for-other">
      <img lib-amdocs-image-version src="/assets/icons/booked-for.svg">
      <span *ngIf="seat.bookedFor.code !== myUserCode" class="text-small-bold">{{seat.bookedFor.name}}</span>
    </div>
    <span class="text-small-bold">
      {{seat.bookingTime.start | amdocsDate: 'dddd DD-MM-yyyy, HH:mm'}}
      -
      {{seat.bookingTime.end | amdocsDate: ' HH:mm'}}
    <div *ngIf="isOverNight(seat)" class="d-inline-flex over-night-flag">+1</div>
    </span>
    <span class="text-small-normal">{{seat.location.country.name+ ', Building ' + seat.location.building.name + ', Floor' + seat.location.floor.name }}</span>
    <span class="text-tiny-normal text-color-secondary booked-by" *ngIf="isBookingForMe && seat.bookedBy.code !== myUserCode">
      {{ 'myBookings.bookedBy' | amdocsTranslate }}
      {{ seat.bookedBy.name }}
    </span>
  </div>

  <div *ngIf="currentTab === tabs.upcoming" class="circle-buttons-medium"
       (click)="$event.stopPropagation(); removeBookedSeat({'seatCode': seat.seatCode, 'bookingCode': seat.code, 'roomCode': seat.roomCode})">
    <img lib-amdocs-image-version src="assets/icons/Cancel-booking.svg" alt="cancel seat booking">
  </div>
</div>
