<div class="check-box-wrapper">
  <form *ngIf="checkBoxForm" [formGroup]="checkBoxForm" class="check-box-form">
    <ng-container *ngFor="let item of amenities">
      <div *ngIf="item" class="checkbox-item">
        <lib-amdocs-checkbox [elementId]="item.code"
                             (onChangedCallback)="showDefault = true"
                             [control]="checkBoxForm.controls[item.code]">{{item.name}}</lib-amdocs-checkbox>
      </div>
    </ng-container>
    <div class="footer">
      <div *ngIf="showDefault" class="set-as-default">
        <lib-amdocs-checkbox  [elementId]="'set-as-default'" [control]="setAsMyDefaultLocationControl" >
         {{footerText}}
        </lib-amdocs-checkbox>
      </div>
      <div class="buttons d-flex justify-content-between justify-content-lg-end">
        <button class="btn btn-medium secondary"
                (click)="cancel($event)">{{'common.cancel' | amdocsTranslate}}</button>
        <button class="btn btn-medium primary"
                (click)="submit($event)">{{'common.update' | amdocsTranslate}}</button>
      </div>
    </div>
  </form>

</div>
