import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {
  ICodeNameItem,
  ILocation,
  ISeatBooking,
  SelectedMapFiltersDetails
} from '../../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, finalize, map, mergeMap, share} from 'rxjs/operators';
import {CONSTANTS} from '../../../constants';
import {AmdocsDeviceService, AmdocsEventBusService, ApiError} from 'amdocs-core';
import {NavUserMapService} from '../../../core/nav-user-map.service';
import {SelectedMapFiltersDetailsService} from '../../../core/selected-map-filters-details.service';
import {IWhen} from '../../../models/user-map';
import {UserSettingsService} from '../../../core/user-settings.service';
import {ApiService} from '../../../core/api.service';

@Component({
  selector: 'app-find-a-friend-cards',
  templateUrl: './find-a-friend-cards.component.html',
  styleUrls: ['./find-a-friend-cards.component.scss']
})
export class FindAFriendCardsComponent implements OnInit {

  public loader = true;
  public friendBookings$: Observable<ISeatBooking[]>;
  public friendDefaultLocation: ILocation;
  public friendDefaultTime: {startTime: string; endTime: string};
  public userWhen: IWhen;
  public amenities: ICodeNameItem[];
  public wingGroupCode: string;
  @Output() genericApiErrorCB = new EventEmitter<ApiError>();


  @Input() friendName: string;

  // tslint:disable-next-line:variable-name
  private _friendId: string;
  @Input() set friendId(val: string) {
    if (val) {

      this._friendId = val;
      this.selectedMapFiltersDetailsService.getSelectedMapFiltersDetails().subscribe((filtersRes: SelectedMapFiltersDetails) => {
        this.userWhen = filtersRes.when;
        if(this.isMobile){
          sessionStorage.setItem('when', JSON.stringify(this.userWhen));
        }
        this.amenities = filtersRes.amenities;
        this.wingGroupCode = filtersRes.selectLocationClient.wingGroup.code;
        this.friendBookings$ = this.apiService.getFriendBookings(val, this.userWhen.date + ' ' + this.userWhen.from, (this.userWhen.endDate || this.userWhen.date) + ' ' + this.userWhen.to, this.wingGroupCode).pipe(
          map((res) => {
            return res
          }),
          catchError((error: ApiError) => {
            this.genericApiErrorCB.emit(error);
            return throwError(error);
          }),
          share(),
          finalize(() => {
            this.loader = false;
            this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, this.loader);
          })
        );
      });
    }
  }

  @Input() set friendBookingsObs(val: Observable<ISeatBooking[]>) {
    if (val) {
      this.friendBookings$ = val.pipe(
        share(),
        catchError((error: ApiError) => {
          this.genericApiErrorCB.emit(error);
          return throwError(error);
        }),
        finalize(() => {
          this.loader = false;
          this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, this.loader);
        })
      );
    }
  }

  @Output() clickToLocation: EventEmitter<null> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private userSettingsService: UserSettingsService,
    private eventBusService: AmdocsEventBusService,
    private navUserMapService: NavUserMapService,
    private selectedMapFiltersDetailsService: SelectedMapFiltersDetailsService,
    private deviceService: AmdocsDeviceService
  ) {
  }

  ngOnInit(): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, this.loader);
    this.getFriendDefaultValues();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  getFriendDefaultValues(): void {
    this.userSettingsService.getUserSettings(this._friendId, true).pipe(
      mergeMap(res => {
        if(res.defaultLocation?.wingGroup?.code){
          this.friendDefaultLocation = res.defaultLocation;
        }
        this.friendDefaultTime = res.defaultTimeRange;
        return of(res);
      }),
      share(),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      finalize(() => {
        this.loader = false;
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, this.loader);
      })
    ).subscribe();
  }

  goToFriendDefaultLocation(): void {
    const wingGroupCode = this.friendDefaultLocation.wingGroup.code;
    const queryParams: any = {
      date: this.userWhen.date,
      from: this.userWhen.from,
      to: this.userWhen.to,
      friendId: this._friendId,
      amenities: this.amenities.map(a => a.code).join(','),
      wingGroupCode,
    };
    if (this.userWhen.endDate){
      queryParams.endDate =  this.userWhen.endDate;
    }
    sessionStorage.setItem('selectLocationClient', JSON.stringify(this.friendDefaultLocation));
    this.router.navigate([CONSTANTS.ROUTES.USER_MAP], {queryParams});
    this.clickToLocation.emit();
  }

  goToMapWithFriendBooking(seat: ISeatBooking): void {
    const queryParams: any = {
      date: this.userWhen.date,
      from: this.userWhen.from,
      to: this.userWhen.to,
      friendId: this._friendId,
      amenities: this.amenities? this.amenities.map(a => a.code).join(',') : [],
      wingGroupCode: seat.location.wingGroup.code,
      seatId: seat.seatCode
    };
    if (this.userWhen.endDate){
      queryParams.endDate = this.userWhen.endDate;
    }
    sessionStorage.setItem('selectLocationClient', JSON.stringify(seat.location));
    this.router.navigate([CONSTANTS.ROUTES.USER_MAP], {queryParams});
    this.clickToLocation.emit();
  }
}
