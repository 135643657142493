import { Injectable } from '@angular/core';
import {IMapBooking, IMapInitData, IWhen} from '../../models/user-map';
import {MapService} from '../../core/map.service';
import {ApiService} from '../../core/api.service';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {IActiveMap, IExtendedSeat, ILocation} from '../../models';
import {catchError, mergeMap} from 'rxjs/operators';
import {FunctionsService} from '../../core/functions.service';
import {IUserFavorite} from '../../models/user-favorite-seat';
import {ApiError} from 'amdocs-core';

@Injectable()
export class UserMapService {

  public activeMapApiError: ApiError;
  constructor(private functionsService: FunctionsService, private apiService: ApiService, private mapService: MapService) { }

  getData(wingGroupCode: string,
          when: IWhen,
          loadMap: boolean = false,
          loadBookings: boolean = false,
          amenities: string[],
          loadFavs: boolean = false
  ): Observable<IMapInitData> {
    const calls: {[key in string]: any} = {};
    if (loadMap) {
      this.activeMapApiError = null;
      calls.activeMap = this.mapService.getActiveMap(wingGroupCode, true).pipe(
        catchError((error) => {
          this.activeMapApiError = error;
          console.log('this.activeMapApiError', this.activeMapApiError); //
          return throwError(error);
        })
      );
    }
    if (loadBookings) {
      calls.mapBookings = this.apiService.getMapBookings(wingGroupCode, when);
    }
    if (loadFavs) {
      calls.userFavs = this.apiService.getUserFavorites(wingGroupCode);
    }
    if (Object.keys(calls).length) {
      return forkJoin({...calls}).pipe(
        mergeMap((responses: {activeMap: IActiveMap, mapBookings: IMapBooking[], userFavs: IUserFavorite[]}) => {
          return of({
            activeMap: responses.activeMap,
            mapBookings: responses.mapBookings,
            mapSeats: this.getMapSeats(responses.activeMap, responses.mapBookings, amenities),
            userFavs: responses.userFavs ? responses.userFavs.map(fav => fav.seatCode) : null
          });
        })
      );
    }
    return of({});
  }

  public getWingGroupDetails(wingGroupCode): Observable<ILocation>{
    return this.apiService.getWingGroupDetails(wingGroupCode);
  }

  private getMapSeats(activeMap: IActiveMap, mapBookings: IMapBooking[], amenities: string[]): IExtendedSeat[] {
    if (!activeMap) {
      return null;
    }
    const seats: IExtendedSeat[] = this.functionsService.cloneObj(activeMap.seats);
    const rooms: IExtendedSeat[] = this.functionsService.cloneObj(activeMap.rooms);
    const fullList =  [...seats, ...rooms];

    return fullList;
    // FILTER MATCH AMENITIES
    return fullList.filter((s) => {
      return s.amenities.filter(seatAmenity => !!amenities.find(amnCode => amnCode === seatAmenity.code)).length > 0;
    });
  }
}

export enum ESubHeaderMode{
  initialize = 0,
  where = 1,
  whatINeed = 2,
  nextTo = 3,
  when = 4
}
