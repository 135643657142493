import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IEmployee} from '../../../../models/search-employees';

@Component({
  selector: 'app-selected-employees',
  templateUrl: './selected-employees.component.html',
  styleUrls: ['./selected-employees.component.scss']
})
export class SelectedEmployeesComponent implements OnInit {
  @Input() selectedEmployees: IEmployee[];
  @Input() reservedListCounter: number;
  @Input() isUnitRestricted = false;
  @Output() removeEmployeeCallback: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeAllEmployeesCallback: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public removeEmployee(id: string): void {
    this.removeEmployeeCallback.emit(id);
  }

  public removeAllEmployees(event): void {
    this.removeAllEmployeesCallback.emit(event);
  }
}
