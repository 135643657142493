import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IExtendedSeat, ILocation} from '../../../models';
import {IWhen} from '../../../models/user-map';
import {IUserFavoriteSeat} from '../../../models/user-favorite-seat';

@Component({
  selector: 'app-book-a-seat-confirmation',
  templateUrl: './book-a-seat-confirmation.component.html',
  styleUrls: ['./book-a-seat-confirmation.component.scss']
})
export class BookASeatConfirmationComponent implements OnInit {

  @Input() selectedSeat: IExtendedSeat | IUserFavoriteSeat;
  @Input() bookingTime: IWhen;
  @Input() userLocation: ILocation;
  @Output() closePopupEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() viewDetailsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  get seatName(): string {
    return (this.selectedSeat as IUserFavoriteSeat).seatName || (this.selectedSeat as IExtendedSeat).name;
  }

  closePopup(): void {
    this.closePopupEvent.emit(true);
  }

  viewDetails(): void {
    this.viewDetailsEvent.emit(true);
  }

}
