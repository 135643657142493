import {ApiService} from './api.service';
import {EMPTY, forkJoin, Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {UserSettingsService} from './user-settings.service';
import {SiteParametersService} from '../feature/admin/site-parameters/site-parameters.service';
import {FoundationService} from './foundation.service';
import {AmdocsDeviceService, AmdocsUserService, ApiError} from 'amdocs-core';
import {ICodeNameItem, ILocation, ISiteParameters, SelectedMapFiltersDetails} from '../models';
import * as moment from 'moment';
import {IWhen} from '../models/user-map';

enum DefaultDate {
  Today = 'Today',
  NextWorkingDay = 'NextWorkingDay'
}

enum DefaultTime {
  StartTime = '09:00',
  EndTime = '18:00'
}

@Injectable({
  providedIn: 'root'
})
export class SelectedMapFiltersDetailsService {

  public regionCode: string;
  private siteParameter: ISiteParameters;
  private selectedSeatDetails = new SelectedMapFiltersDetails();

  constructor(
    private apiService: ApiService,
    private userSettingsService: UserSettingsService,
    private siteParametersService: SiteParametersService,
    private foundationService: FoundationService,
    private userProfileService: AmdocsUserService,
    private deviceService: AmdocsDeviceService
  ) {
    this.regionCode = this.foundationService.region;
  }

  private getRegion(): void{
    if(this.selectedSeatDetails.selectLocationClient?.country?.region){
      this.regionCode = this.selectedSeatDetails.selectLocationClient?.country?.region;
    }
  }

  private getDefaultParametersByRegion(): Observable<ISiteParameters> {
    return this.siteParametersService.loadSystemParameters().pipe(
      map(systemParameters => {
        let regionCode;
       this.getRegion();
        return systemParameters.systemParameters.find(systemParameter => systemParameter.regionCode === regionCode);
      }),
      catchError(error => {
        console.log('getDefaultParametersByRegion failed');
        return throwError(error);
      })
    );
  }

  private getDefaultParameters(): Observable<ISiteParameters> {
    if (this.siteParameter) {
      return of(this.siteParameter);
    }
    return this.getDefaultParametersByRegion();
  }

  private setSession<K extends keyof SelectedMapFiltersDetails>(keyName: K, obj: SelectedMapFiltersDetails[K]): void {
    const stringify = JSON.stringify(obj);
    sessionStorage.setItem(keyName, stringify);
  }

  private getFromSession<K extends keyof SelectedMapFiltersDetails>(keyName: K): SelectedMapFiltersDetails[K] {
    const asString = sessionStorage.getItem(keyName);
    return JSON.parse(asString);
  }

  private getDate(day: string): string {
    if (!day) {
      day = this.isMobile ? DefaultDate.Today : DefaultDate.NextWorkingDay;
    }
    let date: string;
    if (day === DefaultDate.Today) {
      date = moment().format('YYYY-MM-DD');
    } else if (day === DefaultDate.NextWorkingDay) {
      date = moment().add(1, 'days').format('YYYY-MM-DD');
    }
    return date;
  }

  private getUserFromServer(): Observable<ICodeNameItem> {
    const code = this.userProfileService.user.userProfile.uuid;
    const name = this.userProfileService.user.userProfile.name;
    const user = {code, name};
    this.selectedSeatDetails.user = user;
    // this.setUserCache(user);
    return of(user);
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  private getDateFromServer(): Observable<IWhen> {
    const when = {} as IWhen;
    return this.userSettingsService.getUserSettings().pipe(
      tap(userSettings => {
        when.from = userSettings?.defaultTimeRange?.startTime;
        when.to = userSettings?.defaultTimeRange?.endTime;
      }),
      mergeMap(_ => this.getDefaultParameters()),
      map(res => {
        this.siteParameter = res;
        when.date = this.isMobile ? this.getDate(res?.defaultSchedulingBookingDayForMobile) :
          this.getDate(res?.defaultSchedulingBookingDayForWeb);
        when.from ??= res?.defaultStartTime || DefaultTime.StartTime;
        when.to ??= res?.defaultEndTime || DefaultTime.EndTime;
        this.selectedSeatDetails.when = when;
        // this.setWhenCache(when);
        return when;
      }),
      catchError((error: ApiError) => {
        return throwError(error);
      }),
    );
  }

  private getLocationFromServer(): Observable<ILocation> {
    return this.userSettingsService.getUserSettings().pipe(map(userSettings => {
      let where: ILocation = {} as ILocation;
      if (userSettings) {
        // where.unit = userSettings.defaultLocation?.unit?.code ? userSettings.defaultLocation?.unit : null;
        // where.country = userSettings.defaultLocation.country?.code ? userSettings.defaultLocation.country : null;
        // where.building = userSettings.defaultLocation.building?.code ? userSettings.defaultLocation.building : null;
        // where.floor = userSettings.defaultLocation.floor?.code ? userSettings.defaultLocation.floor : null;
        // where.neighborhood = userSettings.defaultLocation.neighborhood?.code ? userSettings.defaultLocation.neighborhood : null;
        // where.wingGroup = userSettings.defaultLocation.wingGroup?.code ? userSettings.defaultLocation.wingGroup : null;
        if (userSettings.defaultLocation?.wingGroup?.code) {
          where = userSettings.defaultLocation;
          this.setWhereCache(where);
        }
      }
      this.selectedSeatDetails.selectLocationClient = where;
      return where;
    }));
  }

  private getAmenitiesFromServer(): Observable<ICodeNameItem[]> {
    return this.userSettingsService.getUserSettings(null).pipe(
      tap(x => console.log(x)),
      mergeMap(userSettings => {
        this.getRegion();
        const amenitiesByRegion = userSettings.defaultAmenities?.find(a => a.regionCode === this.regionCode)?.amenities;
        this.selectedSeatDetails.amenities = amenitiesByRegion;
        return of(amenitiesByRegion);
      })
    )
  }

  setUserCache(user: ICodeNameItem): void {
    if (user) {
      this.selectedSeatDetails.user = user;
      this.setSession('user', user);
    }
  }

  setWhenCache(when: IWhen): void {
    if (when) {
      this.selectedSeatDetails.when = when;
      this.setSession('when', when);
    }
  }

  setWhereCache(where: ILocation): void {
    if (where) {
      this.selectedSeatDetails.selectLocationClient = where;
      this.setSession('selectLocationClient', where);
    }
  }

  setAmenitiesCache(amenities: ICodeNameItem[]): void {
    if (amenities) {
      this.selectedSeatDetails.amenities = amenities;
      this.setSession('amenities', amenities);
    }
  }

  getAmenities(): Observable<ICodeNameItem[]> {
    const amenities = this.getFromSession('amenities');
    if (amenities) {
      this.selectedSeatDetails.amenities = amenities;
      return of(amenities);
    } else {
      return this.getAmenitiesFromServer();
    }
  }

  getWhere(): Observable<ILocation> {
    const where = this.getFromSession('selectLocationClient');
    if (where) {
      this.selectedSeatDetails.selectLocationClient = where;
      return of(where);
    } else {
      return this.getLocationFromServer();
    }
  }

  getWhen(): Observable<IWhen> {
    const when = this.getFromSession('when');
    if (when) {
      this.selectedSeatDetails.when = when;
      return of(when);
    } else {
      return this.getDateFromServer();
    }
  }

  getUser(): Observable<ICodeNameItem> {
    const user = this.getFromSession('user');
    if (user) {
      this.selectedSeatDetails.user = user;
      return of(user);
    } else {
      return this.getUserFromServer();
    }
  }

  getSelectedMapFiltersDetails(): Observable<SelectedMapFiltersDetails> {
    return forkJoin([this.getUser(), this.getWhen(), this.getWhere(), this.getAmenities()]).pipe(
      map(_ => this.selectedSeatDetails)
    );
  }
}
