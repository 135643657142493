import { FormControl } from '@angular/forms';
import {ICodeNameItem} from './map';

export interface ISiteParametersRegions {
  regions: ICodeNameItem[];
  systemParameters: ISiteParameters[];
}

export interface ISiteParameters {
  regionCode: string;
  advBookingPeriod: number;
  regionName?: string;
  bookingPeriodInAdvance?: number;
  bookingPeriodInAdvanceCtrl: FormControl;
  defaultStartTime: string;
  defaultEndTime: string;
  timeslotBetweenBookings: number;
  timeslotBetweenBookingsCtrl: FormControl;
  defaultSchedulingBookingDayForMobile: string;
  defaultSchedulingBookingDayForWeb: string;
  ignoreLEExpirationInterval?: number;
  ignoreLEExpirationIntervalCtrl?: FormControl;
}
// export const loadSystemParametersQuery = `
//   query LoadSystemParameters {
//     systemParameters {
//       regionCode
//       advBookingPeriod
//       defaultStartTime
//       defaultEndTime
//       timeslotBetweenBookings
//       defaultSchedulingBookingDayForMobile
//       defaultSchedulingBookingDayForWeb
//     }
// }`;


export const loadSystemParametersQuery = `
query LoadSystemParameters {
  systemParameters {
    regionCode
    advBookingPeriod
    defaultStartTime
    defaultEndTime
    timeslotBetweenBookings
    defaultSchedulingBookingDayForMobile
    defaultSchedulingBookingDayForWeb
    weekends
    ignoreLEExpirationInterval
  }
}
`;

export const loadSystemParametersQueryWithRegions = `
query LoadSystemParameters {
  regions {
    code
    name
  }
  systemParameters {
    regionCode
    advBookingPeriod
    defaultStartTime
    defaultEndTime
    timeslotBetweenBookings
    defaultSchedulingBookingDayForMobile
    defaultSchedulingBookingDayForWeb
    weekends
    ignoreLEExpirationInterval
  }
}
`;

export const saveSystemParametersQuery = `
  mutation SaveSystemParameters($input: [SystemParametersInput!]!) {
  systemParameters(input: $input)
}`;

