import {IActiveMap, ICodeNameItem, IExtendedSeat, IRoom} from './map';
import {ILocation} from "./location";

export interface IWhen {
  date: string;
  endDate?: string;
  from: string;
  to: string;
}

export enum EWhenDays {
  sunday = 0,
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6
}

export enum ESeatStatus {
  Booked = 0,
  UnAvailable = 1,
  Assigned = 2,
  Available = 3
}

export enum ESeatButton {
  close = 1,
  bookASeat = 2,
  cancelBooking = 3
}

export interface IDoubleBooking extends IMapBooking{
  location?: ILocation;
}

export interface IMapBooking {
  bookingTime: {
    end: string;
    start: string;
  };
  bookedBy: ICodeNameItem;
  bookedFor: ICodeNameItem;
  seatCode: string;
  roomCode?: string;
  code?: string;
  wingGroupCode?: string;
}

export interface IMapInitData {
  activeMap?: IActiveMap;
  mapBookings?: IMapBooking[];
  mapSeats?: IExtendedSeat[] | IRoom[];
  userFavs?: string[];
}


export const getMapBookingsQuery = `
query MapBookings($wingGroupCode: String!, $ranges: [TimeRangeInput!]! ) {
  mapBookings(wingGroupCode: $wingGroupCode, ranges: $ranges) {
    code
    bookingTime {
      start
      end
    }
    bookedBy {
      code
      name
    }
    bookedFor {
      code
      name
      upn
    }
    seatCode
    roomCode
  }
}
`;
