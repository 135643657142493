<div class="employee-search-wrapper" [ngClass]="{'scroll': isScroll}">
  <div class="employee-results-wrapper" [ngStyle]="{height: resultsWrapperHeight + 'px'}" [hidden]="!showResults">
    <div class="employee-results" *ngIf="employees && employees.length" #searchResults>
      <div class="employee-row-wrapper pointer" *ngFor="let employee of employees">
        <div class="employee-row" *ngIf="employee.displayName || employee.jobTitle" (click)="goToEmployeeDetails(employee)">
          <div class="employee-icon">
            <app-avatar [userName]="employee.displayName" [uuid]="employee.id" [size]="isScroll ? avatarImgSize.xs : avatarImgSize.small"></app-avatar>
          </div>
          <div class="employee-data">
            <div class="employee-name" [ngClass]="isScroll? 'text-small-normal' : 'text-medium-normal'" [innerHTML]="employee.displayName | amdocsStrongSpan:employeeSearchControl.value"></div>
            <div *ngIf="showJobInfo" class="employee-title text-tiny-normal text-color-secondary">{{employee.jobTitle}}</div>
          </div>
        </div>
      </div>
      <div class="end-of-results" *ngIf="showEndOfResults">{{'employeeSearch.endOfResults' | amdocsTranslate}}</div>
    </div>

    <div class="employee-no-results" *ngIf="employees && employees.length == 0">
      <img lib-amdocs-image-version src="/assets/images/no-search-results.png" class="no-results-img" alt="there is no search results image" />
      <div class="no-results-text text-medium-normal">{{'employeeSearch.noResults' | amdocsTranslate}}</div>
    </div>
    <div class="load-more-placeholder" #loadMore></div>
  </div>
</div>

<lib-amdocs-popup #messagePopup *ngIf="searchError" (closeCallback)="searchError = null">
  <lib-amdocs-popup-message
    (actionClicked)="messagePopup.close()"
    [title]="'general.defaultErrorTitle' | amdocsTranslate"
    [message]="searchError.message"
  ></lib-amdocs-popup-message>
</lib-amdocs-popup>
