import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ISeatBooking} from '../../../../models';

@Component({
  selector: 'app-faf-seat-card',
  templateUrl: './faf-seat-card.component.html',
  styleUrls: ['./faf-seat-card.component.scss']
})
export class FafSeatCardComponent {
  @Input() bookedSeat: ISeatBooking;
  @Output() showOnMapLocation = new EventEmitter<ISeatBooking>();

  showOnMap(seat: ISeatBooking): void {
    this.showOnMapLocation.emit(seat);
  }
}
