<div id="main-app-wrapper">
  <lib-amdocs-mimic *ngIf="allowMimic"></lib-amdocs-mimic>
  <ng-container *ngIf="appFinishedLoaded && !appError">
    <app-app-mobile *ngIf="isMobile; else desktopMainComponent" ></app-app-mobile>
    <ng-template #desktopMainComponent>
      <app-app-desktop></app-app-desktop>
    </ng-template>
  </ng-container>

  <lib-amdocs-app-error *ngIf="appError"></lib-amdocs-app-error>
  <lib-amdocs-pwa-ios-notification></lib-amdocs-pwa-ios-notification>

  <lib-amdocs-app-loader *ngIf="showLoaderCount"></lib-amdocs-app-loader>
  <lib-amdocs-user-notify-alert #userNotifyAlert></lib-amdocs-user-notify-alert>

  <lib-amdocs-version-alert></lib-amdocs-version-alert>
</div>
<!--Booking is canceled msg-->
<lib-amdocs-toastr *ngIf="showToaster" (toasterClose)="showToaster = false" [title]="toasterTitle" [msg]="toasterMessage" [toastType]="toasterType"></lib-amdocs-toastr>


