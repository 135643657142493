import {Injectable} from '@angular/core';
import {
  AmdocsAppsyncClientService,
  AmdocsTimeService,
  AmdocsTranslateService,
  AmdocsUserService,
  ApiError
} from 'amdocs-core';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {
  getAmenitiesAsAdminQuery,
  getBuildingsByUnitQuery,
  getBuildingsUnitsForNeighborhoodAllocation,
  getBuildingsUnitsQuery,
  getCountriesQuery,
  getFloorsByBuildingQuery,
  getFloorsByUnitQuery,
  getFriendBooking,
  getMapsListQuery,
  getUnitsByWingGroupQuery,
  getUnitsFoundationQuery,
  getUserSettingsQuery,
  getWingsWingGroupByUnitQuery,
  getWingsWingsWingGroupByFloorQuery,
  IBuilding,
  IBuildingAndUnits,
  ICodeNameItem,
  ICountry,
  IFloor,
  IFloorsAndUnits,
  IMyBookings,
  IMap,
  IUnit,
  IUnitsWingsWingGroups,
  IUpdateSeatAttributesResponse,
  IUserSettings,
  IUserProfile,
  IUserSettingsResponse,
  setDefaultAmenitiesQuery,
  IWingsAndWingGroups,
  loadSystemParametersQuery,
  setDefaultTimeRangeQuery,
  UpdateSeatAttributesQuery,
  getMySeatsBookingQuery,
  IAdminAmenityRegion,
  ISiteParametersRegions,
  ISeatBooking,
  IUpdateSeatAttributes,
  IMapListFull,
  CreateBookingsQuery,
  ICancelBookingRes,
  ICreateBooking,
  IUserAmenity,
  WingGroupDetailsQuery,
  ILocation,
  loadSystemParametersQueryWithRegions,
  getUserSettingsUserCodeQuery,
  EGenericErrors, ICreateBookingRes,
  IOtherUserProfile, getOtherUserProfileQuery, IUpdateAmenitySeatAttributes, UpdateAmenitySeatAttributesQuery
} from '../models';
import {
  getUserFavorites, getUserFavoritesSeatsQuery, IUserFavorite,
  IUserFavoriteSeat,
  IUserUpdateFavoriteSeat,
  setUserFavorite
} from '../models/user-favorite-seat';
import {
  cancelBookingQuery,
  IUpdateSeatBooking,
} from '../models';
import {getMapBookingsQuery, IMapBooking, IWhen} from '../models/user-map';
import {getAmenitiesAsUser} from '../models';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  }
)
export class ApiService {
  constructor(private userService: AmdocsUserService,
              private appSyncClient: AmdocsAppsyncClientService,
              private translateService: AmdocsTranslateService,
              private timeService: AmdocsTimeService) {
  }

  public getMapList(wingGroup): Observable<IMap[]> {
    const params = {wingGroupCode: wingGroup};
    return this.appSyncClient.call(getMapsListQuery, 'MapsList', params).pipe(
      mergeMap((response: { mapsList: { maps: IMap[] } }): Observable<IMap[]> => {
        return of(response.mapsList.maps);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getMapListFull(wingGroup): Observable<IMapListFull> {
    const params = {wingGroupCode: wingGroup};
    return this.appSyncClient.call(getMapsListQuery, 'MapsList', params).pipe(
      mergeMap((response: { mapsList: IMapListFull }): Observable<IMapListFull> => {
        return of(response.mapsList);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getUnitsFoundation(): Observable<IUnit[]> {
    return this.appSyncClient.call(getUnitsFoundationQuery, 'UnitsFoundation').pipe(
      mergeMap((response: { units: IUnit[] }) => {
        return of(response.units);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getBuildingsUnitsByCountry(country, nbPage: boolean = false): Observable<IBuildingAndUnits> {
    // const query = nbPage ? getBuildingsUnitsForNeighborhoodAllocation : getBuildingsUnitsQuery;
    const query = getBuildingsUnitsQuery;
    // const actionName = nbPage ? 'GetBuildingsUnitsForNeighborhoodAllocation' : 'GetBuildingsUnits';
    const actionName = 'GetBuildingsUnits';
    const params = {countryCode: country};
    return this.appSyncClient.call(query, actionName, params).pipe(
      mergeMap((response: { buildingsUnitsNA: IBuildingAndUnits, buildingsUnits: IBuildingAndUnits }) => {
        // if (nbPage) {
        //   return of(response.buildingsUnitsNA);
        // } else {
        return of(response.buildingsUnits);
        // }
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getUnitsByWingGroup(countryCode, wingGroupCode): Observable<IUnit[]> {
    const params = {countryCode, wingGroupCode};
    return this.appSyncClient.call(getUnitsByWingGroupQuery, 'GetUnitsByWingGroup', params).pipe(
      mergeMap((response: { unitsByWingGroup: IUnit[] }): Observable<IUnit[]> => {
        return of(response.unitsByWingGroup);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }


  public getUnitsFloorByBuilding(country, building): Observable<IFloorsAndUnits> {
    const params = {countryCode: country, buildingCode: building};
    return this.appSyncClient.call(getFloorsByBuildingQuery, 'FloorsByBuilding', params).pipe(
      mergeMap((response: { floorsByBuilding: IFloorsAndUnits }): Observable<IFloorsAndUnits> => {
        return of(response.floorsByBuilding);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getWingsWingsWingGroupsByFloor(country, building, floor): Observable<IUnitsWingsWingGroups> {
    const params = {countryCode: country, buildingCode: building, floorCode: floor};
    return this.appSyncClient.call(getWingsWingsWingGroupByFloorQuery, 'GetWingsWingGroupByFloor', params).pipe(
      mergeMap((response: { wingsWingGroupByFloor: IUnitsWingsWingGroups }): Observable<IUnitsWingsWingGroups> => {
        return of(response.wingsWingGroupByFloor);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }


  public getBuildingsByUnit(country: string, unit: string): Observable<IBuilding[]> {
    const params = {countryCode: country, unitCode: unit};
    return this.appSyncClient.call(getBuildingsByUnitQuery, 'BuildingsByUnit', params).pipe(
      mergeMap((response: { buildingsByUnit: IBuilding[] }): Observable<IBuilding[]> => {
        return of(response.buildingsByUnit);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getFloorsByUnit(countryCode, buildingCode, unitCode): Observable<IFloor[]> {
    const params = {countryCode, buildingCode, unitCode};
    return this.appSyncClient.call(getFloorsByUnitQuery, 'FloorsByUnit', params).pipe(
      mergeMap((response: { floorsByUnit: IFloor[] }): Observable<IFloor[]> => {
        return of(response.floorsByUnit);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getWingsWingGroupsByUnit(countryCode, buildingCode, floorCode, unitCode): Observable<IWingsAndWingGroups> {
    const params = {countryCode, buildingCode, floorCode, unitCode};
    return this.appSyncClient.call(getWingsWingGroupByUnitQuery, 'WingsWingGroupsByUnit', params).pipe(
      mergeMap((response: { wingsWingGroupByUnit: IWingsAndWingGroups }): Observable<IWingsAndWingGroups> => {
        return of(response.wingsWingGroupByUnit);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getAmenitiesAsAdmin(): Observable<IAdminAmenityRegion> {
    const params = {};
    return this.appSyncClient.call(getAmenitiesAsAdminQuery, 'GetAmenitiesAsAdmin', params).pipe(
      mergeMap((response: IAdminAmenityRegion): Observable<IAdminAmenityRegion> => {
        return of(response);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getMySeatsBooking(): Observable<IMyBookings> {
    return this.appSyncClient.call(getMySeatsBookingQuery, 'GetMyBookings').pipe(
      mergeMap((response: { myBookings: IMyBookings }): Observable<IMyBookings> => {
        return of(response.myBookings);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  // public updateSeatBooking(input): Observable<IUpdateSeatBooking[]> {
  //   const params = {input};
  //   return this.appSyncClient.call(updateSeatBookingsQuery, 'UpdateSeatBookings', params).pipe(
  //     mergeMap((response: { updateSeatBookings: IUpdateSeatBooking[] }): Observable<IUpdateSeatBooking[]> => {
  //       return of(response.updateSeatBookings);
  //     })
  //   );
  // }


  public cancelBooking(code,sourceSystem): Observable<ICancelBookingRes> {
    const payload = {
      code: code,
      sourceSystem: sourceSystem
    };
    return this.appSyncClient.call(cancelBookingQuery, 'CancelBooking', payload).pipe(
      mergeMap((response): Observable<ICancelBookingRes> => {
        return of(response);
      })
    );
  }

  public getUserFavorites(wingGroupCode: string): Observable<IUserFavorite[]> {
    return this.appSyncClient.call(getUserFavorites, 'GetUserFavorites', {wingGroupCode}).pipe(
      mergeMap((response: IUserFavoriteSeat): Observable<IUserFavorite[]> => {
        return of(response.userFavorites);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getUserFavoritesSeats(wingGroupCode: string, start: string, end: string, otherUserCode: string = null): Observable<IUserFavoriteSeat[]> {

    const input: { wingGroupCode: string; start: string; end: string; userCode?: string } = {
      wingGroupCode,
      start,
      end,
    };

    if (otherUserCode) {
      input.userCode = otherUserCode;
    }
    const params = {
      input: input
    };
    return this.appSyncClient.call(getUserFavoritesSeatsQuery, 'GetUserFavoriteSeats', params).pipe(
      mergeMap((response: { userFavoriteSeats: IUserFavoriteSeat[] }): Observable<IUserFavoriteSeat[]> => {
        return of(response.userFavoriteSeats);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public setUserFavorite(input: IUserUpdateFavoriteSeat): Observable<boolean> {
    return this.appSyncClient.call(setUserFavorite, 'SetUserFavorites', input).pipe(
      mergeMap((response: { setUserFavorites: boolean }): Observable<boolean> => {
        return of(response.setUserFavorites);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public updateSeatAttributes(input): Observable<IUpdateSeatAttributes> {
    const params = {...input};
    params.appid = environment.appId;
    return this.appSyncClient.call(UpdateSeatAttributesQuery, 'UpdateSeatAttributes', params).pipe(
      mergeMap((response: { updateSeatAttributes: IUpdateSeatAttributes }): Observable<IUpdateSeatAttributes> => {
        return of(response.updateSeatAttributes);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public updateAmenitySeatAttributes(input): Observable<IUpdateAmenitySeatAttributes> {
    const params = {...input};
    return this.appSyncClient.call(UpdateAmenitySeatAttributesQuery, 'UpdateAmenitySeatAttributes', params).pipe(
      mergeMap((response: { updateAmenitySeatAttribute: IUpdateAmenitySeatAttributes }): Observable<IUpdateAmenitySeatAttributes> => {
        return of(response.updateAmenitySeatAttribute);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public createBookings(input): Observable<ICreateBookingRes> {
    const params = {input};
    return this.appSyncClient.call(CreateBookingsQuery, 'CreateBookings', params).pipe(
      mergeMap((response: { createBookings: ICreateBookingRes }): Observable<ICreateBookingRes> => {
        return of(response.createBookings);
      })
    );
  }

  public getUserSettings(userCode: string = null): Observable<IUserSettings> {
    const params = userCode ? {userCode} : null;
    if (userCode) {
      return this.appSyncClient.call(getUserSettingsUserCodeQuery, 'GetUserSettings', params).pipe(
        mergeMap((response: IUserSettingsResponse): Observable<IUserSettings> => {
          return of(response.userSettings);
        })
      );
    } else {
      return this.appSyncClient.call(getUserSettingsQuery, 'GetUserSettings', params).pipe(
        mergeMap((response: IUserSettingsResponse): Observable<IUserSettings> => {
          return of(response.userSettings);
        }),
        catchError((error: ApiError) => {
          error = this.handleGenericError(error);
          return throwError(error);
        })
      );
    }

  }

  public getMapBookings(wingGroupCode: string, when: IWhen): Observable<IMapBooking[]> {
    const params = {
      wingGroupCode,
      ranges: [{
        start: `${when.date} ${when.from}`,
        end: `${when.endDate || when.date} ${when.to}`
      }],
    };
    return this.appSyncClient.call(getMapBookingsQuery, 'MapBookings', params).pipe(
      mergeMap((response: { mapBookings: IMapBooking[] }): Observable<IMapBooking[]> => {
        return of(response.mapBookings);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public init(): Observable<{ countries: ICountry[], userProfile: IUserProfile, userSettings: IUserSettings }> {
    const calls = [
      this.getAppInit(),
      this.getUserSettings()
    ];
    return forkJoin(calls).pipe(
      mergeMap((responses) => {
        return of({
          countries: (responses[0] as { countries: ICountry[] }).countries as ICountry[],
          userProfile: (responses[0] as any).userProfile,
          userSettings: responses[1] as IUserSettings,
        });
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getAppInit(): Observable<{ countries: ICountry[], userProfile: IUserProfile }> {
    return this.appSyncClient.call(initQuery, 'KindOfApplicationInit').pipe(
      mergeMap((response: { countries: ICountry[], userProfile: IUserProfile }): Observable<{ countries: ICountry[], userProfile: IUserProfile }> => {
        response.countries = response.countries.map((tmpC: any): ICountry => {
          return {
            code: tmpC.countryCode,
            name: tmpC.countryName,
            region: tmpC.regionCode
          };
        });
        this.userService.user = {
          userProfile: {
            ...response?.userProfile,
            country: response?.userProfile?.country || 'USA',
            name: response.userProfile.name,
            legalEntity: response.userProfile.legalEntity
          }
        };
        return of(response);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getAmenitiesAsUser(regionCode: string): Observable<IUserAmenity> {
    return this.appSyncClient.call(getAmenitiesAsUser, 'GetAmenitiesAsUser', {regionCode}).pipe(
      mergeMap((response: { userAmenities: IUserAmenity }): Observable<IUserAmenity> => {
        return of(response.userAmenities);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public setDefaultAmenities(regionCode, amenitiesCodes: string[]): Observable<boolean> {
    const params = {
      input: {regionCode, amenityCodes: amenitiesCodes}
    };
    return this.appSyncClient.call(setDefaultAmenitiesQuery, 'SetDefaultAmenities', params).pipe(
      mergeMap((res: boolean) => {
        return of(res);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      }));
  }

  public getFriendBookings(userCode: string, start: string, end: string, wingGroupCode: string): Observable<ISeatBooking[]> {
    return this.appSyncClient.call(getFriendBooking, 'GetEmployeeBookings', {userCode, start, end, wingGroupCode}).pipe(
      mergeMap((res: { employeeBookings: ISeatBooking[] }) => {
        return of(res.employeeBookings);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      }));
  }

  // public getActiveFriendBookings(userCode: string, start: string, end: string, wingGroupCode: string): Observable<ISeatBooking[]> {
  //   return this.appSyncClient.call(getFriendBooking, 'GetEmployeeBookings', {userCode, start, end, wingGroupCode}).pipe(
  //     mergeMap((res: { employeeBookings: ISeatBooking[] }) => {
  //       return of(res.employeeBookings.filter(b => !b.isCancelled));
  //     }),
  //     catchError((error: ApiError) => {
  //       error = this.handleGenericError(error);
  //       return throwError(error);
  //     }));
  // }

  public setDefaultTimeRangeQuery(startTime: string, endTime: string): Observable<boolean> {
    const params = {
      input: {startTime, endTime}
    };
    return this.appSyncClient.call(setDefaultTimeRangeQuery, 'SetDefaultTimeRange', params).pipe(mergeMap((res: boolean) => {
      return of(res);
    }));
  }

  public loadSystemParameters(withRegions: boolean = false): Observable<ISiteParametersRegions> {
    const query = withRegions ? loadSystemParametersQueryWithRegions : loadSystemParametersQuery;
    return this.appSyncClient.call(query, 'LoadSystemParameters').pipe(
      mergeMap((res: ISiteParametersRegions) => {
        return of(res);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getWingGroupDetails(wingGroupCode): Observable<ILocation> {
    const params = {wingGroupCode};
    return this.appSyncClient.call(WingGroupDetailsQuery, 'WingGroupDetails', params).pipe(
      mergeMap((res: { wingGroupDetails: ILocation }) => {
        return of(res.wingGroupDetails);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public getCountries(): Observable<ICountry[]> {
    return this.appSyncClient.call(GetCountriesQuery, 'GetCountries', null).pipe(
      mergeMap((res: { countries: ICountry[] }) => {
        return of(res.countries);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }

  public handleGenericError(error: ApiError): ApiError {
    switch (error.errorType) {
      case EGenericErrors.GENERIC: {
        error.message = this.translateService.getText('errors.generic');
        break;
      }
      case EGenericErrors.LOCATION_INVALID: {
        error.message = this.translateService.getText('errors.general.locationInvalid');
        break;
      }
      case EGenericErrors.LOCATION_NO_TZ: {
        error.message = this.translateService.getText('errors.general.locationNoTimezone');
        break;
      }
      case EGenericErrors.SEAT_INVALID: {
        error.message = this.translateService.getText('errors.general.seatInvalid');
        break;
      }
      case EGenericErrors.SYSPARAMS_MISSING: {
        error.message = this.translateService.getText('errors.general.systemParamsMissing');
        break;
      }
      case EGenericErrors.USER_NOT_FOUND: {
        error.message = this.translateService.getText('errors.general.userNotFound');
        break;
      }
      case EGenericErrors.USER_NO_PROFILE: {
        error.message = this.translateService.getText('errors.general.userNoProfile');
        break;
      }
      case EGenericErrors.DATETIME_INVALID: {
        error.message = this.translateService.getText('errors.general.dateTimeInvalid');
        break;
      }
      case EGenericErrors.DATETIME_FORMAT: {
        error.message = this.translateService.getText('errors.general.dateTimeFormat');
        break;
      }
      case EGenericErrors.DATETIME_MINUTES: {
        error.message = this.translateService.getText('errors.general.dateTimeMinutes');
        break;
      }
      case EGenericErrors.DATETIME_SECONDS: {
        error.message = this.translateService.getText('errors.general.dateTimeSeconds');
        break;
      }
      case EGenericErrors.DATETIME_MILLISECONDS: {
        error.message = this.translateService.getText('errors.general.dateTimeMilliSeconds');
        break;
      }
      default: {
        error.message = error.message;
        break;
      }
    }
    return error;
  }

  public getOtherUserProfile(employeeId): Observable<IOtherUserProfile> {
    const params = {employeeId};

    return this.appSyncClient.call(getOtherUserProfileQuery, 'OtherUserProfile', params).pipe(
      mergeMap((res: { otherUserProfile: IOtherUserProfile }) => {
        return of(res.otherUserProfile);
      }),
      catchError((error: ApiError) => {
        error = this.handleGenericError(error);
        return throwError(error);
      })
    );
  }
}

export const GetCountriesQuery = `
query GetCountries {
  countries {
    countryCode
    countryName
    regionCode
  }
}`;

export const initQuery = `
query KindOfApplicationInit {
  userProfile {
    name
    code
    country
    legalEntity
    unit
    org2unit
  }
  ${getCountriesQuery}
}
`;
