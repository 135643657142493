<lib-amdocs-popup *ngIf="!isMobile" class="generic-error-popup"
                  #genericErrorPopup
                  (closeCallback)="closePopup()"
                  [showCloseButton]="true"
               >
  <app-desktop-popup-wrapper>
    <div popupHeader>
      <div class="d-flex">
        <div class="icon-wrapper">
          <img src="/assets/icons/error.svg"/>
        </div>
        <div class="label-h1">{{'common.wentWrong' | amdocsTranslate}}</div>
      </div>
    </div>
    <div popupContent>
      <div class="error-msg">
        <div class="text-small-normal">{{errorMsg}}</div>
      </div>
    </div>
    <div popupFooter class="icons-wrapper d-flex align-items-center justify-content-end">
      <button class="btn btn-medium primary"
              (click)="genericErrorPopup.close(); closePopup()">{{'common.close' | amdocsTranslate}}</button>
    </div>
  </app-desktop-popup-wrapper>
</lib-amdocs-popup>


<lib-amdocs-popup class="no-active-map mobile-popup"
                  *ngIf="isMobile"
                  (closeCallback)="closePopup()"
                  #genericErrorPopup>
  <div class="header d-flex justify-content-center">
    <div class="label-h4">{{'general.defaultErrorTitle' | amdocsTranslate}}</div>
  </div>
  <div class="content">
    <div class="error-msg text-small-thin">
      {{errorMsg}}
    </div>
  </div>
  <div class="footer icons-wrapper d-flex align-items-center justify-content-center">
    <button class="btn btn-medium primary"
            (click)="genericErrorPopup.close(); closePopup()">{{'common.close' | amdocsTranslate}}</button>
  </div>
</lib-amdocs-popup>
