import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AmdocsDeviceService, DropdownItem} from 'amdocs-core';
import {FormControl} from '@angular/forms';
import { Html5Qrcode } from 'html5-qrcode';


declare var Html5QrcodeScanner: any;

@Component({
  selector: 'app-qr-reader',
  templateUrl: './qr-reader.component.html',
  styleUrls: ['./qr-reader.component.scss']
})
export class QrReaderComponent implements OnInit, OnDestroy {

  public html5QrCode: Html5Qrcode;
  private cameraOpen = false;
  public qrMode = QR_MODE;
  public qrScanResult: string;
  public selectedQrMode: QR_MODE;

  private config = {
    fps: 25,    // Optional frame per seconds for qr code scanning
    qrbox: 250  // Optional if you want bounded box UI
  };
  private cameraId: any;
  private cameraList: any;
  public cameraCtrl: FormControl = new FormControl();
  @Input() uploadBtnText = 'Upload File';
  @Input() showCameraList = false;
  @Output() scan = new EventEmitter<string>();
  @Output() scanError = new EventEmitter<string>();
  @Output() scanStart = new EventEmitter<string>();
  @Output() scanStop = new EventEmitter<string>();
  @Output() scanStartSuccess = new EventEmitter<string>();
  @Output() scanStartError = new EventEmitter<string>();
  @Output() getCamerasError = new EventEmitter<string>();
  @Output() selectedModeChanged = new EventEmitter<any>();

  constructor(private deviceService: AmdocsDeviceService) { }

  ngOnInit(): void {
    this.html5QrCode = new Html5Qrcode('reader');
    if (this.deviceService.isChromeIOS()) {
      this.selectedQrMode = QR_MODE.file;
      this.selectedModeChanged.emit(this.selectedQrMode);
    } else {
      this.selectedQrMode = QR_MODE.camera;
      this.selectedModeChanged.emit(this.selectedQrMode);
      this.initScanner();
    }
  }

  initScanner(): void {
    Html5Qrcode.getCameras().then(devices => {
      const camera = devices.find(c => c.label.toLowerCase().indexOf('back') > -1) || devices[devices.length - 1];
      this.cameraList = this.createCameraList(devices);
      this.cameraId = camera.id;
      this.startScan();
    }).catch(() => {
      this.selectedQrMode = QR_MODE.file;
      this.selectedModeChanged.emit(this.selectedQrMode);
      this.getCamerasError.emit();
    });
  }

  private createCameraList(cameras): DropdownItem[] {
    return cameras.map( (camera): DropdownItem => {
      return {key: camera.label, value: camera.label, disabled: false};
    });
  }

  public startScan(): void {
    this.scanStart.emit();
    this.html5QrCode.start(
      { deviceId: { exact: this.cameraId} },
      this.config,
      (qrMessage) => {
        this.qrScanResult = qrMessage;
        this.stopScan();
        this.scan.emit(this.qrScanResult);
      },
      () => {})
      .then(() => {
        this.cameraOpen = true;
        this.scanStartSuccess.emit();
      })
      .catch(err => {
        this.scanStartSuccess.emit();
        this.scanStartError.emit(err);
      });
  }

  public stopScan(): void {
    try {
      this.html5QrCode.stop().then(() => {
        this.cameraOpen = false;
      });
      this.html5QrCode.clear();
      this.scanStop.emit();
    } catch (e) {
    }
  }

  public handleQrUpload(event): void {
    // Scan QR Code
    const file = event.target.files[0];
    this.html5QrCode.scanFile(file, false)
      .then((qrMessage) => {
        this.qrScanResult = qrMessage;
        this.scan.emit(this.qrScanResult);
      })
      .catch((error) => {
        this.scanError.emit(error);
      });
  }

  ngOnDestroy(): void {
    if (this.selectedQrMode === QR_MODE.camera && this.cameraOpen) {
      this.stopScan();
    }
  }
}

export enum QR_MODE {
  camera = 1,
  file = 2
}
