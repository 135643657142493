import {ICodeNameItem} from './map';
import {ILocation} from './location';

export const getUserProfileQuery = `
      query GetUserProfile {
        userProfile {
          accessBridge {
            id
            displayName
            dialInNumber
          }
        }
      }
     `;


export const getUserSettingsQuery =
`query GetUserSettings {
  userSettings {
    defaultLocation {
      country {
        code
        name
      }
      building {
        code
        name
      }
      floor {
        code
        name
      }
      wingGroup {
        code
        name
      }
      neighborhood {
        code
        name
      }
      unit {
        code
        name
      }
      wing {
        code
        name
      }
    }
    defaultTimeRange {
      startTime
      endTime
    }
    defaultAmenities {
      regionCode
      amenities {
        code
      }
    }
  }
}
`;

export const getUserSettingsUserCodeQuery =
`query GetUserSettings($userCode : String) {
  userSettings(userCode: $userCode) {
    defaultLocation {
      country {
        code
        name
      }
      building {
        code
        name
      }
      floor {
        code
        name
      }
      wingGroup {
        code
        name
      }
      neighborhood {
        code
        name
      }
      unit {
        code
        name
      }
      wing {
        code
        name
      }
    }
    defaultTimeRange {
      startTime
      endTime
    }
  }
}
`;

export const getOtherUserProfileQuery = `
  query OtherUserProfile($employeeId : String!) {
  otherUserProfile(employeeId: $employeeId) {
    code
    org2unit
    org3unit
  }
}
`;


// export interface IDefaultLocation {
//   country?: ICodeNameItem;
//   building?: ICodeNameItem;
//   floor?: ICodeNameItem;
//   wingGroup?: ICodeNameItem;
//   neighborhood?: ICodeNameItem;
//   unit?: ICodeNameItem;
// }

export interface IDefaultAmenity {
  regionCode: string;
  amenities: ICodeNameItem[];
}

export interface IDefaultTimeRange {
  endTime: string;
  startTime: string;
}

export interface IUserSettings {
  defaultLocation: ILocation;
  defaultAmenities: IDefaultAmenity[];
  defaultTimeRange: IDefaultTimeRange;
}

export interface IUserProfile {
  code: string;
  country: string;
  name: string;
  legalEntity: string;
  unit?: string;
  org2unit?: string;
}
export interface IUserSettingsResponse {
  userSettings: IUserSettings;
}

export interface IOtherUserProfile {
  code: string;
  org2unit?: string;
  org3unit?: string;
}

export const setDefaultTimeRangeQuery = `
mutation SetDefaultTimeRange($input: DefaultTimeRangeInput!) {
  setDefaultTimeRange(input: $input)
}
`;
