import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AmdocsUiModule} from 'amdocs-core';
import {HeaderDesktopComponent} from './components/header-desktop/header-desktop.component';
import {FooterDesktopComponent} from './components/footer-desktop/footer-desktop.component';
import {LeafMapComponent} from './components/leaf-map/leaf-map.component';
import {DesktopMapPageLayoutComponent} from './components/desktop-map-page-layout/desktop-map-page-layout.component';
import {RightSidePanelHeaderComponent} from './components/desktop-map-page-layout/right-side-panel-header/right-side-panel-header.component';
import {DesktopSimplePageLayoutComponent} from './components/desktop-simple-page-layout/desktop-simple-page-layout.component';
import {SelectLocationComponent} from './components/select-location-component/select-location.component';
import {DesktopPopupWrapperComponent} from './components/desktop-popup-wrapper/desktop-popup-wrapper.component';
import {RouterModule} from '@angular/router';
import {DesktopFooterSeatsLegendComponent} from './components/desktop-footer-seats-legend/desktop-footer-seats-legend.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {UnitsAutocompleteComponent} from './components/units-autocomplete/units-autocomplete.component';
import {MapCoordinatePipe} from './pipes/map-coordinate.pipe';
import {AreYouSurePopupComponent} from './components/are-you-sure-popup/are-you-sure-popup.component';
import {EmployeeSearchComponent} from './components/employee-search/employee-search.component';
import {SelectedEmployeesComponent} from './components/employee-search/selected-employees/selected-employees.component';
import {NavTabComponent} from './components/nav-tabs/nav-tab/nav-tab.component';
import {NavTabsComponent} from './components/nav-tabs/nav-tabs.component';
import {MyBookingsComponent} from './components/my-bookings/my-bookings.component';
import {SeatBookingCardsComponent} from './components/my-bookings/seat-booking-cards/seat-booking-cards.component';
import {MobileSimplePageLayoutComponent} from './components/mobile-map-page-layout/mobile-simple-page-layout.component';
import {ChangeAmenitiesComponent} from './components/change-amenities/change-amenities.component';
import {MobileSidebarMenuComponent} from './components/mobile-sidebar-menu/mobile-sidebar-menu.component';
import {AvatarComponent} from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { SelectWhenComponent } from './components/select-when/select-when.component';
import { EmployeeSearchBoxComponent } from './components/employee-search-box/employee-search-box.component';
import { EmployeeSearchTextFieldComponent } from './components/employee-search-text-field/employee-search-text-field.component';
import { FindAFriendCardsComponent } from './components/find-a-friend-cards/find-a-friend-cards.component';
import {FafSeatCardComponent} from './components/find-a-friend-cards/faf-seat-card/faf-seat-card.component';
import { MobileFooterLegendComponent } from './components/mobile-footer-legend/mobile-footer-legend.component';
import { ClientSeatManagementComponent } from './components/client-seat-management/client-seat-management.component';
import { BookASeatConfirmationComponent } from './components/book-a-seat-confiramtion/book-a-seat-confirmation.component';
import { GenericErrorPopupComponent } from './components/generic-error-popup/generic-error-popup.component';
import {VersionUpdateComponent} from './components/version-update/version-update.component';
import {QrReaderComponent} from './components/qr-reader/qr-reader.component';

const shareComponents = [
  HeaderDesktopComponent,
  FooterDesktopComponent,
  LeafMapComponent,
  RightSidePanelHeaderComponent,
  DesktopMapPageLayoutComponent,
  DesktopSimplePageLayoutComponent,
  SelectLocationComponent,
  DesktopPopupWrapperComponent,
  DesktopFooterSeatsLegendComponent,
  UnitsAutocompleteComponent,
  MapCoordinatePipe,
  AreYouSurePopupComponent,
  EmployeeSearchComponent,
  SelectedEmployeesComponent,
  MyBookingsComponent,
  SeatBookingCardsComponent,
  NavTabsComponent,
  NavTabComponent,
  MobileSimplePageLayoutComponent,
  ChangeAmenitiesComponent,
  MobileSimplePageLayoutComponent,
  MobileSidebarMenuComponent,
  AvatarComponent,
  BadgeComponent,
  SelectWhenComponent,
  EmployeeSearchBoxComponent,
  EmployeeSearchTextFieldComponent,
  MobileFooterLegendComponent,
  FindAFriendCardsComponent,
  FafSeatCardComponent,
  ClientSeatManagementComponent,
  BookASeatConfirmationComponent,
  GenericErrorPopupComponent,
  VersionUpdateComponent,
  QrReaderComponent
];

@NgModule({
  declarations: [shareComponents],
  imports: [
    AmdocsUiModule,
    CommonModule,
    RouterModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
    exports: [AmdocsUiModule, shareComponents]
})
export class ShareModule {
}
