import {Component, Input} from '@angular/core';
import {AmdocsUserService} from 'amdocs-core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  @Input() size: AvatarImgSize = AvatarImgSize.medium;
  @Input() userName: string = null;
  @Input() uuid: string = null;
  public showDefaultImage = false;

  constructor(private userService: AmdocsUserService) {
  }

  get fullPath(): string {
    const uuid = this.uuid || this.userService.user?.userProfile?.uuid;
    return `${environment.imgUrl}/${uuid}?v=${environment.avatarsVersion}`;
  }

  get userShortName(): string {
    return this.userService.getUserShortName(this.userName);
  }

  get fontClass(): string {
    const defaultClass = `${this.size}`;
    switch (this.size) {
      case AvatarImgSize.xs: return `${defaultClass} text-small-bold`;
      case AvatarImgSize.small: return `${defaultClass} text-medium-normal`;
      case AvatarImgSize.medium: return `${defaultClass} label-h3`;
      case AvatarImgSize.big: return `${defaultClass} label-h02`;
    }
  }

}

export enum AvatarImgSize {
  xs = 'xs',
  small = 'small',
  medium = 'medium',
  big = 'big'
}
