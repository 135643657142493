<div class="select-location-wrapper" [ngClass]="{'client': isClientMode}">
  <div *ngIf="!isClientMode" class="select-location-header select-location-elem d-flex align-items-center justify-content-between">
    <span class="label-h1">{{title | amdocsTranslate}}</span>
  </div>
  <div class="select-location-content select-location-elem ">
    <div *ngIf="!isClientMode" class="text-medium-normal content-header">{{'selectLocation.subTitle' | amdocsTranslate}}</div>
    <div class="DDL-wrapper d-flex flex-wrap">
      <div class="DDL-item">
        <lib-amdocs-autocomplete #countriesAutocompleteComponent *ngIf="countriesDDLItems" [elementId]="'countries-DDL'"
                                 [clearable]="false"
                                 [label]="'Country'"
                                 [items]="countriesDDLItems" [hasHierarchy]="false"
                                 [errorsMessage]="countriesErrorMsg"
                                 [placeholder]="'selectLocation.select' | amdocsTranslate"
                                 [isRequired]="true"
                                 [disabled]="disabledCountries"
                                 (itemChangedCallback)="onCountriesChanges($event)">
        </lib-amdocs-autocomplete>
      </div>
      <div class="DDL-item">
        <lib-amdocs-autocomplete #buildingsAutocompleteComponent *ngIf="buildingsDDLItems" [elementId]="'buildings-DDL'"
                                 [clearable]="false"
                                 [label]="'Building'"
                                 [items]="buildingsDDLItems" [hasHierarchy]="false" #buildings
                                 [errorsMessage]="buildingsErrorMsg"
                                 [placeholder]="'selectLocation.select' | amdocsTranslate"
                                 [isRequired]="true"
                                 [disabled]="disabledBuildings"
                                 (itemChangedCallback)="onBuildingsChanges($event)">
        </lib-amdocs-autocomplete>
      </div>
      <div class="DDL-item">
        <lib-amdocs-autocomplete #floorsAutocompleteComponent *ngIf="floorsDDLItems && (isQRDownload || isQRGeneration)" [elementId]="'floors-DDL'"
                                 [clearable]="false" [label]="'Floor'"
                                 [items]="floorsDDLItems" [hasHierarchy]="false" #floors
                                 [errorsMessage]="floorsErrorMsg"
                                 [placeholder]="'selectLocation.select' | amdocsTranslate"
                                 [isRequired]="false"
                                 [disabled]="disabledFloor"
                                 (itemChangedCallback)="onFloorsChanges($event)">
        </lib-amdocs-autocomplete>
        <lib-amdocs-autocomplete #floorsAutocompleteComponent *ngIf="floorsDDLItems && !isQRDownload &&  !isQRGeneration" [elementId]="'floors-DDL'"
        [clearable]="false" [label]="'Floor'"
        [items]="floorsDDLItems" [hasHierarchy]="false" #floors
        [errorsMessage]="floorsErrorMsg"
        [placeholder]="'selectLocation.select' | amdocsTranslate"
        [isRequired]="true"
        [disabled]="disabledFloor"
        (itemChangedCallback)="onFloorsChanges($event)">
</lib-amdocs-autocomplete>
      </div>
      <div *ngIf="isClientMode" class="DDL-item">
        <lib-amdocs-autocomplete #wingsAutocompleteComponent *ngIf="wings"
                                 [elementId]="'wing-groups-DDL'" [clearable]="false"
                                 [label]="'Wings'"
                                 [items]="wingsDDLItems" [hasHierarchy]="false" #wings
                                 [errorsMessage]="wingsErrorMsg"
                                 [placeholder]="'selectLocation.select' | amdocsTranslate"
                                 [isRequired]="true"
                                 [disabled]="disabledWings"
                                 (itemChangedCallback)="onWingsChanges($event)"
        >
        </lib-amdocs-autocomplete>
      </div>
      <!--<div class="DDL-item">
        <lib-amdocs-autocomplete #wingGroupAutocompleteComponent *ngIf="wingGroupDDLItems"
                                 [elementId]="'wing-groups-DDL'" [clearable]="false"
                                 [label]="'Wing Group'"
                                 [items]="wingGroupDDLItems" [hasHierarchy]="false" #wingGroups
                                 [errorsMessage]="wingsGroupsErrorMsg"
                                 [placeholder]="'selectLocation.select' | amdocsTranslate"
                                 [isRequired]="true"
                                 [disabled]="disabledWings"
                                 (itemChangedCallback)="onWingGroupChanges($event)"
        >
        </lib-amdocs-autocomplete>
      </div>-->
      <div [ngClass]="isClientMode ? 'is-client' : ''" class="DDL-item" *ngIf="isUnitMode || isClientMode">
        <app-units-autocomplete #unitsAutocompleteComponent
                                [label]="'Unit'" [items]="unitsList"
                                [errorsMessage]="unitsErrorMsg"
                                [placeholder]="'selectLocation.select' | amdocsTranslate"
                                [isRequired]="!isClientMode"
                                [disabled]="disabledUnits"
                                [selectIfOneItem]="isClientMode ? false : true"
                                (itemChanged)="handleUnitChanged($event)"
        ></app-units-autocomplete>
      </div>
<!--      <div class="unit-wrapper" [ngClass]="isClientMode ? 'is-client' : ''" *ngIf="isUnitMode || isClientMode">-->
<!--      </div>-->
      <div class="DDL-item" *ngIf="isClientMode">
        <lib-amdocs-autocomplete #neighborhoodGroupAutocompleteComponent *ngIf="neighborhoodDDLItems"
                                 [elementId]="'neighborhood-DDL'" [clearable]="false"
                                 [label]="'Neighborhood'"
                                 [items]="neighborhoodDDLItems" [hasHierarchy]="false" #wingGroups
                                 [errorsMessage]="neighborhoodErrorMsg"
                                 [placeholder]="'selectLocation.select' | amdocsTranslate"
                                 [disabled]="disabledNeighborhood"
                                 [selectIfOneItem]="false"
                                 (itemChangedCallback)="onNeighborhoodChanges($event)"
        >
        </lib-amdocs-autocomplete>
      </div>
      <div class="set-as-default" *ngIf="!isMobile && isClientMode && showDefaultCB">
        <lib-amdocs-checkbox [elementId]="'set-as-default'"
                             [control]="setAsMyDefaultLocationControl"
        >{{'selectLocation.setAsMyDefaultLocation' | amdocsTranslate}}
        </lib-amdocs-checkbox>
      </div>

<!--      <div class="manage-wing-group w-100" *ngIf="isMaintenanceMode">-->
<!--        <div *ngIf="!showManageWingsItems else wingGroupItems"-->
<!--             class="manage-link-wrapper w-100 d-flex justify-content-end">-->
<!--          <button [disabled]="isManageWingsLinkDisable" (click)="showMangeWings()"-->
<!--                  class="manage-link btn btn-text">{{'selectLocation.manageWingGroup' | amdocsTranslate}}</button>-->
<!--        </div>-->
<!--        <ng-template #wingGroupItems class=" w-100">-->
<!--          <div class="wing-groups-wrapper">-->
<!--            <div-->
<!--              class="form-title text-medium-normal text-color-secondary">{{'selectLocation.relevantWings' | amdocsTranslate}}</div>-->
<!--            <form *ngIf="wingGroupForm" [formGroup]="wingGroupForm" class="d-flex">-->
<!--              <div *ngFor="let wing of wings" class="wing-checkbox">-->
<!--                <lib-amdocs-checkbox [elementId]="wing.code" [control]="wingGroupForm.controls[wing.code]"-->
<!--                                     (change)="onWingsChange()">{{wing.name}}</lib-amdocs-checkbox>-->
<!--              </div>-->
<!--            </form>-->
<!--            <div *ngIf="showEqualErrMsg" class="error-msg text-small-normal">-->
<!--              {{'selectLocation.wingGroupExist' | amdocsTranslate}}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="close-manage-wings w-100 d-flex justify-content-end">-->
<!--            <button [disabled]="isManageWingsLinkDisable" (click)="hideMangeWings()"-->
<!--                    class="close-btn btn btn-text">{{'selectLocation.cancel' | amdocsTranslate}}</button>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </div>-->
    </div>
  </div>
  <!--  <div class="select-location-upload-file"></div>-->
  <div
    class="select-location-footer select-location-elem d-flex align-items-center justify-content-between flex-row-reverse">
    <div class="right-buttons">
      <div *ngIf="isClientMode && showDefaultCB" class="set-as-default d-block d-md-none">
        <lib-amdocs-checkbox
                             [elementId]="'set-as-default'"
                             [control]="setAsMyDefaultLocationControl"
        >{{'selectLocation.setAsMyDefaultLocation' | amdocsTranslate}}
        </lib-amdocs-checkbox>
      </div>
      <div class="buttons" *ngIf="!isQRGeneration && !isQRDownload">
        <button class="btn btn-medium secondary"
                (click)="cancel()">{{'selectLocation.cancel' | amdocsTranslate}}</button>
        <button class="btn btn-medium primary"
                (click)="submitLocation()">{{submitButtonText}}</button>
      </div>
      <div class="buttons" *ngIf="isQRGeneration">
        <button class="btn btn-medium secondary"
                (click)="cancel()">{{'selectLocation.cancel' | amdocsTranslate}}</button>
        <button class="btn btn-medium primary"
                (click)="submitLocation()">QR Generation</button>
      </div>
      <div class="buttons" *ngIf="isQRDownload">
        <button class="btn btn-medium secondary"
                (click)="cancel()">{{'selectLocation.cancel' | amdocsTranslate}}</button>
        <button class="btn btn-medium primary"
                (click)="submitLocation()">QR Download</button>
      </div>
    </div>
    <button class="btn btn-tiny white d-md-block d-none"
            (click)="clear()">{{'selectLocation.clear' | amdocsTranslate}}</button>
  </div>
</div>
