import {AfterViewInit, EventEmitter, Component, ContentChildren, Input, OnDestroy, Output, QueryList} from '@angular/core';
import {NavTabComponent} from './nav-tab/nav-tab.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements AfterViewInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  @Input() selectedId: string;
  @Output() selectedIdChange = new EventEmitter<string>();

  @ContentChildren(NavTabComponent) tabs: QueryList<NavTabComponent>;

  ngAfterViewInit(): void {
    this.tabs.forEach((tabInstance: NavTabComponent) => {
      tabInstance.selected = this.selectedId;
      this.updateTabsSelectedId();
      tabInstance.tabClick.pipe(takeUntil(this.destroyed$)).subscribe(id => {
        this.selectedId = id;
        this.selectedIdChange.emit(id);
        this.updateTabsSelectedId();
      });
    });
  }

  updateTabsSelectedId(): void {
    this.tabs.forEach((tabInstance: NavTabComponent) => {
      tabInstance.selected = this.selectedId;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
