import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.scss']
})
export class NavTabComponent {

  @Input() id: string;
  @Input() text: string;
  @Input() selected: string;
  @Input() counter: number;
  @Output() tabClick = new EventEmitter<string>();

  onClick(): void {
    this.tabClick.emit(this.id);
  }
}
