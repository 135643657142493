import {Injectable} from '@angular/core';
import {ERole} from '../models';
import jwt_decode from 'jwt-decode';
import {AmdocsAuthService} from 'amdocs-core';

@Injectable({
    providedIn: 'root'
  }
)
export class RolesService {

  private userRoles: ERole[] = [];

  constructor(private authService: AmdocsAuthService) {
  }

  public loadRoles(): void {
    try {
      const token = this.authService.getToken();
      const decoded: any = jwt_decode(token);
      if (decoded.roles) {
        this.userRoles = decoded.roles;
      }
    } catch (e) {
      console.warn('could not parse token');
    }
  }

  public isAdmin(): boolean {
    return true;
  }

  public isAllowed(roles: ERole[]): boolean {
    return this.userRoles.some(role => roles.indexOf(role) >= 0);
  }

  public isEditor(): boolean {
    return this.isAllowed([
      ERole.facilityAdmin,
      ERole.itAdmin,
      ERole.itSupportAdmin,
      ERole.unitAdmin,
    ]);
  }
}
