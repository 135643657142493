import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../core/api.service';
import {catchError, finalize, first, mergeMap, takeUntil} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {IUserFavorite, IUserUpdateFavoriteSeat} from '../../../models/user-favorite-seat';
import {Tabs, ISeatBooking, IMyBookings, ICancelBookingRes, IExtendedSeat, ICountry} from '../../../models';
import * as moment from 'moment';
import {CONSTANTS} from '../../../constants';
import {AmdocsEventBusService, AmdocsTranslateService, ApiError,AmdocsDeviceService} from 'amdocs-core';
import {Router} from '@angular/router';
import {SelectedMapFiltersDetailsService} from '../../../core/selected-map-filters-details.service';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss']
})
export class MyBookingsComponent implements OnInit, OnDestroy {

  private today = moment();
  private destroyed$ = new Subject<void>();
  public tabs = Tabs;
  public loading = true;
  public bookingSeatsRecent: ISeatBooking[] = [];
  public bookingSeatsUpcomingForMe: ISeatBooking[] = [];
  public bookingSeatsUpcomingByMe: ISeatBooking[] = [];
  public favoritesSeats: IUserFavorite[] = [];
  public region: string = null;
  public sourceSystem: any
  @Input() showTabs = true;
  @Input() currentTab: Tabs = Tabs.upcoming;
  @Output() myBookingsLength = new EventEmitter<number>();
  @Output() closeMyBookingBarEvent = new EventEmitter<number>();
  @Output() genericApiErrorCB = new EventEmitter<ApiError>();

  constructor(private apiService: ApiService,
              private eventBusService: AmdocsEventBusService,
              private router: Router,
              private selectedMapFiltersDetailsService: SelectedMapFiltersDetailsService,
              private translateService: AmdocsTranslateService,
              private deviceService: AmdocsDeviceService
              ) {
  }

  get bookingSeatsForMe(): ISeatBooking[] {
    return this.currentTab === this.tabs.upcoming ? this.bookingSeatsUpcomingForMe : null;
  }

  get bookingSeatsByMe(): ISeatBooking[] {
    return this.currentTab === this.tabs.upcoming ? this.bookingSeatsUpcomingByMe : null;
  }

  get recentBooking(): ISeatBooking[] {
    return this.currentTab === this.tabs.recent ? this.bookingSeatsRecent : null;
  }


  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.getMySeatsBooking();
    this.eventBusService.on(CONSTANTS.EVENTS.UPDATE_MY_BOOKING, () => {
      this.getMySeatsBooking();
    }, true);
  }

  getMySeatsBooking(): void {
    this.apiService.getMySeatsBooking().pipe(
      mergeMap((myBookings: IMyBookings) => {
        this.bookingSeatsUpcomingByMe = myBookings.bookedForOthers.filter(b=> !b.isCancelled);
        this.bookingSeatsUpcomingForMe = myBookings.bookedForMe.filter(b => !b.isCancelled);
        this.bookingSeatsRecent = myBookings.recentBookings;
        this.myBookingsLength.emit(this.bookingSeatsUpcomingForMe.length + this.bookingSeatsUpcomingByMe.length);
        return this.getFavorites(myBookings.bookedForMe, myBookings.bookedForOthers, myBookings.recentBookings);
      }),
      finalize(() => {
        this.loading = false;
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getFavoritesByWingGroupCode(wingGroupCode: string): Observable<IUserFavorite[]> {
    return this.apiService.getUserFavorites(wingGroupCode).pipe(
      mergeMap((seats) => {
        if (this.favoritesSeats?.length > 0) {
          seats.map(s => {
            if (!this.favoritesSeats.find(seat => seat.seatCode === s.seatCode)) {
              this.favoritesSeats.push(s);
            }
          })
        } else {
          this.favoritesSeats = seats;
        }
        this.updateFavoriteSeats(seats);
        return of(seats);
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
    );
  }

  updateFavoriteSeats(seats: IUserFavorite[]): void {
    seats.forEach(seat => {
      const find = this.findSeat(seat.seatCode);
      if (find) {
        find.isFavorite = true;
      }
    });
  }

  getFavorites(bookedForMe: ISeatBooking[], bookedForOthers: ISeatBooking[], recentBookings: ISeatBooking[]): Observable<void[]> {
    const wingGroupCodesForMe = bookedForMe.map(seat => seat.location.wingGroup.code);
    const wingGroupCodesByMe = bookedForOthers.map(seat => seat.location.wingGroup.code);
    const wingGroupRecentBooking = recentBookings.map(seat => seat.location.wingGroup.code);
    const concatArrays = wingGroupCodesForMe.concat(wingGroupCodesByMe, wingGroupRecentBooking);
    const wingGroupCodesDistinct = new Set(concatArrays);
    const calls = [];
    wingGroupCodesDistinct.forEach((wingGroupCode) => {
      calls.push(this.getFavoritesByWingGroupCode(wingGroupCode));
    });
    return forkJoin<void>(calls);
  }

  tabClick(tab: Tabs | string): void {
    this.currentTab = tab as Tabs;
  }

  openSeat(seat: ISeatBooking): void {
    this.getCountries(seat)
  }

  navigateToSeat(seat : ISeatBooking): void{
    const date = seat.bookingTime.start.split(' ')[0];
    const endDate = this.isOverNight(seat.bookingTime.start, seat.bookingTime.end) ? seat.bookingTime.end.split(' ')[0] : null;
    const start = seat.bookingTime.start.split(' ')[1];
    const end = seat.bookingTime.end.split(' ')[1];
    const queryParams = {
      date: date,
      endDate: endDate,
      from: start,
      to: end,
      seatId: seat.seatCode,
      wingGroupCode: seat.location.wingGroup.code,
      friendId: null
    };
    seat.location.country.region = this.region;
    sessionStorage.setItem('selectLocationClient', JSON.stringify(seat.location));
    sessionStorage.removeItem('amenities');
    this.closeMyBookingBarEvent.emit();
    this.router.navigate([CONSTANTS.ROUTES.USER_MAP], {queryParams});
  }

  isOverNight(start: string, end: string): boolean {
    return start.split(' ')[1] > end.split(' ')[1];
  }

  getCountries(seat: ISeatBooking): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.apiService.getCountries().pipe(
      mergeMap((countries: ICountry[]) => {
        this.region = countries.find(c => c.countryCode === seat.location.country.code)?.regionCode;
        this.navigateToSeat(seat);
        return of(countries);
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    ).subscribe();
  }

  updateFavorite(updateFavorite: IUserUpdateFavoriteSeat, seats: ISeatBooking[]): void {
    const seat = seats.find(s => s.seatCode === updateFavorite.input.seatCode);
    seat.isFavorite = updateFavorite.input.isFavorite;
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.apiService.setUserFavorite(updateFavorite).pipe(
      mergeMap((res) => {
        this.eventBusService.emit(CONSTANTS.EVENTS.UPDATE_SEATS_ICON_AFTER_BOOK_ACTION);
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        if (updateFavorite.input.isFavorite) {
          this.favoritesSeats.push({
            seatCode: seat.seatCode,
            seatName: '',
            wingGroupCode: updateFavorite.input.wingGroupCode
          });
        } else {
          this.favoritesSeats = this.favoritesSeats.filter(s => s.seatCode !== seat.seatCode);
        }
        return of(res);
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      finalize(() => {
        // return this.getFavorites(this.bookingSeatsForMe, this.bookingSeatsByMe);
        // this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
      })
    ).subscribe();
  }


  removeBooked(seat: { seatCode: string, bookingCode: string }): void {
    // TODO: Waiting for BE deploy

    const method =sessionStorage.getItem('method')
    console.log("methodmethodmethod",method)
    if(method && method!=='undefined' && method!='QR'){
      this.sourceSystem = method
    }
    else{
      console.log("inside else")
      this.sourceSystem = this.isMobile ? 'Mobile' : 'Desktop'
    }
    // sessionStorage.removeItem('method')
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    const params = {
      code: seat.bookingCode,
      sourceSystem:this.sourceSystem
    };
    this.apiService.cancelBooking(params.code,params.sourceSystem).pipe(
      mergeMap((res: ICancelBookingRes) => {
          if(res.cancelBooking.success) {
            this.eventBusService.emit(CONSTANTS.EVENTS.SHOW_CANCEL_BOOKING_MSG, {title: this.translateService.getText('client.seatManagement.seatBookingsIsCanceled')});
            this.eventBusService.emit(CONSTANTS.EVENTS.UPDATE_SEATS_ICON_AFTER_BOOK_ACTION, {
              loadMap: true,
              loadBookings: true,
              loadFavs: false
            });
            this.myBookingsLength.emit(this.bookingSeatsUpcomingForMe.length + this.bookingSeatsUpcomingByMe.length - 1);
            this.bookingSeatsUpcomingForMe = this.bookingSeatsUpcomingForMe.filter(b => b.seatCode !== seat.seatCode);
            this.bookingSeatsUpcomingByMe = this.bookingSeatsUpcomingByMe.filter(b => b.seatCode !== seat.seatCode);
            return of(res)
          } else{
            return throwError({
              status: 200,
              error: {
                errors: res
              }
            });
          }

      }),
      first(),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        this.closeMyBookingBarEvent.emit();
      }))
      .subscribe();
  }

  findSeat(code: string): ISeatBooking {
    return this.bookingSeatsRecent.find(b => b.seatCode === code)
      || this.bookingSeatsUpcomingForMe.find(b => b.seatCode === code)
      || this.bookingSeatsUpcomingByMe.find(b => b.seatCode === code);
  }
}
