import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  constructor() {

  }

  public cloneObj(obj): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public getParamsFromQueryString(queryString: string): any {
    const params = new URLSearchParams(queryString);
    const obj = {};
    for (const key of (params as any).keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }
    return obj;
  }

  public getOrderedListByCode(list: any[], orderList: any[]): []{
    const orderedArray: any = [];
    orderList.map(a => {
      const foundedItem = list.find(item => item?.code === a?.code);
      if (foundedItem) {
        orderedArray.push(foundedItem);
      }
    });
    if (orderedArray.length !== list.length) {
      list.map(a => {
        if (!orderedArray.find(item => item?.code === a?.code)) {
          orderedArray.push(a);
        }
      });
    }

    return orderedArray;
  }
}
