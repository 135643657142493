import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, finalize, mergeMap, takeUntil} from 'rxjs/operators';
import {
  AmdocsDeviceService,
  AmdocsEventBusService,
  AmdocsUserService,
  ApiError
} from 'amdocs-core';
import {FormControl} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {IEmployee, ISearchEmployee} from '../../../models';
import {CONSTANTS} from '../../../constants';
import {Observable, of, Subject, throwError} from 'rxjs';
import {EmployeeSearchService} from '../../../core/employee-search.service';
import {AvatarImgSize} from '../avatar/avatar.component';

@Component({
  selector: 'app-employee-search-box',
  templateUrl: './employee-search-box.component.html',
  styleUrls: ['./employee-search-box.component.scss']
})
export class EmployeeSearchBoxComponent implements OnInit, OnDestroy, OnChanges {

  private destroyed$ = new Subject<void>();
  private intersectionObserver: IntersectionObserver;

  public resultsWrapperHeight: number;
  public employees: IEmployee[] = null;
  public nextToken: string = null;
  public showResults = false;
  public showEndOfResults = false;
  public searchError: ApiError = null;
  public isLoading = false;
  public avatarImgSize = AvatarImgSize;

  @Input() employeeSearchControl: FormControl;
  @Input() isScroll: boolean = false;
  @Input() showJobInfo: boolean = true;

  @Output() selectEmployee = new EventEmitter<IEmployee>();

  @ViewChild('loadMore') loadMorePlaceholderElement: ElementRef;
  @ViewChild('searchResults') searchResultsElement: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document,
              private userService: AmdocsUserService,
              private homeService: EmployeeSearchService,
              private deviceService: AmdocsDeviceService,
              private eventBus: AmdocsEventBusService,
  ) {
  }

  get showSearchClearButton(): boolean {
    return this.employeeSearchControl?.value || this.showResults;
  }

  ngOnInit(): void {
    this.setResultsWrapperHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employeeSearchControl.firstChange) {
      this.inputFieldValueChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.removeScrollListening();
  }

  inputFieldValueChanges(): void {
    this.employeeSearchControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    ).subscribe((searchText) => {
      if (searchText) {
        this.showResults = true;
        this.onSearchInputChange(searchText);
      }
    });
  }

  onSearchInputChange(searchText): void {
    this.removeScrollListening();
    this.nextToken = null;
    this.searchEmployee(searchText);
  }

  setResultsWrapperHeight(): void {
    // const currentBodyHeight = this.document.body.style.height;
    const currentBodyHeight = this.deviceService.screenHeight;
    this.resultsWrapperHeight = currentBodyHeight ? currentBodyHeight - 47 : this.document.body.clientHeight - 47;
  }

  searchEmployee(searchText): void {
    this.searchError = null;
    this.showEndOfResults = false;
    this.isLoading = true;
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.homeService.searchEmployee(searchText, CONSTANTS.EMPLOYMENT_SEARCH.SEARCH_RESULTS, this.nextToken, true)
      .pipe(
        mergeMap((res: ISearchEmployee): Observable<ISearchEmployee> => {
          // if we called with token, means it's extra data for the same search;
          if (this.nextToken) {
            this.employees = this.employees.concat(res.employees);
          } else {
            this.employees = res.employees;
          }

          this.nextToken = res.nextToken;

          if (this.nextToken) {
            this.addScrollListening();
          } else {
            setTimeout(() => {
              this.showEndOfResults = this.resultsWrapperHeight < (this.searchResultsElement?.nativeElement?.clientHeight || 0);
            }, 100);
          }
          return of(res);
        }),
        catchError((error: ApiError) => {
          this.searchError = error;
          return throwError(error);
        }),
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
          this.isLoading = false;
        }),
        takeUntil(this.destroyed$)
      ).subscribe();
  }

  goToEmployeeDetails(employee: IEmployee): void {
    this.selectEmployee.emit(employee); //
  }

  hide(): void {
    this.showResults = false;
    this.removeScrollListening();
  }

  clearSearch(): void {
    this.employeeSearchControl.setValue('');
    this.hide();
  }

  addScrollListening(): void {
    if (this.intersectionObserver) {
      return;
    }
    const intersectionOptions = {
      rootMargin: '50px'
    };

    this.intersectionObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        this.removeScrollListening();
        this.searchEmployee(this.employeeSearchControl.value);
      }
    }, intersectionOptions);

    this.intersectionObserver.observe(this.loadMorePlaceholderElement.nativeElement);
  }

  removeScrollListening(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }
}
