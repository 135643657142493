import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {CONSTANTS} from '../../constants';
import {AmdocsDeviceService} from 'amdocs-core';

@Injectable({
  providedIn: 'root'
})
export class MobileOnlyGuardService implements CanActivate {

  constructor(private deviceService: AmdocsDeviceService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.deviceService.isMobile()) {
      if (next.data.nonMobileFallback) {
        this.router.navigate([next.data.nonMobileFallback]);
      } else {
        this.router.navigate([CONSTANTS.ROUTES.USER_MAP]);
      }
      return false;
    }
    return true;
  }
}
