import {Injectable} from '@angular/core';
import {AmdocsAuthService} from 'amdocs-core';
import {Observable, of} from 'rxjs';
import {IUserSettings} from '../models';
import {ApiService} from './api.service';
import {mergeMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  }
)
export class UserSettingsService {

  public userSettings: IUserSettings;

  constructor(private authService: AmdocsAuthService, private apiService: ApiService) {}

  getUserSettings(userCode: string = null, force: boolean = false): Observable<IUserSettings> {
    if (!force && this.userSettings) {
      return of(this.userSettings);
    }
    return this.apiService.getUserSettings(userCode).pipe(
      mergeMap((userSettings: IUserSettings) => {
        this.userSettings = userSettings;
        return of(this.userSettings);
      }),
    );
  }
}


