<div class="are-u-sure-wrapper">
  <div *ngIf="title || iconSrc" class="main-title d-flex text-start">
    <img lib-amdocs-image-version *ngIf="iconSrc" class="popup-img" src="{{iconSrc}}">
    <div class="label-h1">{{title}}</div>
  </div>
  <div *ngIf="descriptionLine1 || descriptionLine2" class="description-wrapper">
    <div *ngIf="descriptionLine1" class="description line-1 text-medium-normal d-flex text-start">{{descriptionLine1}}</div>
    <div *ngIf="descriptionLine2" class="description line-2 text-medium-normal d-flex text-start">{{descriptionLine2}}</div>
  </div>
  <div *ngIf="showOkButton || showCancelButton || showUpdateButton" class="buttons-wrapper d-flex justify-content-between">
    <div class="left=buttons">
      <button *ngIf="showOkButton" class="btn btn-medium white approve-button" (click)="approve.emit()">{{okButton}}</button>
    </div>
    <div class="right-buttons d-flex justify-content-end align-items-center">
      <button *ngIf="showCancelButton" class="btn btn-medium secondary cancel-button" (click)="cancel.emit()">{{cancelButton}}</button>
      <button *ngIf="showUpdateButton" class="btn btn-medium primary update-button" (click)="update.emit()">{{updateButton}}</button>
    </div>
  </div>
</div>

