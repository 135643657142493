import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CONSTANTS} from './constants';
import {AdminGuardService} from './core/guards/admin.guard.service';
import {ERole} from './models';
import {MobileOnlyGuardService} from './core/guards/mobile-only.guard.service';
import {DesktopOnlyGuardService} from './core/guards/desktop-only.guard.service';

const routes: Routes = [
  {
    path: CONSTANTS.ROUTES.ADMIN.BASE,
    loadChildren: () => import('./feature/admin/admin.module').then(m => m.AdminModule),
    canActivate: [DesktopOnlyGuardService, AdminGuardService],
    data: {
      permissions: [ERole.itAdmin, ERole.unitAdmin, ERole.facilityAdmin, ERole.itSupportAdmin]
    }
  },
  {
    path: CONSTANTS.ROUTES.USER_MAP,
    loadChildren: () => import('./feature/user-map/user-map.module').then(m => m.UserMapModule)
  },
  {
    path: CONSTANTS.ROUTES.MY_BOOKINGS,
    loadChildren: () => import('./feature/my-bookings-page-mobile/my-bookings-page-mobile.module').then(m => m.MyBookingsPageMobileModule),
    canActivate: [MobileOnlyGuardService]
  },
  {
    path: CONSTANTS.ROUTES.LOCATION_SELECTION,
    loadChildren: () => import('./feature/location-selection-mobile/search-where-mobile.module').then(m => m.SearchWhereMobile),
    canActivate: [MobileOnlyGuardService]
  },
  {
    path: CONSTANTS.ROUTES.AMENITIES_PAGE,
    loadChildren: () => import('./feature/amenities-page-mobile/amenities-page-mobile.module').then(m => m.amenitiesPageMobile),
    canActivate: [MobileOnlyGuardService]
  },
  {
    path: CONSTANTS.ROUTES.FIND_A_FRIEND,
    loadChildren: () => import('./feature/find-a-friend-mobile/find-a-friend-mobile.module').then(m => m.FindAFriendMobileModule),
    canActivate: [MobileOnlyGuardService]
  },
  {
    path: CONSTANTS.ROUTES.SWITCH_USER_PROFILE,
    loadChildren: () => import('./feature/switch-user-profile-mobile/switch-user-profile.module').then(m => m.SwitchUserProfileModule),
    canActivate: [MobileOnlyGuardService]
  },
  {
    path: CONSTANTS.ROUTES.BOOK_A_SEAT,
    pathMatch: 'full',
    loadChildren: () => import('./feature/book-a-seat-page-mobile/book-a-seat-page-mobile.module').then(m => m.BookASeatPageMobileModule),
    canActivate: [MobileOnlyGuardService]
  }, 
  {
    path: CONSTANTS.ROUTES.WHEN_PAGE_MOBILE,
    loadChildren: () => import('./feature/when-page-mobile/when-page-mobile.module').then(m => m.WhenPageMobileModule),
    canActivate: [MobileOnlyGuardService]
  },
  {
    path: CONSTANTS.ROUTES.QR_SCANNER,
    loadChildren: () => import('./feature/scan-qr-page/scan-qr.module').then(m => m.ScanQrModule),
    canActivate: [MobileOnlyGuardService]

  },
  {
    path: 'qr-code/*', // Add a route for the download URL
    redirectTo: window.location.origin + '/qr-code/*'
  },
  {
    path: '**',
    redirectTo: CONSTANTS.ROUTES.HOME
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
