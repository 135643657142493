<div class="sub-title" [hidden]="loader">
      <span class="text-medium-normal">
        <span class="text-medium-bold">{{ friendName }}</span>
        <ng-container *ngIf="(friendBookings$ | async)?.length > 0; else emptyBooking">
          {{ 'bookASeat.friendBookedLocation' | amdocsTranslate }}
        </ng-container>
        <ng-template #emptyBooking>
          <ng-container>
           <span class="text-left"> {{ ' ' + ('bookASeat.friendHasNotBooked' | amdocsTranslate) }}</span>
          </ng-container>
        </ng-template>
      </span>
</div>
<ng-container *ngIf="(friendBookings$ | async)?.length === 0" class="favorites-wrapper">
  <div *ngIf="friendDefaultLocation as favFriendSeat" class="favorites text-small-normal text-left with-padding">
    <div class="title"> {{ ('bookASeat.defaultLocation' | amdocsTranslate) }}</div>
    <div *ngIf="friendDefaultLocation">
      <ng-container *ngIf="favFriendSeat.country">
        {{ favFriendSeat.country.name + ',' }}
      </ng-container>
      <ng-container *ngIf="favFriendSeat.building">
        {{ favFriendSeat.building.name }}
      </ng-container>
      <ng-container *ngIf="favFriendSeat.floor">
        {{ ', Floor ' + favFriendSeat.floor.name }}
      </ng-container>
      <div class="btn navigate-btn" (click)="goToFriendDefaultLocation()">
        <img lib-amdocs-image-version src="/assets/icons/book-a-seat-mobile/Go-to-map.svg" alt="go to map seat location">
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(friendBookings$ | async) as bookings">
  <ng-container *ngFor="let bookedSeat of bookings">
    <app-faf-seat-card  [bookedSeat]="bookedSeat"
                        (showOnMapLocation)="goToMapWithFriendBooking($event)">
    </app-faf-seat-card>
  </ng-container>

</ng-container>
