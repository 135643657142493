<div class="book-a-seat-popup">
  <div class="header">
    <img lib-amdocs-image-version class="header-img" src="assets/icons/Illustration.png">
    <div class="title label-h4">
      {{seatName}} {{'client.seatManagement.seatIsBooked' | amdocsTranslate}}
    </div>
  </div>
  <div class="seat-details">
    <div *ngIf="bookingTime" class="booking-time text-small-bold text-left">
      <img lib-amdocs-image-version class="icon-date-time" src="/assets/icons/Date&Time-purple.svg"/>
      {{bookingTime.date | date:'EEEE d-MMM-y'}},
      {{bookingTime.from }} -
      {{bookingTime.to }}
    </div>
    <div *ngIf="userLocation" class="location text-small-normal text-left">
      <img lib-amdocs-image-version class="icon-location" src="/assets/icons/location-purple.svg"/>
      {{userLocation.building.name}},
      {{'client.seatManagement.floor' | amdocsTranslate}} {{userLocation.floor.name}}</div>
    <div class="close-btn">
      <button (click)="closePopup()"
              class="btn btn-small primary">{{'client.seatManagement.close' | amdocsTranslate}}</button>
    </div>
    <div role="button" (click)="viewDetails()" class="view-details btn btn-tiny white">
      <div class="text-small-normal font-weight-bold">
              {{'client.seatManagement.viewBookingDetails' | amdocsTranslate}}
      </div>
    </div>
  </div>
</div>
