import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CONSTANTS} from '../../../constants';
import {
  AmdocsAuthService,
  AmdocsEventBusService, AmdocsFunctionsService,
  AmdocsTranslateService,
  AmdocsUserService,
  IUserProfile
} from 'amdocs-core';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {ApiService} from '../../../core/api.service';
import {Observable, Subject} from 'rxjs';
import * as moment from 'moment';
import {AvatarImgSize} from '../avatar/avatar.component';
import {UserSettingsService} from "../../../core/user-settings.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-mobile-sidebar-menu',
  templateUrl: './mobile-sidebar-menu.component.html',
  styleUrls: ['./mobile-sidebar-menu.component.scss']
})
export class MobileSidebarMenuComponent implements OnInit, OnDestroy {

  public AvatarImgSize: AvatarImgSize = AvatarImgSize.medium;
  private destroyed$ = new Subject<void>();
  public upcomingNamed: string;
  public upcomingSeatsLength: number;
  public menuOpen = false;
  public menuItems = [
    {
      name: this.translate.getText('sideBar.home'),
      icon: '/assets/icons/nav-bar/Home.svg',
      action: () => {
        this.menuOpen = false;
        this.router.navigate([CONSTANTS.ROUTES.BOOK_A_SEAT]);
      }
    },
    {
      name: this.translate.getText('sideBar.upcoming'),
      icon: '/assets/icons/nav-bar/Bookings.svg',
      action: () => {
        this.router.navigate([CONSTANTS.ROUTES.MY_BOOKINGS, CONSTANTS.ROUTES.UPCOMING]);
      }
    },
    {
      name: this.translate.getText('sideBar.recent'),
      icon: '/assets/icons/nav-bar/Home.svg',
      action: () => {
        this.router.navigate([CONSTANTS.ROUTES.MY_BOOKINGS, CONSTANTS.ROUTES.RECENT]);
      }
    },
    {
      name: this.translate.getText('sideBar.reportFlexIssue'),
      icon: '/assets/icons/report-clic-issue-icon.svg',
      action: () => {
        const path = environment.reportAnIssueUrlMobile;
        this.functionService.navigateExternalUrl(path, '_self');
      }
    },
    {
      name: this.translate.getText('sideBar.log'),
      icon: '/assets/icons/nav-bar/Logout.svg',
      action: () => {
        this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
        this.amdocsAuthService.logout()
          .pipe(
            finalize(() => {
              sessionStorage.setItem('selectLocationClient', JSON.stringify(this.userSettingsService.userSettings.defaultLocation));
              this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
            }),
            takeUntil(this.destroyed$)
          ).subscribe();
      }
    }
  ];

  constructor(
    private router: Router,
    private userService: AmdocsUserService,
    private amdocsAuthService: AmdocsAuthService,
    private eventBus: AmdocsEventBusService,
    private translate: AmdocsTranslateService,
    private apiService: ApiService,
    private eventBusService: AmdocsEventBusService,
    private userSettingsService: UserSettingsService,
    private functionService: AmdocsFunctionsService
  ) {
  }

  ngOnInit(): void {
    this.initEvents();
    this.upcomingNamed = this.translate.getText('sideBar.upcoming');
    this.apiService.getMySeatsBooking().pipe(
      map(seats => {
        this.upcomingSeatsLength = seats.bookedForOthers.length + seats.bookedForMe.length;
      }),
      takeUntil(this.destroyed$)
    );
  }

  initEvents(): void{
    // this.eventBusService.on(CONSTANTS.EVENTS.UPDATE_SEATS_ICON_AFTER_BOOK_ACTION, () => {
    //   this.upcomingSeatsLength = + 1;
    // }, true);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get userProfile(): IUserProfile {
    return this.userService.user.userProfile;
  }

  public open(): void {
    this.menuOpen = true;
    // this.eventBus.emit(CONSTANTS.EVENTS.CLOSE_NEWS);
  }

  public close(): void {
    this.menuOpen = false;
  }
}
