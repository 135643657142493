import {IExtendedSeat, IRoom, ISeat} from './map';

export interface INeighborhood {
  name: string;
  code?: string;
  org2unit?: string;
  unit?: string;
  map?: string; // remove ???
  isReserved?: boolean;
  isCrossEntity: boolean;
  isCrossEntityExpirationDate?: number;
  reservedUsers: IReservedUser[];
  wingGroupCode?: string;
  unitCode?: string;
  rooms?: string[];
  seats?: string[];
  seatsCounter?: number;
  roomsAndSeats?: any[]; // IRoom[]|IExtendedSeat[];
}

export interface INeighborhoodUser {
  unitCode: string;
  neighborhoods: INeighborhood[];
}
export interface IReservedUser {
  code: string;
  name: string;
}

// export interface INewNeighborhood extends INeighborhood {
//   // roomsAndSeats?: string[];
// }

// export interface INeighborhoodDetails extends INeighborhood {
//   seats?: ISeat[];
//   rooms?: IRoom[];
// }
export const updateNeighborhood = `
  mutation UpdateNeighborhood($input: UpdateNeighborhoodInput!) {
    updateNeighborhood(input: $input)
  }
`;

export const deleteNeighborhood = `
  mutation DeleteNeighborhood($wingGroupCode: String!, $code: String!) {
  deleteNeighborhood(wingGroupCode: $wingGroupCode, code: $code)
}
`;

export const createNewNeighborhood = `
  mutation CreateNeighborhood($input: CreateNeighborhoodInput!) {
    createNeighborhood(input: $input) {
      code
    }
}`;

export const getNeighborhoodsAdminQuery = `
query GetNeighborhoodsAdmin($wingGroupCode: String!, $unitCode: String!) {
  neighborhoods(wingGroupCode: $wingGroupCode, unitCode: $unitCode) {
    code
    name
    org2unit
    unitCode
    isReserved
    isCrossEntity
    isCrossEntityExpirationDate
    reservedUsers {
      code
      name
    }
  }
}
`;

export const getNeighborhoodsAllocationQuery = `
query GetNeighborhoodsUser($wingGroupCode: String!) {
  neighborhoodsUser(wingGroupCode: $wingGroupCode) {
    unitCode
    neighborhoods {
      code
      name
    }
  }
}
`;

