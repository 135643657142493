import { Injectable } from '@angular/core';
import { AmdocsEventBusService, AmdocsTranslateService } from 'amdocs-core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERole, ESelectLocationMod, ESelectLocationSubMod, IMenuItem, ISelectLocationConfig } from '../models';
import { CONSTANTS } from '../constants';
import { NavUserMapService } from './nav-user-map.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private navUserMapService: NavUserMapService, private translateService: AmdocsTranslateService, private route: ActivatedRoute, private router: Router, private eventBus: AmdocsEventBusService) {
  }

  public menuItems: IMenuItem[] = [
    {
      id: this.translateService.getText('menuItems.home.id'),
      name: this.translateService.getText('menuItems.home.name'),
      roles: [ERole.facilityAdmin, ERole.itAdmin, ERole.unitAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/home-icon.svg',
      action: () => {
        this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE]);
      }
    },
    {
      id: this.translateService.getText('menuItems.mapMaintenance.id'),
      name: this.translateService.getText('menuItems.mapMaintenance.name'),
      roles: [ERole.itAdmin],
      icon: '/assets/icons/map-maintenance-icon.svg',
      action: () => {
        if (!this.navUserMapService.isAdmin) {
          const queryParams = {
            subMod: ESelectLocationSubMod.mapMaintenance
          };
          this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE], { queryParams });
        } else {
          const config: ISelectLocationConfig = {
            mod: ESelectLocationMod.admin,
            subMod: ESelectLocationSubMod.mapMaintenance
          };
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_SELECT_LOCATION_POPUP, config);
        }
      }
    },
    {
      id: this.translateService.getText('menuItems.neighborhoodAllocations.id'),
      name: this.translateService.getText('menuItems.neighborhoodAllocations.name'),
      roles: [ERole.itAdmin, ERole.unitAdmin, ERole.facilityAdmin],
      icon: '/assets/icons/neighborhood-allocations-icon.svg',
      action: () => {
        if (!this.navUserMapService.isAdmin) {
          const queryParams = {
            subMod: ESelectLocationSubMod.neighborhoodAllocation
          };
          this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE], { queryParams });
        } else {
          const config: ISelectLocationConfig = {
            mod: ESelectLocationMod.admin,
            subMod: ESelectLocationSubMod.neighborhoodAllocation
          };
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_SELECT_LOCATION_POPUP, config);
        }
      }
    },
    {
      id: this.translateService.getText('menuItems.unitAllocations.id'),
      name: this.translateService.getText('menuItems.unitAllocations.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/unit-allocations-icon.svg',
      action: () => {
        if (!this.navUserMapService.isAdmin) {
          const queryParams = {
            subMod: ESelectLocationSubMod.unitsAllocation
          };
          this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE], { queryParams });
        } else {
          const config: ISelectLocationConfig = {
            mod: ESelectLocationMod.admin,
            subMod: ESelectLocationSubMod.unitsAllocation
          };
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_SELECT_LOCATION_POPUP, config);
        }
      }
    },
    {
      id: this.translateService.getText('menuItems.siteParameters.id'),
      name: this.translateService.getText('menuItems.siteParameters.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin],
      icon: '/assets/icons/site-parameters-icon.svg',
      action: () => {
        this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE, CONSTANTS.ROUTES.ADMIN.CHILDREN.SITE_PARAMETERS]);
      }
    },
    {
      id: this.translateService.getText('menuItems.amenitiesManagement.id'),
      name: this.translateService.getText('menuItems.amenitiesManagement.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/amenities-management-icon.svg',
      action: () => {
        this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE, CONSTANTS.ROUTES.ADMIN.CHILDREN.AMENITIES_MANAGEMENT]);
      }
    },
    {
      id: this.translateService.getText('menuItems.reports.id'),
      name: this.translateService.getText('menuItems.reports.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.unitAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/reports-icon.svg',
      action: () => {
        //this.router.navigate([CONSTANTS.ROUTES.USER_MAP]);
        window.open('https://app.powerbi.com/groups/me/apps/a9a45472-96d0-4062-84fb-30c1dcdf8dfd/reports/69daf43d-df81-4b53-a15e-9e49968f3134/ReportSectione749003ebae51abf2453?experience=power-bi', "_blank")
      }
    },
    {
      id: this.translateService.getText('menuItems.adminTicket.id'),
      name: this.translateService.getText('menuItems.adminTicket.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.unitAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/reports-icon.svg',
      action: () => {
        window.open(environment.adminTicketUrl, "_blank")
      }
    },
    // {
    //   id: this.translateService.getText('menuItems.employeeBookASeat.id'),
    //   name: this.translateService.getText('menuItems.employeeBookASeat.name'),
    //   roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.unitAdmin, ERole.itSupportAdmin],
    //   icon: '/assets/icons/employee-book-Seat-icon.svg',
    //   action: () => {
    //     this.router.navigate([CONSTANTS.ROUTES.USER_MAP]);
    //   }
    // },  
    {
      id: this.translateService.getText('menuItems.qrGeneration.id'),
      name: this.translateService.getText('menuItems.qrGeneration.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/qr-generation.svg',
      action: () => {
        if (!this.navUserMapService.isAdmin) {
          const queryParams = {
            subMod: ESelectLocationSubMod.qrGeneration,
          };
          this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE], { queryParams });
        } else {
          const config: ISelectLocationConfig = {
            mod: ESelectLocationMod.admin,
            subMod: ESelectLocationSubMod.qrGeneration
          };
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_SELECT_LOCATION_POPUP, config);
        }
      }
    },
    {
      id: this.translateService.getText('menuItems.qrDownload.id'),
      name: this.translateService.getText('menuItems.qrDownload.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/bookaSeat.svg',
      action: () => {
        if (!this.navUserMapService.isAdmin) {
          const queryParams = {
            subMod: ESelectLocationSubMod.qrDownload,
          };
          this.router.navigate([CONSTANTS.ROUTES.ADMIN.BASE], { queryParams });
        } else {
          const config: ISelectLocationConfig = {
            mod: ESelectLocationMod.admin,
            subMod: ESelectLocationSubMod.qrDownload
          };
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_SELECT_LOCATION_POPUP, config);
        }
      }
    },
     {
      id: this.translateService.getText('menuItems.bookaSeat.id'),
      name: this.translateService.getText('menuItems.bookaSeat.name'),
      roles: [ERole.itAdmin, ERole.facilityAdmin, ERole.itSupportAdmin],
      icon: '/assets/icons/seat-booking.svg',
      action: () => {
      
          this.router.navigate([CONSTANTS.ROUTES.USER_MAP]);
        }
      
    }

    // {
    //   id: 'test',
    //   name: 'test',
    //   roles: [ERole.itAdmin],
    //   icon: '/assets/icons/map-maintenance-icon.svg',
    //   action: () => {
    //     const config: ISelectLocationConfig = {
    //       mod: ESelectLocationMod.client,
    //       subMod: ESelectLocationSubMod.neighborhoodAllocation
    //     };
    //     this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_SELECT_LOCATION_POPUP, config);
    //   }
    // },
  ];

  public getHomeUrl(): string {
    if(this.router.url=='/')
      return environment.clicBaseUrl;
    else
     return CONSTANTS.ROUTES.HOME;

  }
}
