import * as L from 'leaflet';
import {ISeatMap} from './map';

export class DataMarker extends L.Marker {
    data: ISeatMap;

    constructor(latLng: L.LatLngExpression, data: ISeatMap, options?: L.MarkerOptions) {
        super(latLng, options);
        const seatMapData: ISeatMap = Object.assign({}, data, {selected: false});
        this.setData(seatMapData);
    }

    getData(): any {
        return this.data;
    }

    setData(data: ISeatMap): void {
        this.data = data;
    }
}
