<div  class="legend-wrapper">
  <div class="legend-header d-flex align-items-center text-small-bold" (click)="toggleLegend()">
    {{'client.userMap.legend.title' | amdocsTranslate}}
    <img lib-amdocs-image-version class="legend-header-icon" [ngClass]="{'down': showLegendList}" src="assets/icons/arrow-down.svg">
  </div>
  <div *ngIf="showLegendList" class="legend-items">
    <div class="legend-item d-inline-flex align-items-center {{legend.fullWidth ? 'w-100' : 'w-50'}}"  *ngFor="let legend of legendItems">
      <div class="map-icon {{legend.className}}"></div>
      <div class="text-small-normal">{{legend.text | amdocsTranslate}}</div>
    </div>
  </div>
</div>
