import {Component, Input, OnInit} from '@angular/core';
import {ILegendItem} from '../../../models';

@Component({
  selector: 'app-desktop-footer-seats-legend',
  templateUrl: './desktop-footer-seats-legend.component.html',
  styleUrls: ['./desktop-footer-seats-legend.component.scss']
})
export class DesktopFooterSeatsLegendComponent implements OnInit {

  @Input() legendItems: ILegendItem[] = [];
  constructor() { }

  ngOnInit(): void {
    this.legendItems = this.legendItems.sort((a, b) => a.desktopOrder > b.desktopOrder ? 1 : -1);
  }

}
