import {IWhen} from './user-map';
import {ICodeNameItem} from './map';
import {ILocation} from './location';

// export interface IFilterWhereDEP {
//   country: string; // todo:: ICodeNameItem
//   building: string; // todo:: ICodeNameItem
//   floor: string; // todo:: ICodeNameItem
//   unit?: string; // todo:: ICodeNameItem
//   neighborhood?: string; // todo:: ICodeNameItem
//   wingGroup: string; // todo:: ICodeNameItem
// }

export class SelectedMapFiltersDetails {
  selectLocationClient: ILocation = {} as ILocation;
  when: IWhen = {} as IWhen;
  amenities: ICodeNameItem[] = [];
  user: ICodeNameItem = {} as ICodeNameItem;
}
