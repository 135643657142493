import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AmdocsUtilsModule} from 'amdocs-core';
import {ApiService} from './api.service';
import {PwaService} from './pwa.service';
import {RolesService} from './roles.service';
import {FoundationService} from './foundation.service';
import {MenuService} from './menu.service';
import {MapService} from './map.service';
import {MapState} from './map-state';
import {FunctionsService} from './functions.service';
import {SelectedMapFiltersDetailsService} from './selected-map-filters-details.service';
import {MobileOnlyGuardService} from './guards/mobile-only.guard.service';
import {DesktopOnlyGuardService} from './guards/desktop-only.guard.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AmdocsUtilsModule
  ],
  providers: [
    ApiService,
    PwaService,
    RolesService,
    FoundationService,
    MenuService,
    MapService,
    MapState,
    FunctionsService,
    SelectedMapFiltersDetailsService,
    MobileOnlyGuardService,
    DesktopOnlyGuardService
  ]
})
export class CoreModule { }
