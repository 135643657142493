import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import {Browser, LatLngBoundsExpression} from 'leaflet';
import {AmdocsDeviceService, AmdocsEventBusService, AmdocsFunctionsService} from 'amdocs-core';
import {IExtendedSeat, IRoom, ISeatMap, IWing} from '../../../models';
import {DataMarker, FocusBtn} from '../../../models';
import {LeafletEvent} from 'leaflet';
import {CONSTANTS} from '../../../constants';

@Component({
  selector: 'app-leaf-map',
  templateUrl: './leaf-map.component.html',
  styleUrls: ['./leaf-map.component.scss']
})
export class LeafMapComponent implements AfterViewInit, OnChanges, OnDestroy {

  constructor(private eventBusService: AmdocsEventBusService, private functionsService: AmdocsFunctionsService, private deviceService: AmdocsDeviceService) {
  }

  public map: L.DrawMap;
  @Input()  seats: ISeatMap[] = [];
  @Input()  selectedSeat: ISeatMap;
  @Input() mapPath: string;
  @Input() canDragMarkers = false;
  @Input() showRoomTooltip = false;
  @Input() showSeatTooltip = false;
  @Input() boundWidth: number = 1557;
  @Input() wings: IWing[] = [];
  @Input() boundHeight: number = 858;
  @Output() changeMarkerCallback: EventEmitter<ISeatMap> = new EventEmitter();
  @Output() mapClicked: EventEmitter<any> = new EventEmitter();
  @Output() markerAdded: EventEmitter<ISeatMap> = new EventEmitter();
  @Output() markerClick: EventEmitter<ISeatMap> = new EventEmitter();

  private initMapCenter: any;
  public imageBounds: LatLngBoundsExpression = [];
  markerDrawer: L.Draw.Marker;
  public mapId: string = this.functionsService.createGuid();

  circleIcon = L.divIcon({
    iconSize:     [18, 18], // size of the icon
    iconAnchor: [0, 0],
    className: 'map-icon admin unallocated',
    html: '<div></div>'
  });

  circleIconSelected = L.divIcon({
    iconSize:     [18, 18], // size of the icon
    iconAnchor: [0, 0],
    className: 'map-icon admin selected',
    html: '<div></div>'
  });

  ngAfterViewInit(): void {
    this.imageBounds = [[0, 0], [this.boundHeight, this.boundWidth]];
    console.log('this.imageBounds', this.imageBounds); //
    this.map = L.map(this.mapId, {crs: L.CRS.Simple, minZoom: -50, maxZoom: 20, zoomSnap: 0.25, zoomDelta: 0.25});
    this.map.zoomControl.setPosition('bottomright');
    L.imageOverlay(this.mapPath, this.imageBounds).addTo(this.map);
    this.map.fitBounds(this.imageBounds);
    this.map.setZoom(1);
    if (this.deviceService.isMobile()){
      this.map.zoomControl.remove();
    }
    this.addMapListeners();
    if (this.canDragMarkers) {
      this.addDrawOptions();
    }
    this.initSeats();
    this.addControl();
    this.initMapCenter = this.map.getCenter();


    if (this.wings.length) {
      this.wings.forEach((wing: IWing) => {
        const bounds: any = [[wing.Ymin, wing.Xmin], [wing.Ymax, wing.Xmax]];
        L.rectangle(bounds, {color: '#4C4E81', weight: 1, fillOpacity: 0}).addTo(this.map);
      });
    }

    // // this.rerenderSeats();
    // setTimeout(() => {
    //   this.map.invalidateSize();
    // }, 100);
    this.eventBusService.on(CONSTANTS.EVENTS.MAP_FLY_TO, (wing: IWing) => {
      console.log('flying map to ', wing);
      if (wing.Xmax && wing.Ymax && wing.Xmin && wing.Ymin) {
        this.map.flyToBounds([
          [wing.Ymin, wing.Xmin],
          [wing.Ymax, wing.Xmax]
        ]);
      } else {
        this.map.setView(this.initMapCenter, 1);
      }
    }, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSeat?.currentValue && !changes?.selectedSeat?.firstChange) {
      this.onSelectSeat();
    }
  }



  // CLICKING ON THE MAP
  addMapListeners(): void {
    if (this.map) {
      this.map.on('click', (ev: any) => {
        console.log('leaflet map clicked');
        if (this.canDragMarkers) {
          this.removeSeatsSelected();
        }
        this.mapClicked.emit();
      });
    }
  }

  // unselect selected seat
  removeSeatsSelected(): void {
    this.map.eachLayer( (layer) => {
      if (layer instanceof  L.Marker) {
        layer.dragging.disable();
        layer.setIcon(this.circleIcon);
      }
    });
  }

  findSelectedSeat(code: string): L.Marker {
    let seat: L.Marker = null;
    this.map.eachLayer( (layer) => {
      if (layer instanceof  L.Marker && (layer as DataMarker)?.data?.code === code ) {
        seat = layer;
      }
    });
    return seat;
  }

  addDrawOptions(): void {
    L.Draw.Marker = L.Draw.Marker.extend({
      options : {
        icon: new L.DivIcon({
          iconSize:     [18, 18], // size of the icon
          iconAnchor: [10, 10],
          className: 'map-icon admin selected',
          html: '<div></div>'
        })
      }
    });
    L.drawLocal.draw.handlers.marker.tooltip.start = 'Add marker';
    this.map.on('draw:created', (e: any) => {
      console.log('marker created into map');
      switch (e.layerType) {
        case 'marker':
          this.onSeatCreated(e.layer);
      }
    });
  }

  addControl(): void {
      new FocusBtn({ position: 'bottomright'}).addTo(this.map);
  }

  initSeats(): void {
    this.seats.forEach((seat: ISeatMap) => {
      this.addSeatToMap(seat);
    });
  }

  addSeatToMap(seat: ISeatMap, drawSeat?: boolean): void  {
    if (!(seat.X || seat.Y)) {
      return;
    }
    const seatMarkerCoordinates = L.latLng(seat.Y, seat.X);
    const seatMarkerOptions = {
      icon: L.divIcon({
        iconSize:     [18, 18], // size of the icon
        iconAnchor: [0, 0],
        className: seat.classNames,
        html: '<div></div>'
      })
    };
    const seatMarker = new DataMarker(seatMarkerCoordinates, seat, seatMarkerOptions);
    // seat.marker = seatMarker;
    seatMarker.addTo(this.map);
    if (this.showRoomTooltip) {
      let content: string;
      if ((seatMarker.data as IRoom).seats && (seatMarker.data as IRoom).seats.length) {
        content = (seatMarker.data as IRoom).name + ' - ' + (seatMarker.data as IRoom).seats.map(s => s.name).join(', ');
      } else {
        content = seatMarker.data.code;
      }
      seatMarker.bindPopup(content);
      seatMarker.on('mouseover', () => {
        seatMarker.openPopup();
      });
      seatMarker.on('mouseout', () => {
        seatMarker.closePopup();
      });
    }

    if (this.showSeatTooltip && !(seatMarker.data as IRoom).seats){
      seatMarker.bindPopup((seatMarker.data as IRoom | IExtendedSeat).name);
      seatMarker.on('mouseover', () => {
        seatMarker.openPopup();
      });
      seatMarker.on('mouseout', () => {
        seatMarker.closePopup();
      });
    }

    seatMarker.on('click', (e: LeafletEvent) => {
      console.log('marker clicked');
      if (this.canDragMarkers) {
        e.target.dragging.enable();
        e.target.setIcon(this.circleIconSelected);
      }
      this.markerClick.emit((e.target as DataMarker).data);
    });
    seatMarker.on('dragend', (e: LeafletEvent) => {
      console.log('drag end');
      const latLng = (e.target as DataMarker).getLatLng();
      (e.target as DataMarker).data.X = latLng.lng;
      (e.target as DataMarker).data.Y = latLng.lat;
      this.changeMarkerCallback.emit((e.target as DataMarker).data);

    });
    if (drawSeat) {
      seatMarker.setIcon(this.circleIcon);
      this.markerAdded.emit(seatMarker.data);
    }
  }

  onSelectSeat(): void {
    this.removeSeatsSelected();
    if (!(this.selectedSeat.Y || this.selectedSeat.X)) {
      this.disablePreviousDrawer();
      this.markerDrawer = new L.Draw.Marker(this.map);
      this.markerDrawer.enable();
    } else {
      const selectedSeat = this.findSelectedSeat(this.selectedSeat?.code);
      if (selectedSeat) {
        if (this.canDragMarkers) {
          selectedSeat.dragging.enable();
        }
        selectedSeat.setIcon(this.circleIconSelected);
      }
      this.disablePreviousDrawer();
    }
  }

  disablePreviousDrawer(): void {
    if (this.markerDrawer) {
      this.markerDrawer.disable();
    }
  }

  public changeSeatMarkerLocation(seat: ISeatMap): void {
    const newLatLng = new L.LatLng(seat.Y, seat.X);
    this.map.eachLayer( (marker) => {
      if (marker instanceof  L.Marker) {
        const dataMarker = marker as DataMarker;
        if (dataMarker.data.code === seat.code) {
          marker.setLatLng(newLatLng);
        }
      }
    });
  }

  public rerenderSeats(): void {
    // RUN ON ALL SEATS / MARKERS AND SET THE RELEVANT CLASS BY THE CLASSNAME IN THE SEATMAP OBJECT
    this.map.eachLayer( (marker) => {
      if (marker instanceof  L.Marker) {
        const dataMarker = marker as DataMarker;
        const seat = this.seats.find(tmpSeat => tmpSeat.code === dataMarker.data.code);
        const icon = L.divIcon({
          iconSize:     [18, 18], // size of the icon
          iconAnchor: [0, 0],
          className: seat.classNames,
          html: '<div></div>'
        });
        marker.setIcon(icon);
      }
    });
    // this.seats.forEach( (seat) => {
    //       const icon = L.divIcon({
    //         iconSize:     [18, 18], // size of the icon
    //         iconAnchor: [0, 0],
    //         className: seat.classNames,
    //         html: '<div></div>'
    //       });
    //       seat?.marker?.setIcon(icon);
    // });
  }

  ngOnDestroy(): void {
    this.eventBusService.off(CONSTANTS.EVENTS.MAP_FLY_TO, true);
  }

  onSeatCreated(layer: any): void {
    const markerCoordinates = layer.getLatLng();
    this.selectedSeat.X = markerCoordinates.lng;
    this.selectedSeat.Y = markerCoordinates.lat;
    this.addSeatToMap(this.selectedSeat, true);
  }

}
