import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AmdocsAppsyncClientService, AmdocsUserService } from 'amdocs-core';
import { ISearchEmployee, searchEmployeeQuery } from '../models/search-employees';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSearchService {

  constructor(
    private userService: AmdocsUserService,
    private appSyncClient: AmdocsAppsyncClientService
  ) { }

  public searchEmployee(searchString, top, skiptoken, excludeCurrentUser: boolean): Observable<ISearchEmployee> {
    const params = {
      input: {
        searchString,
        top,
        skiptoken,
        excludeCurrentUser
      }
    };
    return this.appSyncClient.call(searchEmployeeQuery, 'SearchEmployee', params).pipe(
      mergeMap( (res: {searchEmployee: ISearchEmployee}): Observable<ISearchEmployee> => {
        return of(res.searchEmployee);
      }),
      catchError( err => {
        return of({error: err});
      })
    );
  }
}
