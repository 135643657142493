<div class="select-when-wrapper d-flex flex-column">
  <div class="date-and-times-wrapper d-flex align-items-start justify-content-between">
    <div class="date-wrapper">
      <lib-amdocs-datepicker *ngIf="currentDate && datepickerConfig"
                             [inlineView]="true"
                             [config]="datepickerConfig"
                             [initialValue]="currentDate"
                             [minDate]="minDate"
                             [maxDate]="maxDate"
                             (dateChange)="onDateChanged($event)"></lib-amdocs-datepicker>
    </div>
    <div class="from-to-wrapper">
      <div class="text-medium-bold from-to-title">Time Range</div>
      <div class="dropdowns-wrapper d-flex align-items-center justify-content-between">
        <div class="from-wrapper rang-wrapper">
          <lib-amdocs-dropdown (onChangedCallback)="onChangedTime()" [elementId]="'when-from'" [control]="fromCtrl"
                               [items]="fromItems"></lib-amdocs-dropdown>
        </div>
        <div class="range-divider"> to</div>
        <div class="to-wrapper rang-wrapper">
          <lib-amdocs-dropdown (onChangedCallback)="onChangedTime()" [elementId]="'when-to'" [control]="toCtrl"
                               [items]="toItems"></lib-amdocs-dropdown>
        </div>
      </div>
<!--      <div *ngIf="invalidFromTo" class="custom-input-field-error">-->
<!--        <img lib-amdocs-image-version src="/assets/icons/error.svg">-->
<!--        <span class="text-small-normal">{{'errors.fromBiggerThenTo' | amdocsTranslate}}</span>-->
<!--      </div>-->
      <div *ngIf="invalidFromTo" class="d-flex attention-wrapper">
        <img lib-amdocs-image-version class="attention" src="/assets/icons/attention.svg">
        <div class="text-small-normal">
          <span class="text-tiny-normal">{{'errors.fromBiggerThenTo' | amdocsTranslate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-wrapper">
    <div *ngIf="!defaultTimeRange || hasChanges" class="w-100 set-as-default-wrapper">
      <lib-amdocs-checkbox [elementId]="'set-as-default'" [control]="setAsDefaultCtrl">
        <span class="text-small-normal">Set as my default time range</span>
      </lib-amdocs-checkbox>
    </div>
    <div class="buttons">
      <button class="w-50 btn btn-medium secondary cancel-btn" (click)="cancel($event)">
        {{'common.cancel' | amdocsTranslate}}
      </button>
      <button class="w-50 btn btn-medium primary save-btn" (click)="submit($event)">
        {{'common.update' | amdocsTranslate}}
      </button>
    </div>

  </div>
</div>
