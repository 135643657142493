import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {
  AmdocsAppInitService, AmdocsAuthService,
  AmdocsDeviceService,
  AmdocsEventBusService,
  AmdocsPwaService,
  AmdocsUserNotifyAlertComponent, EToasterType
} from 'amdocs-core';
import {environment} from '../environments/environment';
import {CONSTANTS} from './constants';
import {PwaService} from './core/pwa.service';
import {MapState} from './core/map-state';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('userNotifyAlert') userNotifyAlert: AmdocsUserNotifyAlertComponent;

  public allowMimic = environment.allowMimic;
  public showLoaderCount = 0;
  public showToaster = false;
  public toasterType: EToasterType;
  public toasterTitle = '';
  public toasterMessage = '';
  public toasterTimeout: number;
  public qrCodeData:any
  public seatId:any
  public method:any
  public wingGroupCode:any
  public currentTime: Date = new Date();
  public fromTime: string;
  public toTime: any;
  public todayDateFrom:string
  public endDate:string
  public tomorowDate:string
  public redirectLink:string
  public hrefLink:string
  public qrScanLink:string
  public keyValuePairs:any
  public type:any

  @HostListener('window:visibilitychange', ['$event'])
  visibilityChange(event): void {
    if (document.visibilityState === CONSTANTS.DOCUMENT_VISIBILITY_STATE_VISIBLE) {
      if (!this.amdocsAuthService.isLoggedIn()) {
        // window.location.reload();
      }
    }
  }

  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHandler(event): any {
  //   if (!this.mapState.isEdited) {
  //     return null;
  //   }
  //   const confirmationMessage = '';
  //   (event || ((window as any).event)).returnValue = '';
  //   return confirmationMessage;
  // }

  constructor(private appInitService: AmdocsAppInitService,
              private pwaService: PwaService,
              private mapState: MapState,
              private amdocsAuthService: AmdocsAuthService,
              private amdocsPwaService: AmdocsPwaService,
              private eventBusService: AmdocsEventBusService,
              private deviceService: AmdocsDeviceService,private datePipe: DatePipe,private router: Router) {

  }

  ngOnInit(): void {

    if (this.amdocsAuthService.isLoggedIn()) {
      if ('serviceWorker' in navigator) {
        // if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('/ngsw-worker.js');
      }
    }
    // if (!(this.deviceService.isIos() && this.deviceService.isSafari() && !this.deviceService.isChromeIOS() && this.amdocsPwaService.isPwa())) {
    //   if ('serviceWorker' in navigator && environment.production) {
    //     navigator.serviceWorker.register('/ngsw-worker.js');
    //   }
    // }


    this.eventBusService.on(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, (showLoader) => {
      if (showLoader) {
        this.showLoaderCount++;
      } else {
        if (this.showLoaderCount > 0) {
          this.showLoaderCount--;
        }
      }
    }, true);

    this.eventBusService.on(CONSTANTS.EVENTS.NOTIFY_USER_ALERT, (data: {text: string}) => {
      this.userNotifyAlert.showMessage(data.text);
    }, true);

    this.eventBusService.on(CONSTANTS.EVENTS.SHOW_CANCEL_BOOKING_MSG, (toasterData: {title: string, message?: string, timeout?: number, toasterType?: EToasterType}) => {
      this.toasterTitle = toasterData.title;
      this.toasterType = toasterData.toasterType || EToasterType.success;
      if (toasterData.message) {
        this.toasterMessage = toasterData.message;
      }
      if (toasterData.timeout) {
        this.toasterTimeout = toasterData.timeout;
      }
      this.showToaster = true;
    }, true);



    this.qrScanLink = sessionStorage.getItem('orig-path');
    this.type = this.qrScanLink;
     this.hrefLink = window.location.href;
      // this.hrefLink ='https://localhost:4200/?Method=Clic'
    const urlSearchParams = new URLSearchParams(this.hrefLink.split('?')[1]);
    const method = urlSearchParams.get('Method');
    console.log("this.type",this.type)
    console.log(" this.hrefLink ", this.hrefLink )
    console.log("methoddd",method)
    if(method && !this.hrefLink.includes('wingGroupCode'))
    {
      sessionStorage.setItem('method', method);
    }
    else if(this.type){
      const urlSearchParams = new URLSearchParams( this.type?.split('?')[1]);
      const method = urlSearchParams.get('Method');
      console.log("method thistype",method)
      if(method){
        sessionStorage.setItem('method', method);
      }

    }
     if(this.hrefLink && this.hrefLink?.includes('wingGroupCode') && this.hrefLink?.includes('seatId') || this.qrScanLink?.includes('wingGroupCode'))
     {

       if(this.hrefLink.includes('wingGroupCode')){
        this.keyValuePairs = this.hrefLink.split(/[&/]/);
       }
       else if(this.qrScanLink.includes('wingGroupCode')){
        this.keyValuePairs = this.qrScanLink.split(/[&/]/);
       }

       this.keyValuePairs.forEach(pair => {
         const [key, value] = pair.split('=');

         if (key) {
           if (key === 'wingGroupCode') {
             this.wingGroupCode = value !== undefined ? value : null;
           } else if (key === 'seatId') {
             this.seatId = value !== undefined ? value : null;
           }
           else if (key === 'Method') {
            this.method = value !== undefined ? value : null;
          }
         }
       });
       console.log(" this.method", this.method)
       sessionStorage.setItem('method', this.method);
       // Logging the results
    if (this.wingGroupCode && this.seatId) {


      const todaydate = new Date(this.currentTime).toLocaleString('en-US');

      const  date = new Date();
      this.todayDateFrom= this.datePipe.transform(date,"yyyy-MM-dd")
      this.fromTime = this.currentTimeFrom(this.currentTime);
      this.currentTimeToDate(this.fromTime);
      const dateTime = new Date(this.tomorowDate);

      this.endDate= this.datePipe.transform(dateTime,"yyyy-MM-dd")
      const todayDate = new Date(todaydate);
      const tomorrowDate  = new Date(this.tomorowDate);


      // Compare the dates
      if (todayDate.toDateString() === tomorrowDate.toDateString()) {
        // this.redirectLink = `${url}?date=${this.todayDateFrom}&from=${this.fromTime}&to=${this.toTime}&wingGroupCode=${this.wingGroupCode}&amenities=&seatId=${this.seatId}`;
        this.redirectLink = `/user-map?date=${this.todayDateFrom}&from=${this.fromTime}&to=${this.toTime}&wingGroupCode=${this.wingGroupCode}&amenities=&seatId=${this.seatId}`;
        this.router.navigateByUrl(this.redirectLink);
        // window.open(this.redirectLink,'_self')

      } else {
         // Dates are not equal
        // this.redirectLink = `${url}?date=${this.todayDateFrom}&endDate=${this.endDate}&from=${this.fromTime}&to=${this.toTime}&wingGroupCode=${this.wingGroupCode}&amenities=&seatId=${this.seatId}`;
        this.redirectLink = `/user-map?date=${this.todayDateFrom}&endDate=${this.endDate}&from=${this.fromTime}&to=${this.toTime}&wingGroupCode=${this.wingGroupCode}&amenities=&seatId=${this.seatId}`;
        // window.open(this.redirectLink,'_self')
        this.router.navigateByUrl(this.redirectLink);


     }
    }
  }

  }

  get appFinishedLoaded(): boolean {
    return this.appInitService.appFinishedLoaded;
  }

  get appError(): boolean {
    return this.appInitService.appInitError !== null && this.appInitService.appInitError !== undefined;
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  currentTimeFrom(date: Date): string {
    const roundedMinutes = Math.ceil(date.getMinutes() / 30) * 30;
    const roundedTime = new Date(date);
    roundedTime.setMinutes(roundedMinutes);

    return this.datePipe.transform(roundedTime, 'HH:mm', 'en-US')!;
  }

  currentTimeToDate(time: string): void {
    const timeParts = time.split(':');
    let hours = parseInt(timeParts[0], 10) + 9;
    const minutes = parseInt(timeParts[1], 10);

    const dateTimeWith9Hours = new Date();
    dateTimeWith9Hours.setHours(hours);
    dateTimeWith9Hours.setMinutes(minutes);

    this.tomorowDate = this.datePipe.transform(dateTimeWith9Hours, 'short', 'en-US')!;
    this.toTime = this.datePipe.transform(dateTimeWith9Hours, 'HH:mm', 'en-US')!;

  }
}
