import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AmdocsDeviceService, AmdocsEventBusService, AmdocsTimeService, ApiError, DropdownItem} from 'amdocs-core';
import {FormControl} from '@angular/forms';
import {EWhenDays, IWhen} from '../../../models/user-map';
import {SelectedMapFiltersDetailsService} from '../../../core/selected-map-filters-details.service';
import {IDefaultTimeRange, ISiteParametersRegions, SelectedMapFiltersDetails} from '../../../models';
import {CONSTANTS} from '../../../constants';
import {catchError, finalize, first, map, mergeMap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {ApiService} from '../../../core/api.service';
import {FoundationService} from '../../../core/foundation.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-select-when',
  templateUrl: './select-when.component.html',
  styleUrls: ['./select-when.component.scss']
})

export class SelectWhenComponent implements OnInit {

  private firstDateChange = true;
  public hasChanges = false;
  public amdocsDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public datepickerConfig: any;
  public storageKey = 'filter-when';
  public fromItems: DropdownItem[] = [];
  public fromCtrl: FormControl = new FormControl();
  public invalidFromTo = false;
  public toItems: DropdownItem[] = [];
  public toCtrl: FormControl = new FormControl();
  public currentDate: string;
  public setAsDefaultCtrl: FormControl = new FormControl();
  public minDate: string;
  public maxDate: string;
  @Input() when: IWhen;
  @Input() defaultTimeRange: IDefaultTimeRange;
  @Output() cancelCallback: EventEmitter<null> = new EventEmitter<null>();
  @Output() genericApiErrorCB = new EventEmitter<ApiError>();

  public currentTime: Date = new Date();

  constructor(private apiService: ApiService,
              private deviceService: AmdocsDeviceService,
              private foundationService: FoundationService,
              private timeService: AmdocsTimeService,
              private selectedMapFiltersDetailsService: SelectedMapFiltersDetailsService,
              private eventBusService: AmdocsEventBusService, private datePipe: DatePipe) {
  }


  ngOnInit(): void {
    this.selectedMapFiltersDetailsService.getSelectedMapFiltersDetails().pipe(
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error)
        return throwError(error);
      }),
    ).subscribe((filters: SelectedMapFiltersDetails) => {
      if (!this.when) {
        this.when = filters.when;
      }
      this.initDropdowns();
      this.fromCtrl.setValue(this.when.from);
      this.toCtrl.setValue(this.when.to);
      this.currentDate = this.when.date;
      this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
      this.apiService.loadSystemParameters().pipe(
        mergeMap((params: ISiteParametersRegions) => {
          const sessionRegion = JSON.parse(sessionStorage.getItem('selectLocationClient'))?.country?.region;
          const region = sessionRegion ? sessionRegion : this.foundationService.region.toString();
          const siteParam = params.systemParameters.find(p => p.regionCode === region);
          if (siteParam) {
            if (!this.fromCtrl.value) {
              this.fromCtrl.setValue(siteParam.defaultStartTime);
            }
            if (!this.toCtrl.value) {
              this.toCtrl.setValue(siteParam.defaultEndTime);
            }
            /*const allowedDays = [];
            const moment = this.timeService.moment();
            //this.minDate = this.timeService.moment().format('YYYY-MM-DD');
            this.minDate = moment.format('YYYY-MM-DD, h:mm:ss a');
            allowedDays.push(moment.day());
            for (let i = 1; i < siteParam.advBookingPeriod; i++) {
              moment.add(1, 'day');
            }
            if (filters.selectLocationClient?.country?.code === 'ISR') {
              if (moment.day() === EWhenDays.thursday) {
                moment.add(2, 'day');
              }
            } else {
              if (moment.day() === EWhenDays.friday) {
                moment.add(2, 'day');
              }
            }
            //this.maxDate = moment.format('YYYY-MM-DD');
            this.maxDate = moment.format('YYYY-MM-DD, h:mm:ss a');
            this.datepickerConfig = {};*/

            const allowedDays = [];
            const currentDate = new Date();
            this.minDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
            console.log("this.minDate", this.minDate);
            console.log("currentDate", currentDate);
            console.log("currentDate.getDay()", currentDate.getDay());
            allowedDays.push(currentDate.getDay());
            console.log("allowedDays", allowedDays);
            
            for (let i = 1; i < siteParam.advBookingPeriod; i++) {
              currentDate.setDate(currentDate.getDate() + 1);
              if (filters.selectLocationClient?.country?.code === 'ISR') {
                if (currentDate.getDay() === EWhenDays.friday) {
                  siteParam.advBookingPeriod++;
                }
                if (currentDate.getDay() === EWhenDays.saturday) {
                  siteParam.advBookingPeriod++;
                }
              } else {
                if (currentDate.getDay() === EWhenDays.saturday) {
                  siteParam.advBookingPeriod++;
                }
                if (currentDate.getDay() === EWhenDays.sunday) {
                  siteParam.advBookingPeriod++;
                }
              }

            }

            if (filters.selectLocationClient?.country?.code === 'ISR') {
              if (currentDate.getDay() === EWhenDays.thursday) {
                currentDate.setDate(currentDate.getDate() + 2);
              }
            } else {
              if (currentDate.getDay() === EWhenDays.friday) {
                currentDate.setDate(currentDate.getDate() + 2);
              }
            }

            this.maxDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
            console.log("maxDate", this.maxDate);
            
            this.datepickerConfig = {};

          } else {
            this.datepickerConfig = {};
          }
          this.onChangedTime();

          return of(params);
        }),
        catchError((error: ApiError) => {
          this.genericApiErrorCB.emit(error)
          return throwError(error);
        }),
        finalize(() => {
          this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        }),
        first()
      ).subscribe();
    });
  }

  onDateChanged(date: string): void {
    if (!this.firstDateChange) {
      this.hasChanges = true;
    }
    this.firstDateChange = false;
    this.currentDate = date;
  }

  onChangedTime(): void {
    this.hasChanges = true;

    this.invalidFromTo = false;
    if (this.fromCtrl.value > this.toCtrl.value) {
      this.invalidFromTo = true;
      return;
    }
  }

  initDropdowns(): void {
    const disabled = false;
    for (let i = 0; i < 24; i++) {
      const value = i < 10 ? '0' + i + ':00' : `${i}:00`;
      const key = value;
      this.fromItems.push({
        key, value, disabled
      });
      this.toItems.push({
        key, value, disabled
      });
    }
  }

  cancel(event): void {
    event.stopPropagation();
    this.cancelCallback.emit();
  }

  submit(event): void {
    // this.invalidFromTo = false;
    // if (this.fromCtrl.value > this.toCtrl.value) {
    //   this.invalidFromTo = true;
    //   return;
    // }
    this.saveDefault().pipe(
      map((res) => {
        this.setValuesInStorage();
        this.cancel(event);
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
    ).subscribe();
  }

  public setValuesInStorage(): void {
    const when: IWhen = {
      date: this.currentDate,
      from: this.fromCtrl.value,
      to: this.toCtrl.value
    };
    if (this.invalidFromTo) {
      when.endDate = this.timeService.moment(this.currentDate).add(1, 'day').format('YYYY-MM-DD');
    }
    this.selectedMapFiltersDetailsService.setWhenCache(when);
    if (!this.deviceService.isMobile()) {
      this.eventBusService.emit(CONSTANTS.EVENTS.USER_MAP_NAVIGATION, {when});
    }
  }

  public saveDefault(): Observable<boolean> {
    if (this.setAsDefaultCtrl.value) {
      const from = this.fromCtrl.value;
      const to = this.toCtrl.value;
      return this.apiService.setDefaultTimeRangeQuery(from, to);
    }
    return of(true);
  }

}
