import { ApiError } from 'amdocs-core';

export interface IEmployee {
  id: string;
  jobTitle: string;
  displayName: string;
  code?: string;
  employeeId?: string;
}
export interface ISearchEmployee {
  employees?: IEmployee[];
  nextToken?: string | null;
  error?: ApiError;
}

export const searchEmployeeQuery = `
  query SearchEmployee($input: SearchEmployeeInput!) {
    searchEmployee(input: $input) {
      employees {
        id
        jobTitle
        displayName
        employeeId
      }
      nextToken
    }
  }
`;
