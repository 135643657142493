export const misnpBaseURL = 'https://misnp.amdocs.com';
export const misBaseURL = 'https://mis.amdocs.com';
export const serviceNowUrl = 'https://amdocsuat.service-now.com';

export const baseEnvironment = {
  isRC: false,
  loginGWBaseURL: '',
  graphQlBaseURL: '/graphql',
  cnsBaseURL: '',
  imgUrl: '/sas/profilePicture',
  avatarsVersion: '1',
  startChatURL: 'https://amdocsuat.service-now.com/ta?id=ta_it_chat&origin=clic',
  serviceNowURL: 'https://amdocsuat.service-now.com/ta?origin=clic',
  clicBaseUrl: 'https://clic-dev.misnp.amdocs.com',
  reportAnIssueUrl: `${serviceNowUrl}/one_portal?id=aop_sc_cat_item&sys_id=5e4bcf6a87833c90b18875d8cebb35cf&category_sys_id=64b72181db7c6304396ce2a94b961933`,
  reportAnIssueUrlMobile: `${serviceNowUrl}/ta?id=ta_sc_cat_item&sys_id=5e4bcf6a87833c90b18875d8cebb35cf&origin=clic`,
  adminTicketUrl: `${serviceNowUrl}/one_portal?id=aop_sc_cat_item&sys_id=12bfb8ea87cbf5544f7762480cbb35a7`,
  refreshTokenInterval: 2400000,
  appId: 'bas-2'
};
