import { Component, EventEmitter, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { ESeatManagementMod } from '../../../feature/admin/seat-management/seat-management.service';
import { IEmployee, IExtendedSeat, ILocation, IOtherUserProfile } from '../../../models';
import {
  AmdocsDeviceService,
  AmdocsEventBusService, AmdocsTimeService, AmdocsTranslateService,
  AmdocsUserService, ApiError,
  IUserProfile
} from 'amdocs-core';
import { ESeatStatus, IMapBooking, IWhen } from '../../../models/user-map';
import { of, throwError } from 'rxjs';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../core/api.service';
import { CONSTANTS } from '../../../constants';
import { FormControl } from '@angular/forms';
import { EmployeeSearchBoxComponent } from '../employee-search-box/employee-search-box.component';
import { UserMapService } from '../../../feature/user-map/user-map.service';
import { SelectedMapFiltersDetailsService } from '../../../core/selected-map-filters-details.service';

import { chat } from "@microsoft/teams-js";

import {Router} from '@angular/router';

@Component({
  selector: 'app-client-seat-management',
  templateUrl: './client-seat-management.component.html',
  styleUrls: ['./client-seat-management.component.scss']
})
export class ClientSeatManagementComponent implements OnInit, OnChanges {


  @Input() selectedSeat: IExtendedSeat;
  @Input() selectedSeatBooking: IMapBooking;
  @Input() when: IWhen;
  @Input() wingGroupCode: string;
  @Output() roomSeatClickedEvent: EventEmitter<IExtendedSeat> = new EventEmitter<IExtendedSeat>();
  @Output() roomSelectedClickedEvent: EventEmitter<IExtendedSeat> = new EventEmitter<IExtendedSeat>();
  @Output() closeSeatViewEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() bookThisSeatEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelBookingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showOnMap: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() genericApiErrorCB = new EventEmitter<ApiError>();

  @ViewChild('employeeSearchBox') employeeSearchBoxComponent: EmployeeSearchBoxComponent;
  public viewMod: ESeatManagementMod;
  public ESeatManagementMod = ESeatManagementMod;
  public userLocation: ILocation;
  public myUser: IUserProfile;
  public showCancelBookingBtn: boolean = false;
  public showBookThisSeatBtn: boolean = false;
  public showCloseBtn: boolean = false;
  public isBookedByMe: boolean = false;
  public isBookedForMe: boolean = false;
  public currentSeatStatus: ESeatStatus;
  public ESeatStatus = ESeatStatus;
  public showSearchEmployee: boolean = false;
  public employeeSearchControl = new FormControl('');
  public bookForFriend: IEmployee = null;
  public isBookThisSeatBtnDisabled = false;
  public btnTopMessage: string;
  public showClearSearchBtn = false;
  public showActions = { left: false, right: false };
  public actionsSpanWidth = 0;
  public actionsWidth = 0;
  public saparetor = '-----';
  public chatIcon: boolean = false;
  public teamLink: string;
  constructor(
    private deviceService: AmdocsDeviceService,
    private userService: AmdocsUserService,
    private apiService: ApiService,
    private eventBusService: AmdocsEventBusService,
    private userMapService: UserMapService,
    private selectedMapFiltersDetailsService: SelectedMapFiltersDetailsService,
    private timeService: AmdocsTimeService,
    private router: Router,
    private translateService: AmdocsTranslateService) {
  }

  ngOnInit(): void {
    this.setUserLocation();
    this.setMyUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedSeat || changes?.selectedSeatBooking) {
      this.showCloseBtn = false;
      this.showBookThisSeatBtn = false;
      this.showCancelBookingBtn = false;
      this.showSearchEmployee = false;
      this.bookForFriend = null;
      this.isBookThisSeatBtnDisabled = false;
      this.btnTopMessage = null;
      this.setUserLocation();
      this.employeeSearchBoxComponent?.clearSearch();
      this.employeeSearchBoxComponent?.hide();
      this.getMySeatsBooking();
      this.setViewMod();
    }
  }

  public setButton(): void {
    if (this.isSeatBooked) {
      if (this.isBookedByMe || this.isBookedForMe) {
        this.showCancelBookingBtn = true;
      } else {
        this.showCloseBtn = true;
      }
    } else if (this.currentSeatStatus === ESeatStatus.UnAvailable) {
      this.showCloseBtn = true;
    } else if (this.currentSeatStatus === ESeatStatus.Assigned) {
      if (this.selectedSeat.assignedUser.code === this.myUser.uuid) {
        this.showBookThisSeatBtn = true;
      } else {
        this.showCloseBtn = true;
      }
    } else {
      this.showBookThisSeatBtn = true;
    }
  }

  public getMySeatsBooking(): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.apiService.getMapBookings(this.wingGroupCode, this.when).pipe(
      mergeMap((mapBooking: any) => {
        console.log("mapbooking", mapBooking,)
        const selectedSeatBooked = mapBooking.find(s => s.seatCode === this.selectedSeat.code);
        if (selectedSeatBooked) {
          this.isBookedByMe = selectedSeatBooked.bookedBy.code === this.userService.user.userProfile.uuid;
          this.isBookedForMe = selectedSeatBooked.bookedFor.code === this.userService.user.userProfile.uuid;
          this.teamLink = selectedSeatBooked.bookedFor.upn;

        }
        return of(mapBooking);
      }),
      catchError((error: ApiError) => {
        this.genericApiErrorCB.emit(error);
        return throwError(error);
      }),
      finalize(() => {
        this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        this.setButton();
      }),
    ).subscribe();
  }

  setMyUser(): void {
    this.myUser = this.userService.user.userProfile;
  }

  setViewMod(): void {
    if (this.selectedSeat.seats?.length > 0) {
      this.roomSelectedClickedEvent.emit(this.selectedSeat);
      this.viewMod = ESeatManagementMod.roomView;
    } else {
      this.viewMod = ESeatManagementMod.singleSeatView;
    }
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get isSeatBooked(): boolean {
    return this.selectedSeatBooking?.seatCode?.length > 0;
  }

  get isOverNight(): boolean {
    return this.timeService.moment(this.selectedSeatBooking?.bookingTime?.start).format('HH:mm') > this.timeService.moment(this.selectedSeatBooking?.bookingTime?.end).format('HH:mm');
  }

  public isMyUser(userName): boolean {
    return userName === this.myUser.name;
  }

  get bookedFor(): string {
    const currentUser = JSON.parse(sessionStorage.getItem('user'));
    if (currentUser && currentUser?.code !== this.myUser.uuid) {
      this.bookForFriend = { displayName: currentUser.name, id: currentUser.code, jobTitle: null, employeeId: currentUser.employeeId || null };
      this.showClearSearchBtn = true;
    }
    if (this.isSeatBooked) {
      if (this.isMyUser(this.selectedSeatBooking.bookedFor.name)) {
        return this.selectedSeatBooking.bookedFor.name + ' (me)';
      } else {
        return this.selectedSeatBooking.bookedFor.name;
      }
    } else if (this.selectedSeat.isAssigned) {
      if (this.isMyUser(this.selectedSeat.assignedUser.name)) {
        return this.selectedSeat.assignedUser.name + ' (me)';
      } else {
        return this.selectedSeat.assignedUser.name;
      }
    } else if (this.selectedSeat.isReserved) {
      if (this.selectedSeat.reservedUsers.find(u => u.code === this.myUser.uuid)) {
        return this.myUser.name + ' (me)';
      } else {
        // TODO: check
      }
    } else if (this.currentSeatStatus === ESeatStatus.UnAvailable) {
      return '';
    } else {
      return this.myUser.name + ' (me)';
    }
  }

  showSearchEmployeeBtn(): boolean {
    return this.currentSeatStatus === ESeatStatus.Available && !this.showSearchEmployee;
  }

  navigateToMap(): void {
    this.showOnMap.emit(true);
  }

  get myUserUnitsCodes(): string[] {
    const { unit = null, org2unit = null } = this.userService.user.userProfile;
    const unitsArr = [];
    if (unit) { unitsArr.push(unit); }
    if (org2unit) { unitsArr.push(org2unit); }
    return unitsArr;
  }

  get seatStatus(): string {
    if (this.isSeatBooked) {
      this.currentSeatStatus = ESeatStatus.Booked;
    } else if (this.selectedSeat.isUnavailable) {
      this.currentSeatStatus = ESeatStatus.UnAvailable;
    } else if (this.selectedSeat.isAssigned) {
      this.currentSeatStatus = ESeatStatus.Assigned;
    } else if (this.selectedSeat.isUnitRestricted) {
      const isCurrentUserUnitSeatUnit = !!this.myUserUnitsCodes.find(unitCode => this.selectedSeat.unit.code === unitCode);
      if (!isCurrentUserUnitSeatUnit) {
        this.currentSeatStatus = ESeatStatus.UnAvailable;
      } else if (this.selectedSeat.isReserved) {
        this.isReserved(this.selectedSeat);
      } else {
        if (this.selectedSeat.legalEntity && this.selectedSeat.legalEntity?.code !== this.myUserLegalEntity) {
          this.currentSeatStatus = ESeatStatus.UnAvailable;
        } else {
          this.currentSeatStatus = ESeatStatus.Available;
        }
      }
    } else {
      if (this.selectedSeat.isReserved) {
        this.isReserved(this.selectedSeat);
      } else {
        if (this.selectedSeat.legalEntity && this.selectedSeat.legalEntity?.code !== this.myUserLegalEntity) {
          this.currentSeatStatus = ESeatStatus.UnAvailable;
        } else {
          this.currentSeatStatus = ESeatStatus.Available;
        }
      }
    }
    return ESeatStatus[this.currentSeatStatus];
  }

  isReserved(seat): void {
    const myUserExistsInGroup = !!seat.reservedUsers.find(u => u.code === this.myUser.uuid);
    if (myUserExistsInGroup) {
      // this.currentSeatStatus = ESeatStatus.Available;
      // TODO: Uncomment - BE not ready.
      if (this.selectedSeat.legalEntity && !this.selectedSeat.isCrossEntity && this.selectedSeat.legalEntity?.code !== this.myUserLegalEntity) {
        this.currentSeatStatus = ESeatStatus.UnAvailable;
      } else {
        this.currentSeatStatus = ESeatStatus.Available;
      }
    } else {
      this.currentSeatStatus = ESeatStatus.UnAvailable;
    }
  }

  get myUserLegalEntity(): string {
    return this.userService.user.userProfile.legalEntity;
  }

  setUserLocation(): void {
    if (this.isMobile) {
      this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
      this.userMapService.getWingGroupDetails(this.wingGroupCode).pipe(
        mergeMap((res: ILocation) => {
          this.userLocation = res;
          if (this.selectedSeat?.wing && this.wingGroupCode) {
            this.userLocation.wing = this.selectedSeat.wing;
            this.userLocation.wingGroup = {
              code: this.wingGroupCode,
              name: this.wingGroupCode,
            };
            this.selectedMapFiltersDetailsService.setWhereCache(this.userLocation);
          }
          this.selectedMapFiltersDetailsService.setWhenCache(this.when);
          this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
          return of(res);
        }),
        catchError((error: ApiError) => {
          this.genericApiErrorCB.emit(error);
          return throwError(error);
        }),
      ).subscribe();
    } else {
      this.userLocation = JSON.parse(sessionStorage.getItem('selectLocationClient'));
    }
  }

  roomSeatClicked(seat): void {
    this.bookForFriend = null;
    this.isBookThisSeatBtnDisabled = false;
    this.btnTopMessage = null;
    this.roomSeatClickedEvent.emit(seat);
  }

  closeSeatView(): void {
    this.closeSeatViewEvent.emit(true);
  }

  createBooking(): void {
    this.bookThisSeatEvent.emit(this.bookForFriend ? this.bookForFriend.id : this.myUser.uuid);

  }

  cancelBooking(): void {
    this.cancelBookingEvent.emit(true);
  }

  selectEmployee(employee: IEmployee): void {
    this.bookForFriend = employee;
    this.showSearchEmployee = false;
    this.showClearSearchBtn = true;

    const employeeExistsInGroup = !!this.selectedSeat.reservedUsers?.find(u => u.code === employee.id);
    if (this.selectedSeat.isUnitRestricted) {
      this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
      this.apiService.getOtherUserProfile(employee.employeeId).pipe(
        mergeMap((otherUserProfile: IOtherUserProfile) => {
          if (otherUserProfile.org2unit !== this.selectedSeat.unit.code && otherUserProfile.org3unit !== this.selectedSeat.unit.code) {
            this.isBookThisSeatBtnDisabled = true;
            this.btnTopMessage = this.translateService.getText('client.seatManagement.notInRestrictedUnit', [employee.displayName]);
          } else {
            this.setBookThisSeatBtnDisabledByNH(employeeExistsInGroup, employee.displayName);
          }
          return of(otherUserProfile);
        }),
        catchError((error: ApiError) => {
          this.genericApiErrorCB.emit(error);
          return throwError(error);
        }),
        finalize(() => {
          this.eventBusService.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
          this.setButton();
        }),
      ).subscribe();
    } else {
      this.setBookThisSeatBtnDisabledByNH(employeeExistsInGroup, employee.displayName);
    }
    this.employeeSearchBoxComponent.hide();
  }

  setBookThisSeatBtnDisabledByNH(employeeExistsInGroup, displayName): void {
    if (this.selectedSeat.isReserved) {
      if (employeeExistsInGroup) {
        this.isBookThisSeatBtnDisabled = false;
        this.btnTopMessage = null;
      } else {
        this.isBookThisSeatBtnDisabled = true;
        this.btnTopMessage = this.translateService.getText('client.seatManagement.notInRestrictedList', [displayName]);
      }
    } else {
      this.isBookThisSeatBtnDisabled = false;
      this.btnTopMessage = null;
    }
  }

  clearSelectedEmployee(): void {
    this.bookForFriend = null;
    this.isBookThisSeatBtnDisabled = false;
    this.btnTopMessage = null;
    this.showClearSearchBtn = false;
    const user = { code: this.myUser.uuid, name: this.myUser.name };
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  toggleTeams() {

    this.chatIcon = !this.chatIcon;
  }

  openChatWithUser() {
    console.log("this.teamLinkthis.teamLinkthis.teamLink", this.teamLink)
    this.chatIcon = false;
    const userToChatWith = this.teamLink;
    const id = 'c8eca3ca-1276-46d5-9d9d-a0f2a028920f'
    //  const chatDeepLink = `MSTeams:/l/chat/0/0?users=${userToChatWith}`;
    // const chatDeepLink = `https://teams.microsoft.com/l/chat/0/0?users=${userToChatWith}`;
    //const chatDeepLink = `https://teams.microsoft.com/l/chat/0/0?tenantId=${id}&users=${userToChatWith}`;
    //https://teams.microsoft.com/l/chat/0/0?tenantId=<tenantId>&users=<user1>,<user2>,...&topicName=<chat name>&message=<precanned text>

    const chatDeepLink = "https://teams.microsoft.com/l/chat/0?users=" + userToChatWith + "&tenantId=" + id;
    //  const chatDeepLink = "MSTeams:/l/chat/0/0?users=nitmahin@amdocs.com";
    //alert(chatDeepLink);
    console.log(chatDeepLink);
    window.open(chatDeepLink)
 //this.router.navigateByUrl(chatDeepLink);
      //this.teamsCall();

  }

  teamsCall() {
    //const context: app.Context = await app.getContext();

    if (chat.isSupported()) {
      const chatPromise = chat.openChat({ user: ""+this.teamLink, message: "Hi this is message from POC" });
      chatPromise.
        then((result) => {
          /*Successful operation*/
          alert(result)
          console.log(result)
        }).
        catch((error) => {/*Unsuccessful operation*/
          console.log("error")
          console.log(error)
        });
    }
  }

}
