import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  AmdocsAuthService,
  AmdocsEventBusService,
  AmdocsFunctionsService,
  AmdocsPopoverComponent,
  AmdocsTranslateService,
  AmdocsUserService,
  IAmdocsUser
} from 'amdocs-core';
import { IMenuItem, ERole } from '../../../models';
import { RolesService } from '../../../core/roles.service';
import { MenuService } from '../../../core/menu.service';
import { CONSTANTS } from '../../../constants';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import {NavUserMapService} from '../../../core/nav-user-map.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss']
})
export class HeaderDesktopComponent implements OnInit {

  menuOpen = false;
  accountPopupOpen = false;
  public menuItems: IMenuItem[];
  public pageTitle: string;
  public constants = CONSTANTS;
  public showAdminMenu = false;
  public hideMyBookingsBar = true;
  public myBookingsLength: number;
  @Input() showMyBookingButton = true;

  @ViewChild('popoverRef') popoverRef: AmdocsPopoverComponent;

  constructor(private translateService: AmdocsTranslateService,
              private userService: AmdocsUserService,
              private navUserMapService: NavUserMapService,
              private rolesService: RolesService,
              private eventBus: AmdocsEventBusService,
              private activatedRoute: ActivatedRoute,
              private menuService: MenuService,
              private amdocsAuthService: AmdocsAuthService,
              private functionService: AmdocsFunctionsService) {
    this.showAdminMenu = this.rolesService.isEditor();
  }

  ngOnInit(): void {
    this.menuItems = this.menuService.menuItems;
    this.eventBus.on(CONSTANTS.EVENTS.CLOSE_HAMBURGER_MENU, () => {
      this.menuOpen = false;
    }, true);
    this.activatedRoute.data.subscribe( (data) => {
      this.pageTitle = data.pageTitle;
    });

  }

  get isAdmin(): boolean{
    return this.navUserMapService.isAdmin;
  }

  toggleHamburger(): void {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu(): void {
    this.menuOpen = false;
  }

  get user(): IAmdocsUser {
    return this.userService.user.userProfile;
  }

  openUserSettings(): void{
    this.accountPopupOpen = !this.accountPopupOpen;
  }

  isAllowed(roles: ERole[]): boolean{
    return this.rolesService.isAllowed(roles);
  }

  toggleBookingsBar(e): void{
    e.stopPropagation();
    if (this.hideMyBookingsBar){
      this.popoverRef.hide();
    }
    this.hideMyBookingsBar = !this.hideMyBookingsBar;
  }

  public logout(): void {
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.amdocsAuthService.logout()
      .pipe(
        finalize(() => {
          this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      ).subscribe();
  }

  reportIssue(): void {
    const path = environment.reportAnIssueUrl;
    this.functionService.navigateExternalUrl(path, '_self');
  }
}
