import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-desktop-popup-wrapper',
  templateUrl: './desktop-popup-wrapper.component.html',
  styleUrls: ['./desktop-popup-wrapper.component.scss']
})
export class DesktopPopupWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
