import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {CONSTANTS} from '../../constants';
import {RolesService} from '../roles.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  constructor(private rolesService: RolesService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (next.data.permissions) {
      if (!this.rolesService.isAllowed(next.data.permissions)) {
        this.router.navigate([CONSTANTS.ROUTES.HOME]);
        return false;
      }
    }
    return true;
  }
}
