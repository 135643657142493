import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AmdocsAppsyncClientService } from 'amdocs-core';
import {
  ISiteParameters, ISiteParametersRegions,
  saveSystemParametersQuery
} from '../../../models';
import {FunctionsService} from '../../../core/functions.service';
import {ApiService} from '../../../core/api.service';

// TO DO:: Ask Zion about Injectable providedIn root
@Injectable({
  providedIn: 'root'
})
export class SiteParametersService {

  constructor(private apiService: ApiService, private functionsService: FunctionsService, private appSyncClient: AmdocsAppsyncClientService) { }

  public loadSystemParameters(withRegions: boolean = false): Observable<ISiteParametersRegions> {
    return this.apiService.loadSystemParameters(withRegions);
  }

  public saveSystemParameters(tableData): Observable<ISiteParameters[]> {
    const params = {
      input: tableData
    };
    return this.appSyncClient.call(saveSystemParametersQuery, 'SaveSystemParameters', params).pipe(
      mergeMap((res: {systemParameters: ISiteParameters[] }) => {
        return of(res.systemParameters);
      })
    );
  }

  public cloneObj(obj): any {
    return this.functionsService.cloneObj(obj);
  }
}
