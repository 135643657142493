import {Injectable} from '@angular/core';
import {IActiveMap, IExtendedSeat, IMapDetails, INeighborhood, IRoom, ISelectedUnit} from '../models';
import {FunctionsService} from './functions.service';


@Injectable()
export class MapState{

  public pageKey = '';

  constructor(private functionsService: FunctionsService) {}

  get mapDetails(): IMapDetails{
    return this.getState('mapDetails');
  }

  set mapDetails(val: IMapDetails) {
    this.setState('mapDetails', val);
  }

  get activeMap(): IActiveMap{
    return this.getState('activeMap');
  }

  set activeMap(val: IActiveMap) {
    this.setState('activeMap', val);
  }

  get selectedUnit(): ISelectedUnit {
    return this.getState('selectedUnit');
  }

  set selectedUnit(val: ISelectedUnit) {
    this.setState('selectedUnit', val);
  }

  get seats(): IExtendedSeat[] {
    return this.getState('seats');
  }

  set seats(val: IExtendedSeat[]) {
    this.setState('seats', val);
  }

  get isEdited(): boolean{
    return this.getState('isEdited');
  }

  set isEdited(val: boolean) {
    this.setState('isEdited', val);
  }

  get selectedNeighborhood(): INeighborhood {
    return this.getState('selectedNeighborhood');
  }

  set selectedNeighborhood(val: INeighborhood) {
    this.setState('selectedNeighborhood', val);
  }

  get seatsRooms(): (IExtendedSeat | IRoom)[] {
    return this.getState('seatsRooms');
  }

  set seatsRooms(val: (IExtendedSeat | IRoom)[]) {
    this.setState('seatsRooms', val);
  }

  get neighborhoodsSideBarData(): INeighborhood[] {
    return this.getState('neighborhoodsSideBarData');
  }

  set neighborhoodsSideBarData(val: INeighborhood[]) {
    this.setState('neighborhoodsSideBarData', val);
  }

  get selectedSeat(): IExtendedSeat {
    return this.getState('selectedSeat');
  }

  set selectedSeat(val: IExtendedSeat) {
    this.setState('selectedSeat', val);
  }

  get selectedRoom(): IExtendedSeat {
    return this.getState('selectedRoom');
  }

  set selectedRoom(val: IExtendedSeat) {
    this.setState('selectedRoom', val);
  }

  public clear(): void {
    this.removeState('mapDetails');
    this.removeState('activeMap');
    this.removeState('neighborhood');
    this.removeState('selectedUnit');
    this.removeState('isEdited');
    this.removeState('seats');
    this.removeState('neighborhoodsSideBarData');
    this.removeState('selectedNeighborhood');
    this.removeState('seatsRooms');
    this.removeState('selectedSeat');
    this.removeState('selectedRoom');
  }

  public cloneObj(obj): any {
    return this.functionsService.cloneObj(obj);
  }

  private setState(key: string, val: any): void {
    sessionStorage.setItem(`${this.pageKey}_${key}`, JSON.stringify(val));
  }

  private getState(key: string): any {
    const data = sessionStorage.getItem(`${this.pageKey}_${key}`);
    if (data) {
      return JSON.parse(data);
    }
    return  null;
  }
  private removeState(key): void {
    sessionStorage.removeItem(`${this.pageKey}_${key}`);
  }
}
