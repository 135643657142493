import {Injectable} from '@angular/core';
import {ISeatBooking, SelectedMapFiltersDetails} from '../models';
import {CONSTANTS} from '../constants';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavUserMapService {

  public isAdmin: boolean;

  constructor(private router: Router) {
  }
  // todo:: for all functions userCode - is it friendId?

  navigateFromFilter(filtersDetails: SelectedMapFiltersDetails): void {
    // todo:: interface for queryParams
    const queryParams = {
      date: filtersDetails.when.date,
      endDate: filtersDetails.when.endDate,
      from: filtersDetails.when.from,
      to: filtersDetails.when.to,
      wingGroupCode: filtersDetails.selectLocationClient.wingGroup.code || filtersDetails.selectLocationClient.wingGroup,
      amenities: filtersDetails.amenities?.length > 0 ? filtersDetails.amenities.map(amn => amn.code).join(',') : '',
    };
    if (filtersDetails.selectLocationClient.unit?.code){
      queryParams['unitCode'] = filtersDetails.selectLocationClient.unit.code;
    }
    if (filtersDetails.selectLocationClient.neighborhood?.code){
      queryParams['nbCode'] = filtersDetails.selectLocationClient.neighborhood.code;
    }
    // if(filtersDetails.user.code){
    //   queryParams['friendId'] = filtersDetails.user.code;
    // }
    // console.log('queryParams', queryParams); //
    this.router.navigate([CONSTANTS.ROUTES.USER_MAP], {queryParams});
  }
}
