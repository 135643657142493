<div *ngIf="!!employeeSearchControl" class="employee-search-wrapper" lib-amdocs-click-outside (clickOutside)="hideResults()">
  <div class="input-wrapper">
    <img lib-amdocs-image-version class="input-icon" src="/assets/icons/search-icon.svg"/>
    <input #inputSearch
      [attr.placeholder]="'employeeSearch.inputPlaceholder' | amdocsTranslate"
      class="text-medium-bold"
      [ngClass]="{'custom-input-field-control readOnly':readOnly}"
      (keyup)="!readOnly && startSearch()"
      [formControl]="employeeSearchControl"
      (focus)="!readOnly && onSearchInputFocus()"
      [readOnly]="readOnly"
      [disabled]="readOnly"/>
    <img lib-amdocs-image-version *ngIf="!readOnly && showClearButton" class="delete-icon" src="/assets/icons/grey-close-icon.svg" (click)="clearSearch()"/>
  </div>
  <div *ngIf="errorMsg" class="custom-input-field-error">
    <img lib-amdocs-image-version src="/assets/icons/error.svg">
    <span class="text-small-normal">{{errorMsg | amdocsTranslate}}</span>
  </div>
  <div class="employee-results-wrapper"
     [ngStyle]="{height: resultsWrapperHeight + 'px'}"
     *ngIf="showResults"
     (scroll)="addScrollListening()"
     #searchResults>
    <div class="employee-results" *ngIf="employees && employees.length" >
      <div class="employee-row-wrapper" *ngFor="let employee of employees" (click)="selectEmployee($event, employee)">
        <div class="employee-row">
          <div class="employee-title text-small-normal">{{employee.displayName}}</div>
        </div>
      </div>
    </div>
    <div class="employee-no-results" *ngIf="employees && employees.length === 0">
      <img lib-amdocs-image-version src="/assets/images/no-search-results.png" class="no-results-img"/>
      <div class="no-results-text text-medium-normal">{{'employeeSearch.noResults' | amdocsTranslate}}</div>
    </div>
    <div class="load-more-placeholder" #loadMore></div>
  </div>
</div>
<ng-template [ngIf]="showSelectedEmployees">
  <app-selected-employees
    [selectedEmployees]="selectedEmployees"
    [reservedListCounter]="reservedListCounter"
    [isUnitRestricted]="isUnitRestricted"
    (removeAllEmployeesCallback)="removeAllEmployees($event)"
    (removeEmployeeCallback)="removeEmployee($event)"
  ></app-selected-employees>
</ng-template>
