import * as L from 'leaflet';

export class FocusBtn extends L.Control {

  onAdd(map: L.Map): HTMLElement {
    const el = L.DomUtil.create('div', 'focus-btn');
    const homeCoordinates = map.getCenter();
    L.DomEvent
      .disableClickPropagation(el)
      .addListener(el, 'click', () => {
        map.setView(homeCoordinates, 1);
      }, el);
    return el;
  }

  constructor(options?: L.ControlOptions) {
    super(options);
  }
}
