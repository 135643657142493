<lib-amdocs-autocomplete *ngIf="items" [elementId]="'wing-groups-DDL'" [clearable]="false"
                         [label]="label" #autocompleteElem
                         [items]="items" [hasHierarchy]="hasHierarchy"
                         [errorsMessage]="errorsMessage"
                         [placeholder]="placeholder"
                         [isRequired]="isRequired"
                         [disabled]="disabled"
                         [isRounded]="isRounded"
                         [selectIfOneItem]="selectIfOneItem"
                         (itemChangedCallback)="itemChangedEvent($event)">
</lib-amdocs-autocomplete>
