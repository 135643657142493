<div class="booking-seat-card">
  <div class="left-content">
    <div class="title">
      <span class="text-medium-normal seat-id">
        {{ bookedSeat.seatName }}
      </span>
    </div>
    <span class="text-small-bold">
      {{bookedSeat.bookingTime.start | amdocsDate: 'dddd DD-MM-yyyy, HH:mm'}}
      -
      {{bookedSeat.bookingTime.end | amdocsDate: ' HH:mm'}}
    </span>
    <span class="text-medium-normal">
      <ng-container *ngIf="bookedSeat.location.country">
        {{ bookedSeat.location.country.name + ',' }}
      </ng-container>
      <ng-container *ngIf="bookedSeat.location.building">
        {{ bookedSeat.location.building.name}}
      </ng-container>
      <ng-container *ngIf="bookedSeat.location.floor">
        {{ ', Floor ' + bookedSeat.location.floor.name }}
      </ng-container>
    </span>
  </div>

  <div class="btn" (click)="showOnMap(bookedSeat)">
    <img lib-amdocs-image-version src="/assets/icons/book-a-seat-mobile/Go-to-map.svg" alt="go to map seat location">
  </div>
</div>
