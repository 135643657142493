import {Tabs} from './models';

export const CONSTANTS = {
  ROUTES: {
    HOME: '',
    UPCOMING: Tabs.upcoming,
    RECENT: Tabs.recent,
    ADMIN: {
      BASE: 'admin',
      CHILDREN: {
        MAP_MAINTENANCE: 'map-maintenance',
        UNITS_MAINTENANCE: 'units-maintenance',
        NEIGHBORHOOD_MANAGEMENT: 'neighborhoods',
        SITE_PARAMETERS: 'parameters',
        AMENITIES_MANAGEMENT: 'amenities',
        SEAT_MANAGEMENT: 'seat-management'
      }
    },
    USER_MAP: 'user-map',
    MY_BOOKINGS: 'my-bookings',
    LOCATION_SELECTION: 'location-selection',
    AMENITIES_PAGE: 'amenities',
    WHEN_PAGE_MOBILE: 'when-mobile',
    FIND_A_FRIEND: 'find-a-friend',
    SWITCH_USER_PROFILE: 'switch-user-profile-mobile',
    BOOK_A_SEAT: '',
    QR_SCANNER:'qr-scanner'
  },
  EVENTS: {
    TOGGLE_FULL_PAGE_LOADER: 'toggle-full-page-loader',
    NOTIFY_USER_ALERT: 'notify-user-alert',
    TOGGLE_MAP_PAGE_SIDEBAR: 'toggle-map-page-sidebar',
    TOGGLE_SELECT_LOCATION_POPUP: 'toggle-select-location-popup',
    CLOSE_SELECT_LOCATION_POPUP: 'close-select-location-popup',
    CLOSE_HAMBURGER_MENU: 'close-hamburger-menu',
    TOGGLE_ARE_U_SURE_POPUP: 'toggle-are-u-sure-popup',
    UPDATE_FROM_ARE_YOU_SURE_POPUP_START: 'update-from-are-you-sure-popup-start',
    UPDATE_FROM_ARE_YOU_SURE_POPUP_END: 'update-from-are-you-sure-popup-end',
    USER_MAP_NAVIGATION: 'user-map-navigation',
    SHOW_CANCEL_BOOKING_MSG: 'show-cancel-booking-msg',
    MAP_FLY_TO: 'map-fly-to',
    UPDATE_SEATS_ICON_AFTER_BOOK_ACTION: 'update seats icon after book action',
    UPDATE_MY_BOOKING: 'update-my-booking',
    CANCEL_NEXT_BOOKING_AND_BOOK: 'cancel-next-booking-and-book',
    SHOW_NEW_VERSION_ALERT: 'show-new-version-alert',
    QR_SCANNING: 'qr-scanning'
  },
  EMPLOYMENT_SEARCH: {
    MIN_CHARS_FOR_SEARCH: 2,
    SEARCH_RESULTS: 20
  },
  DOCUMENT_VISIBILITY_STATE_VISIBLE: 'visible',
  L255: 255,
};

