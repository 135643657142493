import {IEmployee, IUnit} from './';

export interface IMap {
  version: string;
  name: string;
  isActive: boolean;
  isPublished?: boolean;
}

export interface IMapListFull {
  maps: IMap[];
  failedMaps: IFailedMap[];
}

export interface IFailedMap {
  version: string;
  errorMessage: string;
}

export interface IMapDetails {
  version: string;
  name: string;
  url: string;
  wingGroupCode: string;
  isActive: boolean;
  isPublished?: boolean;
  comment: string;
  seats: ISeat[];
  rooms: IRoom[];
  displayWidth: number;
  displayHeight: number;
  wings?: IWing[];
}

export interface IWing {
  code: string;
  name: string;
  Xmin?: number;
  Ymin?: number;
  Xmax?: number;
  Ymax?: number;
}

export interface ISeat {
  code: string;
  name?: string;
  X: number;
  Y: number;
}


export interface ISeatMap extends ISeat {
  selected?: boolean;
  classNames: string;
  seats?: ISeat[];
  // marker?: L.Marker;
}


export interface IMapDetailsRequest {
  version: string;
  name: string;
  isActive: boolean;
  isPublished?: boolean;
  comment: string;
  wingGroupCode: string;
  seats: ISeat[];
  rooms: ISeat[];
}

export interface IWingGroup {
  name: string;
  wings: string[];
}

export interface ICodeNameItem {
  code: string;
  name: string;
}

export interface ICodeNameRegionItem {
  code: string;
  name: string;
  region?: string;
}

export interface IExtendedSeat extends ISeatMap {
  isAssigned?: boolean;
  isReserved?: boolean;
  isUnavailable?: boolean;
  assignedUser: ICodeNameItem;
  reservedUsers: IEmployee[];
  org2unit: string;
  unit: IUnit;
  legalEntity: ICodeNameItem;
  isCrossEntity: boolean;
  neighborhoods: ICodeNameItem[];
  amenities: ICodeNameItem[];
  problematicMove?: boolean;
  seats?: IExtendedSeat[];
  wing: ICodeNameItem;
  seatCode?: string;
  isUnitRestricted?: boolean;
  isPublished?: boolean;
}

export interface IRoom extends IExtendedSeat {
  seats: IExtendedSeat[];
}

export interface IActiveMap {
  version: string;
  name: string;
  url: string;
  wingGroupCode: string;
  seats: IExtendedSeat[];
  rooms: IRoom[];
  displayWidth: number;
  displayHeight: number;
  wings?: IWing[];
  isPublished?: boolean;
}

export interface ILegendItem {
  className: string;
  text: string;
  fullWidth?: boolean;
  desktopOrder?: number,
  mobileOrder?: number;
}

export enum ESeatStatus {
  allocated = 'allocated',
  unallocated = 'unallocated',
  bookable = 'bookable',
  unbookable = 'unbookable',
  assigned = 'assigned',
  selected = 'selected',
  problematic = 'problematic',
  focused = 'focused'
}

export enum EUserSeatStatus {
  bookable = 'bookable',
  selected = 'selected',
  amenities = 'amenities',
  favBookable = 'fav-bookable',
  favUnbookable = 'fav-unbookable',
  friendSeat = 'friend-seat',
  myBooked = 'my-booked',
  unbookable = 'unbookable'
}


export interface IAdminAmenities {
  amenities: IAmenities[];
  code: string;
  name: string;
}

export interface IAmenities {
  code: string;
  name: string;
  comments?: string;
  isHidden?: boolean;
}

export interface IUpdateSeatAttributes {
  success: boolean;
  userAssignedToAnotherSeat: boolean;
  assignedSeatLocation: {
    country: ICodeNameItem;
    building: ICodeNameItem;
    floor: ICodeNameItem;
    wingGroup: string;
    seatName: string;
    seatCode: string;
  };
}

export interface IUpdateAmenitySeatAttributes {
  success: boolean;
}

export const getMapsListQuery = `
query MapsList($wingGroupCode: String!) {
  mapsList(wingGroupCode: $wingGroupCode) {
    maps {
      version
      name
      isActive
    }
    failedMaps {
      version
      errorMessage
    }
    pendingMaps {
      version
    }
  }
}
`;

export const getMapDetailsQuery = `
  query GetMapDetails($wingGroupCode: String!, $version: String) {
  mapDetails(wingGroupCode: $wingGroupCode, version: $version) {
    wingGroupCode
    version
    name
    url
    isActive
    isPublished
    comment
    seats {
      code
      name
      X
      Y
    }
    rooms {
      code
      name
      X
      Y
    }
    wings {
      name
      code
      Xmin
      Ymin
      Xmax
      Ymax
    }
    viewBox {
      Xmin
      Ymin
      Xmax
      Ymax
    }
    displayWidth
    displayHeight
  }
}
`;


export const saveMapDetailsQuery = `
mutation SaveMapDetails($mapDetails: MapDetailsInput!) {
  saveMapDetails(mapDetails: $mapDetails)
}
`;

export const getActiveMapQuery = `
 query ActiveMapDetails($wingGroupCode: String!, $isUserQuery: Boolean!) {
  activeMapDetails(wingGroupCode: $wingGroupCode, isUserQuery: $isUserQuery) {
    wingGroupCode
    version
    name
    url
    isPublished
    seats {
      code
      name
      isAssigned
      isReserved
      isUnavailable
      isUnitRestricted
      assignedUser {
        code
        name
      }
      reservedUsers {
        code
        name
      }
      org2unit
      unit {
        code
        name
      }
      legalEntity {
        code
        name
      }
      isCrossEntity
      neighborhoods {
        name
        code
      }
      amenities {
        name
        code
      }
      wing {
        code
        name
      }
      X
      Y
    }
    rooms {
      code
      name
      isReserved
      isUnavailable
      reservedUsers {
        code
        name
      }
      org2unit
      unit {
        code
        name
      }
      legalEntity {
        code
        name
      }
      isCrossEntity
      neighborhoods {
        code
        name
      }
      amenities {
        name
        code
      }
      wing {
        code
        name
      }
      X
      Y
      seats {
        code
        name
        isAssigned
        isReserved
        isUnavailable
        assignedUser {
          code
          name
        }
        reservedUsers {
          code
          name
        }
        org2unit
        unit {
          code
          name
        }
        legalEntity {
          code
          name
        }
        isCrossEntity
        neighborhoods {
          name
          code
        }
        amenities {
          name
          code
        }
        wing {
          code
          name
        }
      }
    }
    wings {
      name
      code
      Xmin
      Ymin
      Xmax
      Ymax
    }
    displayWidth
    displayHeight
  }
}
`;

export interface ICreateMapPopupData {
  wingGroupCode?: string;
}

export interface ICreateMapResponse {
  version: string;
  wingGroupCode: string;
  uploadUrl: string;
  downloadUrl: string;
  mapList?: IMap[];
}

// export const createMapQuery = `
// mutation CreateMap($foundationDataInput: FoundationDataInput!) {
//   createMap(foundationDataInput: $foundationDataInput) {
//     wingGroupCode
//     version
//     uploadUrl
//     downloadUrl
//   }
// }`;

export const createMapVersionQuery = `
mutation CreateMapVersion($wingGroupCode: String!) {
  createMapVersion(wingGroupCode: $wingGroupCode) {
    wingGroupCode
    version
    uploadUrl
    downloadUrl
  }
}
`;

export const getAmenitiesAsAdminQuery = `
query GetAmenitiesAsAdmin {
  regions {
    code
    name
  }

  adminAmenities {
    regionCode
    amenities {
      code
      name
      comments
      isHidden
      isDefault
    }
    amenitiesOrder {
      code
      order
    }
  }
}
`;

export const UpdateSeatAttributesQuery = `
mutation UpdateSeatAttributes($seat: SeatAttributesInput!, $force: Boolean!, $appid: String) {
  updateSeatAttributes(seat: $seat, force: $force, appid: $appid) {
    success
    userAssignedToAnotherSeat
    assignedSeatLocation {
      country {
        code
        name
      }
      building {
        code
        name
      }
      floor {
        code
        name
      }
      wingGroup
      seatName
      seatCode
      roomCode
    }
  }
}
`;

export const UpdateAmenitySeatAttributesQuery = `
mutation UpdateAmenitySeatAttributes($seat: AmenitySeatAttributesInput!) {
  updateAmenitySeatAttribute(seat: $seat) {
    success
  }
}
`;

export const CreateBookingsQuery = `
mutation CreateBookings($input: SeatBookingsInput!) {
  createBookings(input: $input) {
    success
    wingGroupCode
    bookings {
      code
      seatCode
      roomCode
      seatName
      bookingTime {
        start
        end
      }
      bookedBy {
        code
        name
      }
      bookedFor {
        code
        name
      }
      location {
        country {
          code
          name
        }
        building {
          code
          name
        }
        floor {
          code
         name
        }
        wingGroup {
          code
          name
        }
        wing {
          code
          name
        }
      }
    }
  }
}
`;

export const WingGroupDetailsQuery =
`query WingGroupDetails($wingGroupCode: String!) {
  wingGroupDetails(wingGroupCode: $wingGroupCode) {
    code
    name
    country {
      code
      name
    }
    building {
      code
      name
    }
    floor {
      code
      name
    }
  }
}`;
