import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {ApiService} from '../../../core/api.service';
import {first, mergeMap} from 'rxjs/operators';
import {IUnit} from '../../../models';
import {of} from 'rxjs';
import {AmdocsAutocompleteComponent} from 'amdocs-core';

@Component({
  selector: 'app-units-autocomplete',
  templateUrl: './units-autocomplete.component.html',
  styleUrls: ['./units-autocomplete.component.scss']
})
export class UnitsAutocompleteComponent implements OnInit{

  @Input() label: string;
  @Input() items: IUnit[];
  @Input() selectedItem: IUnit;
  @Output() itemChanged = new EventEmitter<IUnit>();
  @Input() loadUnitsOnInit = false;
  @Input() errorsMessage: string;
  @Input() placeholder = 'select';
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() hasHierarchy = true;
  @Input() isRounded = false;
  @Input() selectIfOneItem = true;
  @ViewChild('autocompleteElem') autocompleteElem: AmdocsAutocompleteComponent;


  constructor(private apiService: ApiService) { }

  public clearSelected(): void {
    this.selectedItem = null;
    this.autocompleteElem.selectedItemCode = null;

  }

  public setSelectedItem(unit: IUnit): void {
    this.selectedItem = {...unit};
    this.autocompleteElem.selectedItemCode = this.selectedItem.code;
  }

  ngOnInit(): void {
    if (this.selectedItem) {
      this.autocompleteElem.selectedItemCode = this.selectedItem.code;
    }
    if (this.loadUnitsOnInit) {
      this.apiService.getUnitsFoundation().pipe(
        mergeMap((units: IUnit[]) => {
          const options: any[] = [];
          units.map((unit) => {
            options.push({code: unit.code, name: unit.name, isGroup: true});
            unit.org3units.map((org3Unit) => {
              options.push({code: org3Unit.code, name: org3Unit.name});
            });
          });
          this.items = [...options];
          return of(units);
        }),
        first()
      ).subscribe();
    }
  }

  itemChangedEvent(unit: IUnit): void{
    this.selectedItem = unit;
    this.itemChanged.emit(unit);
  }
}
