<div class="header-hamburger-wrapper" (click)="hideMyBookingsBar = true">
  <div class="header-wrapper d-flex align-items-center justify-content-between">
    <div class="header-right d-flex">
      <div *ngIf="showAdminMenu" class="pointer hamburger-icon-wrapper d-flex align-items-center justify-content-center"
           (click)="toggleHamburger()">
        <img lib-amdocs-image-version src="/assets/icons/hamburger.svg" alt="menu icon">
      </div>
      <a href="javascript:void(0)" [routerLink]="['/' + constants.ROUTES.ADMIN.BASE]"
         class="amdocs-logo-wrapper  d-flex align-items-center justify-content-end">
        <img lib-amdocs-image-version src="/assets/icons/logo.svg" alt="logo">
      </a>
      <div class="header-text  d-flex align-items-center justify-content-center label-h4">
        {{pageTitle | amdocsTranslate}}
      </div>
    </div>
    <div class="header-left d-flex  d-flex align-items-center justify-content-center text-medium-normal">
      <a href="javascript:void(0)" class="text-medium-normal header-link my-bookings-wrapper "
         (click)="reportIssue()">
        <div>{{ 'homePage.reportIssue' | amdocsTranslate }}</div>
      </a>
      <span class="divider">|</span>
      <div *ngIf="!isAdmin && showMyBookingButton" class="pointer">
        <a href="javascript:void(0)" class="text-medium-normal header-link my-bookings-wrapper "
           (click)="toggleBookingsBar($event)">
          <div>{{ 'myBookings.myBookings' | amdocsTranslate }}</div>
          <app-badge [content]="myBookingsLength"></app-badge>
        </a>
        <div class="full-page-my-bookings" [hidden]="hideMyBookingsBar">
          <div class="d-flex justify-content-end full-height">
            <div class="overlay flex-grow-1 full-height" ></div>
            <div class="my-bookings-bar">
              <app-my-bookings (click)="$event.stopPropagation()" (closeMyBookingBarEvent)="hideMyBookingsBar=true" (myBookingsLength)="myBookingsLength = $event"></app-my-bookings>
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="!isAdmin && showMyBookingButton" class="divider">|</span>
      <lib-amdocs-popover #popoverRef [trigger]="'click'" [placement]="'bottom'" (click)="hideMyBookingsBar = true;" [class]="!hideMyBookingsBar ? 'non-pointer' : ''">
        <div popoverOpener>
          <div *ngIf="user" class="user-name-wrapper">
            <span class="user-name">{{user.name}}</span>
            <img lib-amdocs-image-version class="arrow-down" src="/assets/icons/arrowDown.svg" alt="arrow down logo">
          </div>
        </div>
        <div popoverContent>
          <div class="header-logout-wrapper" (click)="logout()">
            <div class="header-logout-btn">{{'admin.logOut' | amdocsTranslate}}</div>
          </div>
        </div>
      </lib-amdocs-popover>
    </div>
  </div>
  <div [ngClass]="{'show': menuOpen}" class="hamburger-fade">
  </div>
  <div [ngClass]="{'open' : menuOpen}" class="hamburger-wrapper">
    <div (click)="toggleHamburger()" class="close-hamburger-icon d-flex justify-content-end align-items-center">
      <img lib-amdocs-image-version src="/assets/icons/close.svg" alt="close icon">
    </div>
    <ul class="menu-items">
      <ng-container *ngFor="let item of menuItems">
        <li (click)="closeMenu(); item.action()" *ngIf="isAllowed(item.roles)"
            class="text-small-normal menu-item">
          <span class="item-desc label-h3">{{item.name}}</span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>


