import {Injectable} from '@angular/core';
import {
  getNeighborhoodsAdminQuery,
  IBuildingAndUnits,
  ICountry,
  IFloor,
  IFloorsAndUnits,
  IUnit, IUnitsWingsWingGroups, IWingsAndWingGroups,
  INeighborhood,
  setDefaultLocationQuery, getNeighborhoodsAllocationQuery, INeighborhoodUser
} from '../models';
import {Observable, of} from 'rxjs';
import {AmdocsAppsyncClientService} from 'amdocs-core';
import {mergeMap} from 'rxjs/operators';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
  }
)export class FoundationService {

  public countries: ICountry[] = [];
  public floors: IFloor[] = [];
  public units: IUnit[] = [];
  public region: string;
  constructor(private apiService: ApiService, private appSyncClient: AmdocsAppsyncClientService) {
  }

  public getNeighborhoodsAllocation(wingGroupCode: string, unitCode: string): Observable<INeighborhoodUser[]> {
    return this.appSyncClient.call(getNeighborhoodsAllocationQuery, 'GetNeighborhoodsUser', {wingGroupCode, unitCode}).pipe(
      mergeMap((res: {neighborhoodsUser: INeighborhoodUser[]}) => {
        return of(res.neighborhoodsUser);
      })
    );
  }
  getUnitsFloorByBuilding(country, building): Observable<IFloorsAndUnits> {
    return this.apiService.getUnitsFloorByBuilding(country, building);
  }

  getFloorsByUnit(countryCode, buildingCode, unitCode): Observable<IFloor[]> {
    return this.apiService.getFloorsByUnit(countryCode, buildingCode, unitCode);
  }

  getWingsWingGroupsByUnit(countryCode, buildingCode, floorCode, unitCode): Observable<IWingsAndWingGroups> {
    return this.apiService.getWingsWingGroupsByUnit(countryCode, buildingCode, floorCode, unitCode);
  }

  getWingsWingsWingGroupsByFloor(country, building, floor): Observable<IUnitsWingsWingGroups> {
    return this.apiService.getWingsWingsWingGroupsByFloor(country, building, floor);
  }

  getBuildingsUnitsByCountry(country, nbPage: boolean = false): Observable<IBuildingAndUnits> {
    return this.apiService.getBuildingsUnitsByCountry(country, nbPage);
  }

  setDefaultLocation(wingGroupCode, neighborhood, unit, wing): Observable<boolean> {
    return this.appSyncClient.call(setDefaultLocationQuery, 'SetDefaultLocation', {
      input: {
        wingGroupCode: wingGroupCode,
        neighborhoodCode: neighborhood,
        unitCode: unit,
        wingCode: wing
      }
    }).pipe(
      mergeMap((res) => {
        return of(res.setDefaultLocation);
      })
    );
  }

  getUnitsByWingGroup(countryCode: string, wingGroupCode: string ): Observable<IUnit[]> {
    return this.apiService.getUnitsByWingGroup(countryCode, wingGroupCode);
  }

}
