<div class="d-flex flex-column page-wrapper">
  <!-- header -->
  <div class="header-wrapper">
    <ng-content select="[header]"></ng-content>
  </div>
  <!-- content -->
  <div class="content-wrapper">
      <ng-content select="[content]"></ng-content>
  </div>
</div>
