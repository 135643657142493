import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { AmdocsEventBusService, ApiError, IEmployee } from 'amdocs-core';
import { CONSTANTS } from '../../../constants';
import { EmployeeSearchService } from '../../../core/employee-search.service';
import { ISearchEmployee } from '../../../models/';
import {IReservedUser} from '../../../models';

@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit {

  @ViewChild('inputSearch') inputSearchElement: ElementRef;
  @ViewChild('loadMore') loadMorePlaceholderElement: ElementRef;
  @ViewChild('searchResults') searchResultsElement: ElementRef;

  @Input() inputValidationFlag = false;
  @Input() resetInputValue = false;
  @Input() initialSelectedList: IReservedUser[];
  @Input() resultsWrapperHeight = 191;
  @Input() showSelectedEmployees = true;
  @Input() showSearchClearButton = false;
  @Input() initialInputValue: string;
  @Input() setInputValueOnEmployeeSelect = true;
  @Input() errorMsg: string = null;
  @Input() isUnitRestricted = false;
  @Input() readOnly = false;
  @Output() selectedEmployeesCallback: EventEmitter<IEmployee[]> = new EventEmitter<IEmployee[]>();
  @Output() searchResultEmployeeClicked: EventEmitter<IEmployee> = new EventEmitter<IEmployee>();
  @Output() clearResultEmployeeClicked: EventEmitter<IEmployee> = new EventEmitter<IEmployee>();

  public employees: IEmployee[] = null;
  public selectedEmployees: IEmployee[] = [];
  public reservedListCounter: number;

  public employeeSearchControl: FormControl;
  public showResults = false;
  public showEndOfResults = false;
  public isLoading = false;
  public searchError: ApiError = null;
  public nextToken: string = null;
  private thread: any;

  constructor(
    private eventBus: AmdocsEventBusService,
    private employeeService: EmployeeSearchService
  ) { }

  get showClearButton(): boolean {
    if (this.showSearchClearButton) {
      return this.employeeSearchControl.value || this.showResults;
    }
  }

  ngOnInit(): void {
    this.employeeSearchControl = new FormControl(this.initialInputValue, Validators.required);
    this.employeeSearchControl.markAsDirty();
    this.initSearch();
    this.initSelectedList();
  }

  private initSelectedList(): void {
    this.initialSelectedList.map(elem => {
      this.selectedEmployees.push({id: elem.code, displayName: elem.name, jobTitle: ''});
    });
    this.reservedListCounter = this.selectedEmployees.length;
  }

  private initSearch(): void {
    this.reservedListCounter = 0;
  }

  public startSearch(): void {
    if (this.thread) {
      clearTimeout(this.thread);
    }
    this.thread = setTimeout(() => {
      this.nextToken = null;
      this.searchEmployee(this.employeeSearchControl.value);
    }, 400);
  }

  public addScrollListening(): void {
    const nativeEl = this.searchResultsElement.nativeElement;
    if ((nativeEl.scrollHeight - nativeEl.scrollTop) === nativeEl.clientHeight && this.nextToken) {
      this.searchEmployee(this.employeeSearchControl.value);
    }
  }

  private searchEmployee(searchText): void {
    this.searchError = null;
    this.showEndOfResults = false;

    if (searchText?.length >= CONSTANTS.EMPLOYMENT_SEARCH.MIN_CHARS_FOR_SEARCH) {
      this.isLoading = true;
      // this.showResults = true;
      this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
      this.employeeService.searchEmployee(searchText, CONSTANTS.EMPLOYMENT_SEARCH.SEARCH_RESULTS, this.nextToken, false)
        .pipe(
          mergeMap((res: ISearchEmployee): Observable<ISearchEmployee> => {
            // if we called with token, means it's extra data for the same search;
            if (this.nextToken) {
              this.employees = this.employees.concat(res.employees);
            } else {
              this.employees = res.employees;
            }
            this.nextToken = res.nextToken;
            this.showResults = true;

            if (!this.nextToken) {
              setTimeout(() => {
                this.showEndOfResults = this.resultsWrapperHeight < (this.searchResultsElement?.nativeElement?.clientHeight || 0);
              }, 100);
            }
            return of(res);
          }),
          catchError((error: ApiError) => {
            this.searchError = error;
            return throwError(error);
          }),
          finalize(() => {
            this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
            this.isLoading = false;
          })
        ).subscribe();

    }
  }

  public onSearchInputFocus(): void {
    if (this.showResults) {
      return;
    }
    this.searchEmployee(this.employeeSearchControl.value);
  }

  public clearSearch(): void {
    if (!this.employeeSearchControl.value) {
      this.hideResults();
      return;
    }
    this.employeeSearchControl.setValue('');
    this.inputSearchElement.nativeElement.focus();
    this.clearResultEmployeeClicked.emit();
  }

  public selectEmployee(event, employee: IEmployee): void {
    event.stopPropagation();
    this.searchResultEmployeeClicked.emit(employee);
    if (this.setInputValueOnEmployeeSelect) {
      this.resetInputValue ? this.employeeSearchControl.reset() : this.employeeSearchControl.setValue(employee.displayName);
    }
    if (this.selectedEmployees.length) {
      const existingEmployee = this.selectedEmployees.find((empl: IEmployee) => empl.id === employee.id);
      if (existingEmployee) {
        this.hideResults();
        return;
      } else {
        this.addEmployee(employee);
      }
    } else {
      this.addEmployee(employee);
    }
  }

  public addEmployee(employee): void {
    this.selectedEmployees.push(employee);
    this.reservedListCounter++;
    // this.showResults = false;
    this.hideResults();
    this.selectedEmployeesCallback.emit(this.selectedEmployees);
    this.errorMsg = null;
  }

  public hideResults(): void {
    if (!this.setInputValueOnEmployeeSelect) {
      this.employeeSearchControl.setValue('');
    }
    this.employees = [];
    this.showResults = false;
  }

  public removeAllEmployees(event: boolean): void {
    this.clear();
  }

  public removeEmployee(id: string): void {
    const tempSelectedEmpls = [...this.selectedEmployees];
    this.selectedEmployees = tempSelectedEmpls.filter((empl: IEmployee) => {
      return empl.id !== id;
    });
    this.reservedListCounter--;
    this.selectedEmployeesCallback.emit(this.selectedEmployees);
  }

  private clear(): void {
    this.selectedEmployees = [];
    this.reservedListCounter = 0;
    this.employeeSearchControl.setValue('');
    this.selectedEmployeesCallback.emit(this.selectedEmployees);
  }

}
