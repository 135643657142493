import {Injectable} from '@angular/core';
import {AmdocsAppsyncClientService} from 'amdocs-core';
import {
  getMapDetailsQuery,
  IMap,
  IMapDetails,
  IMapDetailsRequest,
  saveMapDetailsQuery,
  getActiveMapQuery,
  IBuilding,
  IActiveMap, IExtendedSeat,
  IRoom, ESeatStatus,
  INeighborhood, IAdminAmenityRegion, IUpdateSeatAttributes, IUpdateAmenitySeatAttributes
} from '../models';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {ApiService} from './api.service';
import {removeUnitQuery, saveUnitQuery} from '../models/unit';
import {environment} from '../../environments/environment';

@Injectable()
export class MapService {

  constructor(private apiService: ApiService,
              private appSyncClient: AmdocsAppsyncClientService) {}

  getMapsList(wingGroup: string): Observable<IMap[]> {
    return this.apiService.getMapList(wingGroup);
  }

  getBuildingsByUnit(country: string, unit: string): Observable<IBuilding[]> {
    return this.apiService.getBuildingsByUnit(country, unit);
  }

  getAmenitiesAsAdmin(): Observable<IAdminAmenityRegion> {
    return this.apiService.getAmenitiesAsAdmin();
  }

  updateSeatAttributes(input): Observable<IUpdateSeatAttributes> {
    return this.apiService.updateSeatAttributes(input);
  }

  updateAmenitySeatAttributes(input): Observable<IUpdateAmenitySeatAttributes> {
    return this.apiService.updateAmenitySeatAttributes(input);
  }

  getMapDetails(wingGroupCode: string, version: string): Observable<IMapDetails> {
    return this.appSyncClient.call(getMapDetailsQuery, 'GetMapDetails', {wingGroupCode, version}).pipe(
      mergeMap((res: any) => {
        if (environment.isRC) {
          res.mapDetails.url = '/assets/images/image.svg';
        }
        return of(res.mapDetails);
      })
    );
  }

  saveMapDetails(mapDetails: IMapDetailsRequest): Observable<boolean> {
    return this.appSyncClient.call(saveMapDetailsQuery, 'SaveMapDetails', {mapDetails}).pipe(
      mergeMap((res: any) => {
        return of(res?.saveMapDetails);
      })
    );
  }

  getActiveMap(wingGroupCode: string, isUserQuery = false): Observable<IActiveMap> {
    const params: {wingGroupCode: string, isUserQuery: boolean} = {
      wingGroupCode,
      isUserQuery
    };
    return this.appSyncClient.call(getActiveMapQuery, 'ActiveMapDetails', params).pipe(
      mergeMap((res: any) => {
        if (environment.isRC) {
          res.activeMapDetails.url = '/assets/images/map.jpg';
        }
        res.activeMapDetails.seats.forEach((s) => {
          s.unit = s.unit || {code: null, name: null};
        });
        res.activeMapDetails.rooms.forEach((r) => {
          r.unit = r.unit || {code: null, name: null};
        });
        // res.activeMapDetails.rooms = [];
        // res.activeMapDetails.seats = res.activeMapDetails.seats.filter(s => s.name === 'S0-269');
        return of(res.activeMapDetails);
      })
    );
  }

  public removeUnit(unit: string, wingGroupCode: string): Observable<boolean> {
    return this.appSyncClient.call(removeUnitQuery, 'RemoveUnitAllocation', {unit, wingGroupCode}).pipe(
      mergeMap((res: any) => {
        return of(res.removeUnitAllocation);
      })
    );
  }

  public saveUnit(unit: string, wingGroupCode: string, rooms: string[] = [], seats: string[] = [], isRestricted: boolean = false): Observable<boolean> {
    const params = {
      input: {
        unit, wingGroupCode, rooms, seats, isRestricted
      }
    };
    return this.appSyncClient.call(saveUnitQuery, 'CreateUpdateUnitAllocation', params).pipe(
      mergeMap((res: any) => {
        return of(res.unitAllocation);
      })
    );
  }

  getAdminSeatTags(seat: IExtendedSeat|IRoom, isNBPage = false): string[] {
    const tags = [];
    if (this.isAllocated(seat)) {
      tags.push(ESeatStatus.allocated);
    } else {
      tags.push(ESeatStatus.unallocated);
    }
    if ((seat as IRoom).seats) {
      if (this.atLeasOneSeatIsBookable((seat as IRoom))) {

      } else {
        if (this.atLeasOneSeatIsAssigned(seat as IRoom)) {
          tags.push(ESeatStatus.assigned);
        } else {
          tags.push(ESeatStatus.unbookable);
        }
      }
    } else {
      if (!this.isBookable(seat)) {
        if (!this.isAssigned(seat)) {
          tags.push(ESeatStatus.unbookable);
        } else {
          if (!isNBPage) {
            tags.push(ESeatStatus.assigned);
          } else {
            if (this.isNBReserved(seat)) {
              tags.push(ESeatStatus.assigned);
            } else {
              tags.push(ESeatStatus.unbookable);
            }
          }
        }
      } else {
        if (this.isAssigned(seat)) {
          tags.push(ESeatStatus.assigned);
        }
      }
    }
    return tags;
  }

  // getAdminManageSeatTags(seat: IExtendedSeat | IRoom, isNBPage = false): string[] {
  //   const tags = [];
  //   if (this.isAllocated(seat)) {
  //     tags.push(ESeatStatus.allocated);
  //   } else {
  //     tags.push(ESeatStatus.unallocated);
  //   }
  //   if (!this.isBookable(seat)) {
  //     if (!this.isAssigned(seat)) {
  //       tags.push(ESeatStatus.unbookable);
  //     }
  //     else{
  //       if (this.isEmployee(seat)){
  //         tags.push(ESeatStatus.assigned);
  //       }
  //     }
  //   }
  //   // if (!this.isBookable(seat)) {
  //   //   console.log(11111)
  //   //   if (!this.isAssigned(seat)) {
  //   //     tags.push(ESeatStatus.unbookable);
  //   //   } else {
  //   //     if (!isNBPage) {
  //   //       tags.push(ESeatStatus.assigned);
  //   //     } else {
  //   //       if (this.isNBReserved(seat)) {
  //   //         tags.push(ESeatStatus.assigned);
  //   //       } else {
  //   //         tags.push(ESeatStatus.unbookable);
  //   //       }
  //   //     }
  //   //   }
  //   // } else {
  //   //   if (this.isAssigned(seat)) {
  //   //     tags.push(ESeatStatus.assigned);
  //   //   }
  //   // }
  //   // console.log('tags', tags); //
  //   return tags;
  // }

  public isEmployee(seat: IExtendedSeat): boolean{
    return seat.reservedUsers.length > 0;
  }

  private isNBReserved(seat: IExtendedSeat): boolean {
    return !!seat.isReserved;
  }
  public isAllocated(seat: IExtendedSeat): boolean {
    return !!seat.unit?.code;
  }

  public isBookable(seat: IExtendedSeat): boolean {
    return seat.isUnavailable !== true;
  }
  public isAssigned(seat: IExtendedSeat): boolean {
    return seat.isAssigned === true;
  }

  public isRoomAssigned(room: IRoom): boolean {
    if (!room.seats) {
      return false;
    }
    return room.seats.filter(s => this.isAssigned(s)).length > 0;
  }
  public assignedToEmployee(seat: IExtendedSeat): boolean {
    return seat.assignedUser !== null;
  }

  public isAllocatedToUnit(seat: IExtendedSeat): boolean {
    return !!seat.unit?.code;
  }
  public isAllocatedToNB(seat: IExtendedSeat): boolean {
    return seat.neighborhoods.length > 0;
  }
  public atLeasOneSeatIsBookable(room: IRoom): boolean {
    return room.seats.filter((s: IExtendedSeat) => this.isBookable(s)).length > 0;
  }
  public atLeasOneSeatIsAssigned(room: IRoom): boolean {
    return room.seats.filter((s: IExtendedSeat) => this.isAssigned(s)).length > 0;
  }
  public seatHasReservedNB(seat: IExtendedSeat, nbList: INeighborhood[]): boolean {
    return seat.neighborhoods.filter((seatNB) => {
      return nbList.filter(n => n.code === seatNB.code && n.isReserved === true).length > 0;
    }).length > 0;
  }

  public seatHasThisNB(seat: IExtendedSeat, nb: INeighborhood): boolean {
    return seat.neighborhoods.filter(tmpNB => tmpNB.code === nb.code).length > 0;
  }

  public atLeasOneSeatIsBookableAndNotAssigned(room: IRoom): boolean {
    return room.seats.filter((seat: IExtendedSeat) => {
      return this.isBookable(seat) && !this.isAssigned(seat);
    }).length > 0;
  }

  public getSeatBaseClasses(seat: IExtendedSeat): string {
    return `map-icon admin ${seat.code} ${seat.unit?.code} ${seat.neighborhoods?.map(n => n.code).join('-')}`;
  }
}
