export interface IUserFavoriteSeat {
  userFavorites: IUserFavorite[];
}

export interface IUserFavorite {
  seatCode: string;
  seatName: string;
  wingGroupCode: string;
}

export interface IUserFavoriteSeat {
  seatCode: string;
  roomCode: string;
  seatName: string;
  isAvailable: boolean;
}

export interface IUserUpdateFavoriteSeat {
  input: {
    seatCode: string;
    isFavorite: boolean;
    wingGroupCode: string;
    roomCode: string
  };
}

export const getUserFavorites = `
query GetUserFavorites($wingGroupCode: String) {
  userFavorites(wingGroupCode: $wingGroupCode) {
    seatCode
    seatName
    wingGroupCode
  }
}
`;

export const getUserFavoritesSeatsQuery = `
query GetUserFavoriteSeats($input: UserFavoriteSeatsInput!) {
  userFavoriteSeats(input: $input) {
    seatCode
    roomCode
    seatName
    isAvailable
  }
}
`;

export const setUserFavorite = `
mutation SetUserFavorites($input: FavoritesInput!) {
  setUserFavorites(input: $input)
}
`;
