import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AmdocsDeviceService} from "../../../../vendor/amdocs-core-package";

@Component({
  selector: 'app-generic-error-popup',
  templateUrl: './generic-error-popup.component.html',
  styleUrls: ['./generic-error-popup.component.scss']
})
export class GenericErrorPopupComponent implements OnInit {

  @Input() errorMsg;
  @Output() popupClosed: EventEmitter<boolean> = new EventEmitter();
  constructor(private deviceService: AmdocsDeviceService) { }

  ngOnInit(): void {}

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  closePopup(): void{
    this.popupClosed.emit(true);
  }

}
