export interface IMenuItem{
  id: string;
  name: string;
  roles: ERole[];
  icon?: string;
  action?: () => void;
}

export enum ERole {
  facilityAdmin = 'flex.facility.admin',
  itAdmin= 'flex.it.admin',
  itSupportAdmin= 'flex.it.field.support',
  unitAdmin= 'flex.unit.admin',
  user= 'flex.user'
}
