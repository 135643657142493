import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Router} from "@angular/router";
import {CONSTANTS} from '../../../constants';
import {AmdocsDeviceService} from 'amdocs-core';
import {MenuService} from '../../../core/menu.service';

@Component({
  selector: 'app-employee-search-text-field',
  templateUrl: './employee-search-text-field.component.html',
  styleUrls: ['./employee-search-text-field.component.scss']
})
export class EmployeeSearchTextFieldComponent {

  constructor(private router: Router, private menuService: MenuService) {
  }
  @Output() clear = new EventEmitter();
  @Input() employeeSearchControl: FormControl = new FormControl('');
  @Input() showSearchClearButton: boolean;
  @Input() placeholder = 'bookASeat.searchPlaceholder';
  @Input() isScroll: boolean = false;
  @Input() showTopHeader: boolean = true;
  @ViewChild('inputSearch') inputSearchElement: ElementRef;

  get homeUrl(): string {
    return this.menuService.getHomeUrl();
  }

  clearSearch(): void {
    this.inputSearchElement.nativeElement.focus();
    this.clear.emit();
  }

  close(){
    this.router.navigate([CONSTANTS.ROUTES.BOOK_A_SEAT]);
  }

  scanQR(): void{
    this.router.navigate([CONSTANTS.ROUTES.QR_SCANNER]);
  }

}
