import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ILegendItem} from '../../../models';

@Component({
  selector: 'app-mobile-footer-legend',
  templateUrl: './mobile-footer-legend.component.html',
  styleUrls: ['./mobile-footer-legend.component.scss']
})
export class MobileFooterLegendComponent implements OnInit {

  @Input() legendItems: ILegendItem[] = [];
  @Output() legendToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  public showLegendList = false;

  constructor() {
  }

  ngOnInit(): void {
    this.legendItems = this.legendItems.sort((a, b) => a.mobileOrder > b.mobileOrder ? 1 : -1);
  }

  toggleLegend(): void{
    this.showLegendList = !this.showLegendList;
    this.legendToggled.emit(this.showLegendList);
  }

}
