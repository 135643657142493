import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapCoordinate'
})
export class MapCoordinatePipe implements PipeTransform {
  transform(value: number): unknown {
    return Math.floor(value);
  }

}
