import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IUserFavorite, IUserUpdateFavoriteSeat} from '../../../../models/user-favorite-seat';
import {IExtendedSeat, ISeatBooking, Tabs} from '../../../../models';
import {AmdocsEventBusService, AmdocsTimeService, AmdocsUserService} from 'amdocs-core';
import {IMapBooking} from "../../../../models/user-map";

@Component({
  selector: 'app-seat-booking-cards',
  templateUrl: './seat-booking-cards.component.html',
  styleUrls: ['./seat-booking-cards.component.scss']
})
export class SeatBookingCardsComponent implements OnInit{

  @Input() seatsBooking: ISeatBooking[];
  @Input() currentTab: Tabs;
  @Input() isBookingByMe: boolean;
  @Input() isBookingForMe: boolean;
  @Input() favoriteSeats: IUserFavorite[];
  @Output() favorite = new EventEmitter<IUserUpdateFavoriteSeat>();
  @Output() removeBooked = new EventEmitter<{seatCode: string, bookingCode: string, roomCode: string}>();
  @Output() clickEvent = new EventEmitter<ISeatBooking>();

  public tabs = Tabs;
  constructor(private amdocsUserService: AmdocsUserService, private eventBusService: AmdocsEventBusService, private timeService: AmdocsTimeService) {}

  ngOnInit(): void {
  }

  updateFavorite(seatCode: string, isFavorite: boolean, wingGroupCode: string, roomCode: string): void {
    const input = {seatCode, isFavorite, wingGroupCode, roomCode: roomCode};
    this.favorite.emit({input});
  }

  removeBookedSeat(seat: {seatCode: string, bookingCode: string, roomCode: string} ): void {
    this.removeBooked.emit(seat);
  }

  onClick(seat: ISeatBooking): void {
    if (this.currentTab === Tabs.upcoming) {
      this.clickEvent.emit(seat);
    }
  }

  get myUserCode(): string {
    return this.amdocsUserService.user.userProfile.uuid;
  }

  isOverNight(seat: ISeatBooking): boolean {
    return this.timeService.moment(seat?.bookingTime?.start).format('HH:mm') > this.timeService.moment(seat?.bookingTime?.end).format('HH:mm');
  }

  isFavorite(seat): boolean{
    return !!this.favoriteSeats.find(s => s.seatCode === seat.seatCode);
  }
}
