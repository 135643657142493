import { Component, OnInit } from '@angular/core';
import {ISelectLocationConfig} from '../models';
import {AmdocsEventBusService} from 'amdocs-core';
import {CONSTANTS} from '../constants';

@Component({
  selector: 'app-app-desktop',
  templateUrl: './app-desktop.component.html',
  styleUrls: ['./app-desktop.component.scss']
})
export class AppDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
