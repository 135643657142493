import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-desktop-simple-page-layout',
  templateUrl: './desktop-simple-page-layout.component.html',
  styleUrls: ['./desktop-simple-page-layout.component.scss']
})
export class DesktopSimplePageLayoutComponent implements OnInit, AfterViewInit {

  showSubheader = false;
  @ViewChild('subHeader') subHeader: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.showSubheader = this.subHeader.nativeElement && this.subHeader.nativeElement.children.length > 0;
    this.cdRef.detectChanges();
  }

}
