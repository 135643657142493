import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {
  AmdocsAppInitResponse,
  AmdocsAppInitService, AmdocsDeviceService, AmdocsTranslateService, AmdocsUserService,
  IAppGatewayConfig,
  IAppSyncConfig,
  ILoginGatewayConfig
} from 'amdocs-core';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {Router, RouterModule} from '@angular/router';
import {ShareModule} from './share/share.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import { AppDesktopComponent } from './app-desktop/app-desktop.component';
import { AppMobileComponent } from './app-mobile/app-mobile.component';
import {finalize, mergeMap} from 'rxjs/operators';
import {ApiService} from './core/api.service';
import {of} from 'rxjs';
import {RolesService} from './core/roles.service';
import {CONSTANTS} from './constants';
import {ICountry, IUserSettings} from './models';
import {FoundationService} from './core/foundation.service';
import {UserSettingsService} from './core/user-settings.service';
import { ToastrModule } from 'ngx-toastr';
import {DatePipe} from '@angular/common';

export function initializeApp(appInitService: AmdocsAppInitService,
                              translateService: AmdocsTranslateService,
                              apiService: ApiService,
                              rolesService: RolesService,
                              foundationService: FoundationService,
                              deviceService: AmdocsDeviceService,
                              amdocsUserService: AmdocsUserService,
                              userSettingsService: UserSettingsService,
                              router: Router): any {
  const appInit = appInitService;
  const amdocsDeviceService = deviceService;
  return (): Promise<any> => {
    return translateService.loadTranslate().pipe(
      mergeMap(() => {
        return appInitService.initApp().pipe(
          mergeMap((appInitResponse: AmdocsAppInitResponse) => {
            if (appInitResponse.success) {
              console.log('app init success is  true');
              return apiService.init().pipe(
                mergeMap((res: { countries: ICountry[], userProfile: any, userSettings: IUserSettings}) => {
                  console.log('bas init OK');

                  userSettingsService.userSettings = res.userSettings;
                  foundationService.countries = res.countries;
                  let country = res.userProfile.country ? res.userProfile.country : 'USA';
                  if (res.userSettings?.defaultLocation?.country?.code) {
                    country = res.userSettings?.defaultLocation?.country?.code;
                  }

                  foundationService.region = foundationService.countries.find(c => c.code === country)?.region;
                  amdocsUserService.user.userProfile.uuid = res.userProfile.code;
                  amdocsUserService.user.userProfile.name = res.userProfile.name;
                  (amdocsUserService.user.userProfile as any).unit = res.userProfile.unit;
                  rolesService.loadRoles();
                  console.log('appInitResponse', appInitResponse); //
                  console.log('rolesService.isEditor()', rolesService.isEditor()); //
                  if (appInitResponse.isPostLogin && rolesService.isEditor()) {
                    console.log('navigating to admin');
                    router.navigate([CONSTANTS.ROUTES.ADMIN.BASE]);
                  } else {
                    console.log('NOT navigating to admin');
                    if (!amdocsDeviceService.isMobile() && !rolesService.isEditor()) {
                      console.log('navigating to user map');
                      router.navigate([CONSTANTS.ROUTES.USER_MAP]); // desktop
                    }
                  }
                  return of(res);
                })
              );
            } else {
              if (!appInit.appInitError) {
                appInit.appInitError = appInitResponse.error;
              }
              return of(appInitResponse);
            }
          }),
          finalize(() => {
            console.log('finalize init');
            appInit.appFinishedLoaded = true;
            document.body.className = document.body.className.replace('show-splash', '');
          })
        );
      })
    ).toPromise();
  };
}

const appSyncConfig: IAppSyncConfig = {
  url: environment.graphQlBaseURL,
  region: 'us-east-1',
  initAppOperationName: ''
};

const appGatewayConfig: IAppGatewayConfig = {
  url: 'https://localhost:4200/appConfig',
  region: 'us-east-1',
};
const loginGatewayConfig: ILoginGatewayConfig = {
  url: environment.loginGWBaseURL,
  region: 'eu-west-1',
  appid: environment.appId,
  refreshTokenInterval: environment.refreshTokenInterval
};

const imageVersion = environment.imgVersion;

@NgModule({
  declarations: [
    AppComponent,
    AppDesktopComponent,
    AppMobileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ShareModule,
    CoreModule,
    RouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: true, registrationStrategy: 'registerImmediately'}),
    ToastrModule.forRoot()
  ],
  providers: [
    DatePipe,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [
      AmdocsAppInitService, AmdocsTranslateService, ApiService, RolesService, FoundationService, AmdocsDeviceService, AmdocsUserService, UserSettingsService, Router],
      multi: true},
    {
      provide: 'IAppSyncConfig',
      useValue: appSyncConfig
    },
    {
      provide: 'IAppGatewayConfig',
      useValue: appGatewayConfig
    },
    {
      provide: 'ILoginGatewayConfig',
      useValue: loginGatewayConfig
    },
    {
      provide: 'IImageVersion',
      useValue: imageVersion
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
