import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AmdocsTranslateService} from 'amdocs-core';

@Component({
  selector: 'app-are-you-sure-popup',
  templateUrl: './are-you-sure-popup.component.html',
  styleUrls: ['./are-you-sure-popup.component.scss']
})
export class AreYouSurePopupComponent implements OnInit {

  @Output() approve: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() iconSrc: string;
  @Input() descriptionLine1: string;
  @Input() descriptionLine2: string;
  @Input() cancelButton: string;
  @Input() okButton: string;
  @Input() updateButton: string;

  @Input() showCancelButton = true;
  @Input() showOkButton = true;
  @Input() showUpdateButton = true;

  constructor(private translate: AmdocsTranslateService) {
  }

  ngOnInit(): void {
    if (!this.title) {
      this.title = this.translate.getText('common.cancelSave');
    }
    if (!this.descriptionLine1) {
      this.descriptionLine1 = this.translate.getText('common.cancelSaveDesc01');
    }
    if (!this.descriptionLine2) {
      this.descriptionLine2 = this.translate.getText('common.cancelSaveDesc02');
    }
    if (!this.okButton) {
      this.okButton = this.translate.getText('common.dontUpdate');
    }
    if (!this.cancelButton) {
      this.cancelButton = this.translate.getText('common.cancel');
    }
    if (!this.updateButton) {
      this.updateButton = this.translate.getText('common.update');
    }
    if (!this.iconSrc) {
      this.iconSrc = 'assets/icons/attention.svg';
    }
  }

}
